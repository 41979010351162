import moment from "moment";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useErrorHandler } from "../../../global/ErrorsHandler";
import {
    useCreateProjectLarpMutation,
    useDeleteProjectLarpMutation,
    useGetProjectLarpQuery,
    useUpdateApplicableSettingsMutation,
    useUpdateProjectLarpMutation,
} from "../../../store/service/adbService";
import { setLoader } from "../../../store/slice/dashboard";
import { Switch } from "../../../UI/Switch";
import { DeleteModal } from "../DeleteModal";
import AxelCheckbox from "../../../UI/AxelCheckbox";
import MoreInfoModal from "../../MoreInfoModal";
import CustomDatePicker from "../../CustomDatePicker";

export const LarpStart = ({
                              id,
                              show,
                              setShow,
                              refActivities,
                              glossaryData,
                              larpData,
                              mainData,
                              translations
                          }) => {
   const localeLan = localStorage.getItem("local_lan" );
 moment.locale(localeLan);
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [currentItem, setCurrentItem] = useState(null);
    const [newItem, setNewItem] = useState(false);
    const [showByActive, setShowByActive] = useState(true);
    const [hasArchived, setHasArchived] = useState(false);
    const [editable, setEditable] = useState(false);
    const [deleteModal, setDeleteModal] = useState(null);
    const [infoModal, setInfoModal] = useState(false);
    const [showLarp, setShowLarp] = useState(false);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [moreInfo, setMoreInfo] = useState(null);
    const scrollRef = useRef(null);
    const [currentRow, setCurrentRow] = useState(null);
    const user = useSelector((state) => state?.adbReducer?.user);
    const expandedRef = useRef(null);
    const [expanded, setExpanded] = useState(false);

    const {
        data: projectLarpData,
        isError: projectLarpIsError,
        isLoading: projectLarpIsLoading,
        isSuccess: projectLarpIsSuccess,
        error: projectLarpError,
    } = useGetProjectLarpQuery(id);

    useEffect(() => {
        if(translations){
            const headers= [
                {
                    tooltip_name: translations?.['contract_start_up_activities_and_submittals.larp_approval.table_headers.contract_clause'],
                    tooltip_text: translations?.['contract_start_up_activities_and_submittals.larp_approval.tooltips.contract_clause']
                },
                {
                    tooltip_name: translations?.['contract_start_up_activities_and_submittals.larp_approval.table_headers.implementation_status'],
                    tooltip_text: translations?.['contract_start_up_activities_and_submittals.larp_approval.tooltips.implementation_status']
                },
                {
                    tooltip_name: translations?.['contract_start_up_activities_and_submittals.larp_approval.table_headers.compliance_report_planned_date'],
                    tooltip_text: translations?.['contract_start_up_activities_and_submittals.larp_approval.tooltips.compliance_report_planned_date']
                },
                {
                    tooltip_name: translations?.['contract_start_up_activities_and_submittals.larp_approval.table_headers.compliance_report_complete'],
                    tooltip_text: translations?.['contract_start_up_activities_and_submittals.larp_approval.tooltips.compliance_report_complete']
                },
                {
                    tooltip_name: translations?.['contract_start_up_activities_and_submittals.larp_approval.table_headers.compliance_report_actual_date'],
                    tooltip_text: translations?.['contract_start_up_activities_and_submittals.larp_approval.tooltips.compliance_report_actual_date']
                },
                {
                    tooltip_name: translations?.['contract_start_up_activities_and_submittals.larp_approval.table_headers.current_status_or_comments'],
                    tooltip_text: translations?.['contract_start_up_activities_and_submittals.larp_approval.tooltips.current_status_or_comments']
                }
            ]

            setTableHeaders(headers.map(item => ({
                ...item,
                showTooltip: false
            })))
        }
    }, [translations])

    useEffect(() => {
        if (glossaryData?.data?.length) {
            setMoreInfo(
                glossaryData?.data?.filter(
                    (el) =>
                        el?.section_name === "Contract Start-up Activities and Submittals"
                )[0]
            );
        }
        if (projectLarpData?.data) {
            let data = projectLarpData?.data?.map((item) => ({
                id: item?.id,
                section_definition: item?.section_definition,
                implementation_status: item?.implementation_status,
                compliance_report_due_date: item?.planned_date,
                compliance_report_complete: item?.completed,
                compliance_report_actual_date: item?.actual_date
                    ? item?.actual_date
                    : null,
                current_status_or_comments: item?.comment,
                edit: false,
                archived: item?.archived,
                submitted: item?.submitted,
                old_report: item?.old_report,
                submitted_date: item?.submitted_date,
            }));
            let isHasArchived = isEmpty(data.find(item => item.archived===1));
            setHasArchived(!isHasArchived);
            if (showByActive===true && !isHasArchived){
                const filteredData =  data.filter(item => item.archived ===0);
                setData(filteredData);
            } else setData(data);
            setEditable(false);
        }
    }, [projectLarpData, glossaryData, showByActive]);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                let array = data?.map((item) => {
                    item.edit = false;
                    return item;
                });
                setCurrentItem(null);
                setData(array);
                setEditable(false);
                if (newItem) {
                    setNewItem(false);
                    let s = data?.filter((el, i) => i !== data.length - 1);
                    setData(s);
                }
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, [data]);

    const [
        createProjectLarp,
        {
            data: createProjectLarpData,
            isError: createProjectLarpIsError,
            isLoading: createProjectLarpIsLoading,
            isSuccess: createProjectLarpIsSuccess,
            error: createProjectLarpError,
        },
    ] = useCreateProjectLarpMutation();

    const [
        updateProjectLarp,
        {
            data: updateProjectLarpData,
            isError: updateProjectLarpIsError,
            isLoading: updateProjectLarpIsLoading,
            isSuccess: updateProjectLarpIsSuccess,
            error: updateProjectLarpError,
        },
    ] = useUpdateProjectLarpMutation();

    const [
        deleteProjectLarp,
        {
            data: deleteProjectLarpData,
            isError: deleteProjectLarpIsError,
            isLoading: deleteProjectLarpIsLoading,
            isSuccess: deleteProjectLarpIsSuccess,
            error: deleteProjectLarpError,
        },
    ] = useDeleteProjectLarpMutation();

    const [
        updateApplicableSettings,
        {
            data: updateApplicableSettingsData,
            isError: updateApplicableSettingsIsError,
            isLoading: updateApplicableSettingsIsLoading,
            isSuccess: updateApplicableSettingsIsSuccess,
            error: updateApplicableSettingsError,
        },
    ] = useUpdateApplicableSettingsMutation();

    useEffect(() => {
        const d = () => {
            setEditable(false);
            setData((old) => {
                let data = old.map((item) => ({ ...item, edit: false }));
                return data;
            });
            dispatch(setLoader(false));
        };

        updateApplicableSettingsIsLoading ||
        projectLarpIsLoading ||
        createProjectLarpIsLoading ||
        updateProjectLarpIsLoading ||
        deleteProjectLarpIsLoading
            ? dispatch(setLoader(true))
            : d();
    }, [
        projectLarpIsLoading,
        createProjectLarpIsLoading,
        updateProjectLarpIsLoading,
        deleteProjectLarpIsLoading,
        updateApplicableSettingsIsLoading,
    ]);

    const setterData = (input) => {
        const body = {
            project_id: id,
            section_definition: input?.section_definition,
            implementation_status: input?.implementation_status,
            planned_date: moment(input?.compliance_report_due_date).format(
                "YYYY-MM-DD"
            ),
            comment: input?.current_status_or_comments,
            actual_date: moment(input?.compliance_report_actual_date).format(
                "YYYY-MM-DD"
            ),
        };

        let s = [];

        if (currentItem?.compliance_report_complete) {
            if (body?.actual_date === "Invalid date") {
                s.push("false");
            }
        } else {
            if (body?.actual_date === "Invalid date") {
                delete body.actual_date;
            }
        }

        for (let key in body) {
            if (key !== "comment") {
                if (!body[key] || body[key] === "Invalid date" || body[key] === "") {
                    toast.error("Please enter " + key?.split("_")?.join(" "), {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                    s.push("false");
                }
            }
        }

        if (s.length === 0) {
            return body;
        } else {
            return null;
        }
    };

    const submitHandler = (e, item) => {
        e.preventDefault();
        if (newItem) {
            const dataCreated = setterData(currentItem);
            if (!dataCreated) {
                return;
            }
            createProjectLarp({
                ...dataCreated,
                completed: currentItem?.compliance_report_complete,
            });
            setCurrentItem(null);
            setNewItem(false);
        } else {
            const dataUpdated = setterData(currentItem);
            if (!dataUpdated) {
                return;
            }
            updateProjectLarp({
                id: item?.id,
                body: {
                    ...dataUpdated,
                    completed: currentItem?.compliance_report_complete,
                },
            });
            setCurrentItem(null);
            setNewItem(false);
        }
    };

    const updateApplicable = (value) => {
        updateApplicableSettings({
            id: larpData?.id,
            body: { larp_processing: value ? 1 : 0 },
        });
    };

    const [errorHandler, setErrorHandler] = useErrorHandler([
        projectLarpError,
        createProjectLarpError,
        updateProjectLarpError,
        deleteProjectLarpError,
        updateApplicableSettingsError,
    ]);

    useEffect(() => {
        if (updateApplicableSettingsIsError) {
            toast.error(updateApplicableSettingsError?.data?.errors?.errors?.error, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 8000,
            });
        }
    }, [updateApplicableSettingsIsError]);

    return (
        <>
            {infoModal && (
                <MoreInfoModal
                    title={translations?.['contract_start_up_activities_and_submittals.contract_start_up_activities_and_submittals.headers.contract_start_up_activities_and_submittals']}
                    moreInfo={translations?.['contract_start_up_activities_and_submittals.contract_start_up_activities_and_submittals.more_info.contract_start_up_activities_and_submittals']}
                    close={()=> setInfoModal(false)}
                />
            )}

            <div
                className="project__white-box"
                style={showLarp ? { border: "none" } : {}}
                ref={refActivities}
            >
                {deleteModal?.show && (
                    <DeleteModal
                        id={deleteModal?.id}
                        action={deleteProjectLarp}
                        setDeleteModal={setDeleteModal}
                        cencelText={translations?.['main.main.actions.cancel']}
                        deleteText={translations?.['main.main.actions.delete']}
                        modalTitle={translations?.['main.main.headers.confirm_your_action']}
                        clarifyingText={translations?.['main.main.titles.you_are_going_to_delete_this_row']}
                    />
                )}
                <div className={`project__block ${show ? "" : "close"}`} ref={refActivities}>
                    {mainData?.project_settings?.contract_activities == true ? (
                        <div className="d-f activitiesDf">
                            <div
                                className="project__block-title activitiesTitle"
                                onClick={() => setShow(!show)}
                            >
                                <span>{translations?.['contract_start_up_activities_and_submittals.contract_start_up_activities_and_submittals.headers.contract_start_up_activities_and_submittals']}</span>
                            </div>
                            <span
                                className="project__block-more"
                                onClick={() => setInfoModal(!infoModal)}
                            >
                <span className="img">
                  <img src="/assets/icons/info.svg" alt="info" />
                </span>
                <span className="text">{translations?.['contract_start_up_activities_and_submittals.contract_start_up_activities_and_submittals.titles.more_info']}</span>
              </span>
                        </div>
                    ) : null}
                    {mainData?.project_settings?.larp_approval ? (
                        <div className="project__block-subtitles">
                            <div className="general-title checkbox">
                                {translations?.['contract_start_up_activities_and_submittals.larp_approval.headers.larp_approval']}
                                <div className="project__block-checkbox">
                                    <AxelCheckbox
                                        id="larp"
                                        label={translations?.['contract_start_up_activities_and_submittals.larp_approval.actions.not_applicable']}
                                        handler={() => {
                                            updateApplicable(!showLarp);
                                            setShowLarp(!showLarp);
                                            let array = data?.map((item) => {
                                                item.edit = false;
                                                return item;
                                            });
                                            setCurrentItem(null);
                                            setData(array);
                                            setEditable(false);
                                            if (newItem) {
                                                setNewItem(false);
                                                let s = data?.filter((el, i) => i !== data.length - 1);
                                                setData(s);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="project__block__buttonsContainer">
                                {hasArchived && <button
                                    onClick={() => setShowByActive(!showByActive)}
                                    className="project__block-add-row">
                  <span>
                  <img src="/assets/icons/blue-eye.svg" alt="plus"/>
                  </span>
                                    <span>{showByActive ? translations?.['contract_start_up_activities_and_submittals.larp_approval.actions.show_all']
                                        : translations?.['contract_start_up_activities_and_submittals.larp_approval.actions.show_active']}</span>
                                </button>}
                                {user?.permissions?.edit_project && !showLarp && (
                                    <button
                                        onClick={() => {
                                            let randomId = Math.floor(Math.random() * 1000);
                                            setCurrentRow(randomId);
                                            scrollRef !== null && scrollRef.current.scrollIntoView();
                                            if (!newItem && !showLarp) {
                                                setNewItem(true);
                                                setEditable(true);
                                                setData((old) => [
                                                    ...old,
                                                    {
                                                        id: randomId,
                                                        application_type: "",
                                                        implementation_status: "",
                                                        compliance_report_due_date: null,
                                                        compliance_report_complete: false,
                                                        compliance_report_actual_date: null,
                                                        current_status_or_comments: "",
                                                        edit: true,
                                                    },
                                                ]);
                                                setCurrentItem({
                                                    id: randomId,
                                                    application_type: "",
                                                    implementation_status: "",
                                                    compliance_report_due_date: null,
                                                    compliance_report_complete: false,
                                                    compliance_report_actual_date: null,
                                                    current_status_or_comments: "",
                                                    edit: true,
                                                });
                                            }
                                        }}
                                        className="project__block-add-row"
                                    >
                    <span>
                      <img src="/assets/icons/blue-plus.svg" alt="plus" />
                    </span>
                                        <span>{translations?.['contract_start_up_activities_and_submittals.larp_approval.actions.add_row']}</span>
                                    </button>
                                )}
                            </div>
                        </div>
                    ) : null}

                    <div
                        className={`project__block-content activities ${
                            editable ? "editable" : ""
                        }`}
                        style={{ zIndex: 45 }}
                    >
                        {mainData?.project_settings?.larp_approval && !showLarp ? (
                            <div className={`project__block-table project-table`}>
                                <div
                                    ref={scrollRef}
                                    className={`project-table__header ${
                                        data?.length > 3 && editable ? "isFixed" : ""
                                    }`}
                                >
                                    {tableHeaders?.map((el, idx) => {
                                        return (
                                            <div
                                                key={idx}
                                                onMouseOver={(event) => {
                                                    setTableHeaders(
                                                        tableHeaders.map((item) => ({
                                                            ...item,
                                                            showTooltip: item.tooltip_name === el.tooltip_name
                                                        }))
                                                    );
                                                }}
                                                onMouseLeave={() => {
                                                    setTableHeaders(
                                                        tableHeaders.map((item) => ({
                                                            ...item,
                                                            showTooltip: false
                                                        }))
                                                    );
                                                }}
                                                className="project-table__th"
                                            >
                                                {el?.showTooltip ? (
                                                    <span
                                                        className={
                                                            el?.tooltip_text?.length > 20
                                                                ? "lengthy"
                                                                : "not-lengthy"
                                                        }
                                                    >
                            {el?.tooltip_text}
                          </span>
                                                ) : (
                                                    ""
                                                )}
                                                {el?.tooltip_name}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="project-table__body">
                                    {data?.map((item, index) => (
                                        <div key={index}>
                                            {index !== 0 && currentRow === item?.id && (
                                                <div className={`project-table__header`} key={index}>
                                                    {tableHeaders?.map((el, idx) => {
                                                        return (
                                                            <div key={idx} className="project-table__th">
                                                                {el?.showTooltip ? (
                                                                    <span
                                                                        className={
                                                                            el?.tooltip_text?.length > 20
                                                                                ? "lengthy"
                                                                                : "not-lengthy"
                                                                        }
                                                                    >
                                    {el?.tooltip_text}2
                                  </span>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                {el?.tooltip_name}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                            <form
                                                className={`project-table__row ${
                                                    showLarp ? "row__disabled" : ""
                                                } ${item?.edit ? "edit" : currentItem ? "n" : ""} ${
                                                    Boolean(item.submitted) ||
                                                    !user?.permissions?.edit_project
                                                        ? "submitted"
                                                        : ""
                                                }`}
                                                id={item?.id}
                                                key={item?.id}
                                                onSubmit={(e) => submitHandler(e, item)}
                                            >
                                                <div className="project-table__td">
                                                    <span>{item?.section_definition}</span>
                                                    <div className="project__block-input project-table__field">
                                                        <input
                                                            type="text"
                                                            defaultValue={item?.section_definition}
                                                            onChange={(e) => {
                                                                setCurrentItem((old) => ({
                                                                    ...old,
                                                                    section_definition: e.target.value,
                                                                }));
                                                            }}
                                                            maxLength={190}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="project-table__td">
                                                    <span>{item?.implementation_status}</span>
                                                    <div className="project__block-input project-table__field">
                                                        <input
                                                            maxLength={190}
                                                            type="text"
                                                            defaultValue={item?.implementation_status}
                                                            onChange={(e) => {
                                                                setCurrentItem((old) => ({
                                                                    ...old,
                                                                    implementation_status: e.target.value,
                                                                }));
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="project-table__td">
                          <span>
                            {moment(item?.compliance_report_due_date).format(
                                "DD/MM/YYYY"
                            ) === "Invalid date" ||
                            !moment(item?.compliance_report_due_date).format(
                                "DD/MM/YYYY"
                            )
                                ? ""
                                : moment(item?.compliance_report_due_date).format(
                                    "DD/MM/YYYY"
                                )}
                          </span>
                                                    <div className="project__block-item-calendar project-table__field">
                                                        <CustomDatePicker
                                                            portalId="root-portal"
                                                            autoComplete="off"
                                                            id="approved_date"
                                                            placeholderText={translations?.['main.main.placeholder.dd_mm_yyyy']}
                                                            dateFormat="dd/MM/yyyy"
                                                            selected={
                                                                currentItem?.compliance_report_due_date
                                                                    ? moment(
                                                                        currentItem?.compliance_report_due_date
                                                                    ).toDate()
                                                                    : ""
                                                            }
                                                            onChange={(date) => {
                                                                setCurrentItem((old) => ({
                                                                    ...old,
                                                                    compliance_report_due_date: date,
                                                                }));
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="project-table__td">
                                                    <Switch
                                                        value={item?.compliance_report_complete}
                                                        checked={
                                                            item?.edit
                                                                ? currentItem?.compliance_report_complete
                                                                : item?.compliance_report_complete
                                                        }
                                                        id={index + 200}
                                                        disabled={item?.edit ? false : true}
                                                        handler={(value) => {
                                                            setCurrentItem((old) => ({
                                                                ...old,
                                                                compliance_report_complete: value,
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                                <div className="project-table__td">
                          <span>
                            {moment(item?.compliance_report_actual_date).format(
                                "DD/MM/YYYY"
                            ) === "Invalid date" ||
                            !moment(item?.compliance_report_actual_date).format(
                                "DD/MM/YYYY"
                            )
                                ? ""
                                : moment(
                                    item?.compliance_report_actual_date
                                ).format("DD/MM/YYYY")}
                          </span>
                                                    <div
                                                        className={`project__block-item-calendar project-table__field ${
                                                            currentItem?.compliance_report_complete
                                                                ? ""
                                                                : "project__actualDate-disabled"
                                                        }`}
                                                    >
                                                        <CustomDatePicker
                                                            portalId="root-portal"
                                                            id="approved_date"
                                                            autoComplete="off"
                                                            placeholderText={translations?.['main.main.placeholder.dd_mm_yyyy']}
                                                            dateFormat="dd/MM/yyyy"
                                                            disabled={
                                                                !currentItem?.compliance_report_complete
                                                            }
                                                            selected={
                                                                currentItem?.compliance_report_actual_date
                                                                    ? moment(
                                                                        currentItem?.compliance_report_actual_date
                                                                    ).toDate()
                                                                    : ""
                                                            }
                                                            onChange={(date) => {
                                                                setCurrentItem((old) => ({
                                                                    ...old,
                                                                    compliance_report_actual_date: date,
                                                                }));
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="project-table__td">
                                                    <span>{item?.current_status_or_comments}</span>
                                                    <div className="project__block-input project-table__field">
                            <textarea
                                className={`${
                                    expanded ? "project__block-expandedInput" : ""
                                }`}
                                ref={expandedRef}
                                onFocus={() => setExpanded(true)}
                                onBlur={() => setExpanded(false)}
                                maxLength={190}
                                type="text"
                                defaultValue={item?.current_status_or_comments}
                                onChange={(e) => {
                                    setCurrentItem((old) => ({
                                        ...old,
                                        current_status_or_comments: e.target.value,
                                    }));
                                }}
                            />
                                                    </div>
                                                </div>
                                                {!showLarp && (
                                                    <div className="project__block-btns">
                                                        {currentItem ? (
                                                            <div
                                                                className="project__block-cancel"
                                                                onClick={() => {
                                                                    setCurrentRow(null);
                                                                    let array = data?.map((item) => {
                                                                        item.edit = false;
                                                                        return item;
                                                                    });
                                                                    setCurrentItem(null);
                                                                    setData(array);
                                                                    setEditable(false);
                                                                    if (newItem) {
                                                                        setNewItem(false);
                                                                        let s = data?.filter(
                                                                            (el, i) => i !== data.length - 1
                                                                        );
                                                                        setData(s);
                                                                    }
                                                                }}
                                                            >
                                                                <img
                                                                    src="/assets/icons/close-mark.svg"
                                                                    alt="close"
                                                                />
                                                                {translations?.['contract_start_up_activities_and_submittals.larp_approval.actions.cancel']}
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className="project__block-delete"
                                                                onClick={() => {
                                                                    setDeleteModal({ id: item?.id, show: true });
                                                                }}
                                                            >
                                                                <img
                                                                    src="/assets/icons/trash-icon.svg"
                                                                    alt="arrow"
                                                                />
                                                                {translations?.['contract_start_up_activities_and_submittals.larp_approval.actions.delete']}
                                                            </div>
                                                        )}
                                                        {currentItem ? (
                                                            <button className="project__block-save">
                                                                <img
                                                                    src="/assets/icons/save-arrow.svg"
                                                                    alt="arrow"
                                                                />
                                                                {translations?.['contract_start_up_activities_and_submittals.larp_approval.actions.save']}
                                                            </button>
                                                        ) : (
                                                            <div
                                                                onClick={() => {
                                                                    setCurrentRow(item?.id);
                                                                    setCurrentItem(item);
                                                                    setEditable(true);
                                                                    setData(() => {
                                                                        let array = data?.map((elem) => {
                                                                            if (elem?.id === item?.id) {
                                                                                elem.edit = true;
                                                                            }
                                                                            return elem;
                                                                        });
                                                                        return array;
                                                                    });
                                                                }}
                                                                className="project__block-update"
                                                            >
                                                                {translations?.['contract_start_up_activities_and_submittals.larp_approval.actions.update']}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </form>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    );
};
