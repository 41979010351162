import moment from "moment";
import isEmpty from "lodash/isEmpty";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useErrorHandler } from "../../../global/ErrorsHandler";
import {
    useCreateProjectResidualActionsMutation,
    useDeleteProjectResidualActionsMutation,
    useGetProjectResidualActionsQuery,
    useLazyGetAllCompaniesQuery,
    useUpdateProjectResidualActionsMutation,
} from "../../../store/service/adbService";
import { setLoader } from "../../../store/slice/dashboard";
import { Switch } from "../../../UI/Switch";
import { ContractActivities } from "../ContractActivities";
import { ContractRequirments } from "../ContractRequirments";
import Select from "react-select";

import { DeleteModal } from "../DeleteModal";
import CustomDatePicker from "../../CustomDatePicker";

const customStyles = {
    menu: (provided, state = false) => ({
        ...provided,
        width: "150%",
        color: state.selectProps.menuColor,
        cursor: "pointer",
        overflow: "hidden",
    }),

    control: (_, { selectProps: { width } }) => ({
        width: "100%",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        display: "flex",
        background: "#FFFFFF",
        borderRadius: 8,
    }),
};

export const GeneralStart = ({
                                 id,
                                 show,
                                 refGeneralStartUp,
                                 projectData,
                                 refSitePhotographs,
                                 refProgramProgress,
                                 refMobilizationActivities,
                                 glossaryData,
                                 refDisputes,
                                 refFinancialManagement,
                                 refGoodsEquipment,
                                 translations
                             }) => {
    const dispatch = useDispatch();
    const localeLan = localStorage.getItem("local_lan" );
    moment.locale(localeLan)
    const [data, setData] = useState([]);
    const user = useSelector((state) => state?.adbReducer?.user);
    const [currentItem, setCurrentItem] = useState(null);
    const [newItem, setNewItem] = useState(false);
    const [options, setOptions] = useState([]);
    const [showByActive, setShowByActive] = useState(true);
    const [hasArchived, setHasArchived] = useState(false);
    const [deleteModal, setDeleteModal] = useState(null);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [currentRow, setCurrentRow] = useState(null);
    const scrollRef = useRef(null);
    const expandedRef = useRef(null);
    const [expanded, setExpanded] = useState(false);
    const descrRef = useRef(null);
    const [decrExpanded, setDecrExpanded] = useState(false);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                let array = data?.map((item) => {
                    item.edit = false;
                    return item;
                });
                setCurrentItem(null);
                setData(array);
                if (newItem) {
                    setNewItem(false);
                    let s = data?.filter((el, i) => i !== data.length - 1);
                    setData(s);
                }
            }
        };
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, [data, newItem]);

    const {
        data: projectReportRequirmentsData,
        isError: projectReportRequirmentsIsError,
        isLoading: projectReportRequirmentsIsLoading,
        isSuccess: projectReportRequirmentsIsSuccess,
        error: projectReportRequirmentsError,
    } = useGetProjectResidualActionsQuery(id);


    const [
        getCompanies,
        {
            data: companies,
            error: companiesError,
            isLoading: companiesIsLoading,
            isError: companiesIsError,
            isSuccess: companiesIsSuccess,
        },
    ] = useLazyGetAllCompaniesQuery();

    useEffect(() => {
        getCompanies({
            per_page: 1000,
        });
    }, []);

    useEffect(() => {
        if(translations){
            const headers = [
                {
                    tooltip_name: translations?.['general_start_up_activities.residual_tender_stage_actions.table_headers.contract_clause'],
                    tooltip_text: translations?.['general_start_up_activities.residual_tender_stage_actions.tooltips.contract_clause']
                },
                {
                    tooltip_name: translations?.['general_start_up_activities.residual_tender_stage_actions.table_headers.action_description'],
                    tooltip_text: translations?.['general_start_up_activities.residual_tender_stage_actions.tooltips.action_description']
                },
                {
                    tooltip_name: translations?.['general_start_up_activities.residual_tender_stage_actions.table_headers.responsible_party'],
                    tooltip_text: translations?.['general_start_up_activities.residual_tender_stage_actions.tooltips.responsible_party']
                },
                {
                    tooltip_name: translations?.['general_start_up_activities.residual_tender_stage_actions.table_headers.planned_date'],
                    tooltip_text: translations?.['general_start_up_activities.residual_tender_stage_actions.tooltips.planned_date']
                },
                {
                    tooltip_name: translations?.['general_start_up_activities.residual_tender_stage_actions.table_headers.actual_date'],
                    tooltip_text: translations?.['general_start_up_activities.residual_tender_stage_actions.tooltips.actual_date']
                },
                {
                    tooltip_name: translations?.['general_start_up_activities.residual_tender_stage_actions.table_headers.current_status_or_comments'],
                    tooltip_text: translations?.['general_start_up_activities.residual_tender_stage_actions.tooltips.current_status_or_comments']
                },
                {
                    tooltip_name: translations?.['general_start_up_activities.residual_tender_stage_actions.table_headers.completed'],
                    tooltip_text: translations?.['general_start_up_activities.residual_tender_stage_actions.tooltips.completed']
                },
            ];

            setTableHeaders(
                headers.map((item) => ({
                    ...item,
                    showTooltip: false,
                }))
            );
        }
    }, [translations])

    useEffect(() => {
        if (projectData?.responsible_parties) {
            let data = projectData?.responsible_parties?.map((item) => {
                return {
                    value: item,
                    label: item,
                };
            });
            setOptions(data);
        }
    }, [projectData, glossaryData]);

    useEffect(() => {
        if (projectReportRequirmentsData?.data) {
            let data = projectReportRequirmentsData?.data?.map((item) => ({
                id: item?.id,
                contract_clause: item?.contract_clause,
                action_description: item?.action_description,
                responsible_party: {
                    value: item?.responsible_party,
                    label: item?.responsible_party,
                },
                planned_date: item?.planned_date,
                actual_date: item?.actual_date ? item?.actual_date : null,
                comment: item?.comment,
                completed: item?.completed,
                edit: false,
                archived: item?.archived,
                submitted: item?.submitted,
                old_report: item?.old_report,
                submitted_date: item?.submitted_date,
            }));
            let isHaveArchived = isEmpty(data.find(item => item.archived===1));
            setHasArchived(!isHaveArchived);
            if (showByActive===true && !isHaveArchived){
                const filteredData =  data.filter(item => item.archived ===0);
                setData(filteredData);
            } else setData(data);
        }
    }, [projectReportRequirmentsData, showByActive]);

    const [
        createReportRequirments,
        {
            data: createReportRequirmentsData,
            isError: createReportRequirmentsIsError,
            isLoading: createReportRequirmentsIsLoading,
            isSuccess: createReportRequirmentsIsSuccess,
            error: createReportRequirmentsError,
        },
    ] = useCreateProjectResidualActionsMutation();

    const [
        updateReportRequirments,
        {
            data: updateReportRequirmentsData,
            isError: updateReportRequirmentsIsError,
            isLoading: updateReportRequirmentsIsLoading,
            isSuccess: updateReportRequirmentsIsSuccess,
            error: updateReportRequirmentsError,
        },
    ] = useUpdateProjectResidualActionsMutation();

    const [
        deleteReportRequirments,
        {
            data: deleteReportRequirmentsData,
            isError: deleteReportRequirmentsIsError,
            isLoading: deleteReportRequirmentsIsLoading,
            isSuccess: deleteReportRequirmentsIsSuccess,
            error: deleteReportRequirmentsError,
        },
    ] = useDeleteProjectResidualActionsMutation();

    const [errorHandler, setErrorHandler] = useErrorHandler([
        projectReportRequirmentsError,
        deleteReportRequirmentsError,
        updateReportRequirmentsError,
        createReportRequirmentsError,
    ]);

    useEffect(() => {
        const d = () => {
            setData((old) => {
                let data = old?.map((item) => ({ ...item, edit: false }));
                return data;
            });
            dispatch(setLoader(false));
        };

        deleteReportRequirmentsIsLoading ||
        updateReportRequirmentsIsLoading ||
        createReportRequirmentsIsLoading ||
        projectReportRequirmentsIsLoading
            ? dispatch(setLoader(true))
            : d();
    }, [
        deleteReportRequirmentsIsLoading,
        updateReportRequirmentsIsLoading,
        createReportRequirmentsIsLoading,
        projectReportRequirmentsIsLoading,
    ]);

    const setterData = (input) => {
        const body = {
            project_id: id,
            contract_clause: input?.contract_clause,
            action_description: input?.action_description,
            responsible_party: input?.responsible_party.label,
            planned_date: moment(input?.planned_date).format("YYYY-MM-DD"),
            actual_date: moment(input?.actual_date).format("YYYY-MM-DD"),
            comment: input?.comment,
        };

        let s = [];

        if (currentItem.completed) {
            if (body?.actual_date === "Invalid date") {
                s.push("false");
            }
        } else {
            if (body?.actual_date === "Invalid date") {
                delete body.actual_date;
            }
        }

        for (let key in body) {
            if (key !== "comment") {
                if (!body[key] || body[key] === "Invalid date" || body[key] === "") {
                    toast.error("Please enter " + key?.split("_")?.join(" "), {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                    s.push("false");
                }
            }
        }

        if (s.length === 0) {
            return body;
        } else {
            return null;
        }
    };

    const submitHandler = (e, item) => {
        e.preventDefault();

        if (newItem) {
            const dataCreated = setterData(currentItem);
            if (!dataCreated) {
                return;
            }
            createReportRequirments({
                ...dataCreated,
                completed: currentItem?.completed,
            });
            setCurrentItem(null);
            setNewItem(false);
            setCurrentRow(null);
        } else {
            const dataUpdated = setterData(currentItem);
            if (!dataUpdated) {
                return;
            }
            updateReportRequirments({
                id: item?.id,
                body: {
                    ...dataUpdated,
                    completed: currentItem?.completed,
                },
            });
            setCurrentItem(null);
            setCurrentRow(null);

            setNewItem(false);
        }
    };
    return (
        <div
            className={`${
                data?.data?.project_settings?.start_up_activities
                    ? "project__white-box"
                    : ""
            }`}
            ref={refGeneralStartUp}
        >
            {deleteModal?.show && (
                <DeleteModal
                    id={deleteModal?.id}
                    action={deleteReportRequirments}
                    setDeleteModal={setDeleteModal}
                    cencelText={translations?.['main.main.actions.cancel']}
                    deleteText={translations?.['main.main.actions.delete']}
                    modalTitle={translations?.['main.main.headers.confirm_your_action']}
                    clarifyingText={translations?.['main.main.titles.you_are_going_to_delete_this_row']}
                />
            )}
            <div className={`project__block ${show ? "" : "generalclose"}`}>
                <div
                    className="general-title"
                >
                    <span>{translations?.['general_start_up_activities.general_start_up_activities.headers.general_start_up_activities']}</span>
                </div>
                <div className="project__block-hr"></div>
                <div className="project__block-subtitles">
                    <div className="project__block-subtitle">
                        {translations?.['general_start_up_activities.residual_tender_stage_actions.headers.residual_tender_stage_actions']}
                    </div>
                    <div className="project__block__buttonsContainer">
                        {hasArchived && <button
                            onClick={() => setShowByActive(!showByActive)}
                            className="project__block-add-row">
                <span>
                <img src="/assets/icons/blue-eye.svg" alt="plus"/>
                </span>
                            <span>{showByActive ? translations?.['general_start_up_activities.residual_tender_stage_actions.actions.show_all']
                                : translations?.['general_start_up_activities.residual_tender_stage_actions.actions.show_active']}</span>
                        </button>}
                        {user?.permissions?.edit_project && (
                            <button
                                onClick={() => {
                                    let randomId = Math.floor(Math.random() * 1000);
                                    scrollRef?.current?.scrollIntoView();
                                    setCurrentRow(randomId);
                                    if (!newItem) {
                                        setNewItem(true);
                                        setData((old) => [
                                            ...old,
                                            {
                                                id: randomId,
                                                contract_clause: "",
                                                action_description: "",
                                                responsibility_party: "",
                                                planned_date: false,
                                                actual_date: null,
                                                comment: "",
                                                completed: false,
                                                edit: true,
                                            },
                                        ]);
                                        setCurrentItem({
                                            id: randomId,
                                            contract_clause: "",
                                            action_description: "",
                                            responsibility_party: "",
                                            planned_date: false,
                                            actual_date: null,
                                            comment: "",
                                            completed: false,
                                            edit: true,
                                        });
                                    }
                                }}
                                className="project__block-add-row"
                            >
                <span>
                  <img src="/assets/icons/blue-plus.svg" alt="plus" />
                </span>
                                <span>{translations?.['general_start_up_activities.residual_tender_stage_actions.actions.add_row']}</span>
                            </button>
                        )}
                    </div>
                </div>
                <div className="project__block-content general-start">
                    <div className={`project__block-table project-table`}>
                        <div ref={scrollRef} className={`project-table__header `}>
                            {tableHeaders?.map((el, idx) => {
                                return (
                                    <div
                                        key={idx}
                                        onMouseEnter={(event) => {
                                            setTableHeaders(
                                                tableHeaders.map((item) => ({
                                                    ...item,
                                                    showTooltip: item.tooltip_name === el.tooltip_name
                                                }))
                                            );
                                        }}
                                        onMouseLeave={() => {
                                            setTableHeaders(
                                                tableHeaders.map((item) => ({
                                                    ...item,
                                                    showTooltip: false,
                                                }))
                                            );
                                        }}
                                        className="project-table__th"
                                    >
                                        {el?.showTooltip ? (
                                            <span
                                                className={
                                                    el?.tooltip_text?.length > 20
                                                        ? "lengthy"
                                                        : "not-lengthy"
                                                }
                                            >
                        {el?.tooltip_text}
                      </span>
                                        ) : (
                                            ""
                                        )}
                                        {el?.tooltip_name}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="project-table__body">
                            {data?.map((item, index) => (
                                <div key={index}>
                                    {index !== 0 && currentRow === item?.id && (
                                        <div className={`project-table__header`} key={index}>
                                            {tableHeaders?.map((el, idx) => {
                                                return (
                                                    <div key={idx} className="project-table__th">
                                                        {el?.showTooltip ? (
                                                            <span
                                                                className={
                                                                    el?.tooltip_text?.length > 20
                                                                        ? "lengthy"
                                                                        : "not-lengthy"
                                                                }
                                                            >
                                {el?.tooltip_text}
                              </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {el?.tooltip_name}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                    <form
                                        className={`project-table__row ${
                                            item?.edit ? "edit" : currentItem ? "n" : ""
                                        } ${
                                            Boolean(item.submitted) ||
                                            !user?.permissions?.edit_project
                                                ? "submitted"
                                                : ""
                                        }`}
                                        id={item?.id}
                                        key={item?.id}
                                        onSubmit={(e) => submitHandler(e, item)}
                                    >
                                        <div className="project-table__td">
                                            <span>{item?.contract_clause}</span>
                                            <div className="project__block-input project-table__field">
                                                <input
                                                    maxLength={190}
                                                    type="text"
                                                    defaultValue={item?.contract_clause}
                                                    onChange={(e) => {
                                                        setCurrentItem((old) => ({
                                                            ...old,
                                                            contract_clause: e.target.value,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                                            <span>{item?.action_description}</span>
                                            <div className="project__block-input project-table__field">
                        <textarea
                            className={`${
                                decrExpanded ? "project__block-expandedInput" : ""
                            }`}
                            ref={descrRef}
                            onFocus={() => setDecrExpanded(true)}
                            onBlur={() => setDecrExpanded(false)}
                            maxLength={190}
                            type="text"
                            defaultValue={item?.action_description}
                            onChange={(e) => {
                                setCurrentItem((old) => ({
                                    ...old,
                                    action_description: e.target.value,
                                }));
                            }}
                        />
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                      <span>
                        {item?.responsible_party?.label !=
                        "undefined undefined"
                            ? item?.responsible_party?.label
                            : ""}
                      </span>
                                            <div className="project__block-input project-table__field select">
                                                <Select
                                                    options={options}
                                                    isSearchable={false}
                                                    menuPortalTarget={document.body}
                                                    styles={{
                                                        ...customStyles,
                                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                    }}
                                                    value={currentItem?.responsible_party}
                                                    onChange={(selected) => {
                                                        setCurrentItem((old) => ({
                                                            ...old,
                                                            responsible_party: selected,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                      <span>
                        {moment(item?.planned_date).format("DD/MM/YYYY") ===
                        "Invalid date" ||
                        !moment(item?.planned_date).format("DD/MM/YYYY")
                            ? ""
                            : moment(item?.planned_date).format("DD/MM/YYYY")}
                      </span>
                                            <div className="project__block-item-calendar project-table__field">
                                                <CustomDatePicker
                                                    portalId="root-portal"
                                                    autoComplete="off"
                                                    id="approved_date"
                                                    placeholderText={translations?.['main.main.placeholder.dd_mm_yyyy']}
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={
                                                        currentItem?.planned_date
                                                            ? moment(currentItem?.planned_date).toDate()
                                                            : ""
                                                    }
                                                    onChange={(date) => {
                                                        setCurrentItem((old) => ({
                                                            ...old,
                                                            planned_date: date,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                      <span>
                        {moment(item?.actual_date).format("DD/MM/YYYY") ===
                        "Invalid date" ||
                        !moment(item?.actual_date).format("DD/MM/YYYY")
                            ? ""
                            : moment(item?.actual_date).format("DD/MM/YYYY")}
                      </span>
                                            <div
                                                className={`project__block-item-calendar project-table__field ${
                                                    currentItem?.completed
                                                        ? ""
                                                        : "project__actualDate-disabled"
                                                }`}
                                            >
                                                <CustomDatePicker
                                                    portalId="root-portal"
                                                    autoComplete="off"
                                                    id="approved_date"
                                                    disabled={!currentItem?.completed}
                                                    placeholderText={translations?.['main.main.placeholder.dd_mm_yyyy']}
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={
                                                        currentItem?.actual_date
                                                            ? moment(currentItem?.actual_date).toDate()
                                                            : ""
                                                    }
                                                    onChange={(date) => {
                                                        setCurrentItem((old) => ({
                                                            ...old,
                                                            actual_date: date,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                                            <span>{item?.comment}</span>
                                            <div className="project__block-input project-table__field">
                        <textarea
                            className={`${
                                expanded ? "project__block-expandedInput" : ""
                            }`}
                            ref={expandedRef}
                            onFocus={() => setExpanded(true)}
                            onBlur={() => setExpanded(false)}
                            maxLength={190}
                            type="text"
                            defaultValue={item?.comment}
                            onChange={(e) => {
                                setCurrentItem((old) => ({
                                    ...old,
                                    comment: e.target.value,
                                }));
                            }}
                        />
                                            </div>
                                        </div>
                                        <div className="project-table__td">
                                            <Switch
                                                id={index + 500}
                                                disabled={item?.edit ? false : true}
                                                checked={
                                                    item?.edit ? currentItem?.completed : item?.completed
                                                }
                                                handler={(value) => {
                                                    setCurrentItem((old) => ({
                                                        ...old,
                                                        completed: value,
                                                        actual_date: value
                                                            ? currentItem?.actual_date
                                                            : null,
                                                    }));
                                                }}
                                            />
                                        </div>
                                        <div className="project__block-btns">
                                            {currentItem ? (
                                                <div
                                                    className="project__block-cancel"
                                                    onClick={() => {
                                                        setCurrentRow(null);
                                                        let array = data?.map((item) => {
                                                            item.edit = false;
                                                            return item;
                                                        });
                                                        setCurrentItem(null);
                                                        setData(array);
                                                        if (newItem) {
                                                            setNewItem(false);
                                                            let s = data?.filter(
                                                                (el, i) => i !== data.length - 1
                                                            );
                                                            setData(s);
                                                        }
                                                    }}
                                                >
                                                    <img src="/assets/icons/close-mark.svg" alt="close" />
                                                    {translations?.['general_start_up_activities.residual_tender_stage_actions.actions.cancel']}
                                                </div>
                                            ) : (
                                                <div
                                                    className="project__block-delete"
                                                    onClick={() => {
                                                        setDeleteModal({ id: item?.id, show: true });
                                                    }}
                                                >
                                                    <img src="/assets/icons/trash-icon.svg" alt="arrow" />
                                                    {translations?.['general_start_up_activities.residual_tender_stage_actions.actions.delete']}
                                                </div>
                                            )}
                                            {currentItem ? (
                                                <button className="project__block-save">
                                                    <img src="/assets/icons/save-arrow.svg" alt="arrow" />
                                                    {translations?.['general_start_up_activities.residual_tender_stage_actions.actions.save']}
                                                </button>
                                            ) : (
                                                <div
                                                    onClick={() => {
                                                        setCurrentRow(item?.id);
                                                        setCurrentItem(item);
                                                        setData(() => {
                                                            let array = data?.map((elem) => {
                                                                if (elem?.id === item?.id) {
                                                                    elem.edit = true;
                                                                }
                                                                return elem;
                                                            });
                                                            return array;
                                                        });
                                                    }}
                                                    className="project__block-update"
                                                >
                                                    {translations?.['general_start_up_activities.residual_tender_stage_actions.actions.update']}
                                                </div>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="project__block-hr"></div>
                    <ContractRequirments
                        translations={translations}
                        projectData={projectData}
                        glossaryData={glossaryData}
                        id={id}
                    />

                    <div className="project__block-hr"></div>

                    <ContractActivities
                        translations={translations}
                        projectData={projectData}
                        glossaryData={glossaryData}
                        id={id}
                    />
                </div>
            </div>
        </div>
    );
};
