import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useCreateProjectMutation,
  useGetAllSectionsMutation,
  useUpdateProjectMutation,
} from "../../../store/service/adbService";
import { useErrorHandler } from "../../../global/ErrorsHandler";
import { toast } from "react-toastify";
import { setLoader } from "../../../store/slice/dashboard";
import {errorHandle} from "../../../global/errorHandle";

export const EditProjectStep4 = ({ setSteps, translations }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getAllSections, {data: allSections}] = useGetAllSectionsMutation()
  const [sections, setSections] = useState([]);
  const [dataTr, setDataTr] = useState([]);

  useEffect(() => {
    if(translations){    
      setDataTr(translations);
    }
}, [translations])

useEffect(() => {
  if(dataTr){
    const allSections = [
      {
        id: 'contract_key_data',
        label: dataTr?.['contract_key_data.contract_key_data.headers.contract_key_data'],
        value: 1
      },
      {
        id: 'executive_summary',
        label: dataTr?.['executive_summary.executive_summary.headers.executive_summary'],
        value: 1
      },
      {
        id: 'permits_licenses_approvals',
        label: dataTr?.['permits_licenses_and_approvals.permits_licenses_and_approvals.headers.permits_licenses_and_approvals'],
        value: 1
      },
      {
        id: 'larp_approval',
        label: dataTr?.['contract_start_up_activities_and_submittals.larp_approval.headers.larp_approval'],
        value: 1
      },
      {
        id: 'residual_tender_stage_actions',
        label: dataTr?.['general_start_up_activities.residual_tender_stage_actions.headers.residual_tender_stage_actions'],
        value: 1
      },
      {
        id: 'contract_requirements',
        label: dataTr?.['general_start_up_activities.contract_requirements.headers.contract_requirements'],
        value: 1
      },
  
      {
        id: 'contract_activities',
        label: dataTr?.['general_start_up_activities.initial_contract_activities.headers.initial_contract_activities'],
        value: 1
      },
      {
        id: 'mobilization_activities',
        label: dataTr?.['general_start_up_activities.mobilization_activities.headers.mobilization_activities'],
        value: 1
      },
      {
        id: 'risk_management',
        label: dataTr?.['risk_management.risk_management.headers.risk_management'],
        value: 1
      },
      {
        id: 'project_status',
        label: dataTr?.['project_monitoring.project_monitoring.titles.project_status_as_of_date'],
        value: 1
      },
      {
        id: 'major_project_milestones',
        label: dataTr?.['project_monitoring.major_project_milestones.headers.major_project_milestones'],
        value: 1
      },
      {
        id: 'program_progress',
        label: dataTr?.['project_monitoring.program_progress.headers.program_progress'],
        value: 1
      },
      {
        id: 'program_overview',
        label: dataTr?.['project_monitoring.program_overview.headers.program_overview'],
        value: 1
      },
      {
        id: 'critical_path',
        label: dataTr?.['project_monitoring.critical_path.headers.critical_path'],
        value: 1
      },
      {
        id: 'one_month_look_ahead',
        label: dataTr?.['project_monitoring.one_month_look_ahead.headers.one_month_look_ahead'],
        value: 1
      },
      {
        id: 'variations_and_claims',
        label: dataTr?.['project_monitoring.variations_and_claims.headers.variations_and_claims'],
        value: 1
      },
      {
        id: 'variation_register',
        label: dataTr?.['project_monitoring.variation_register.headers.variation_register'],
        value: 1
      },
      {
        id: 'claim_register',
        label: dataTr?.['project_monitoring.claim_register.headers.claim_register'],
        value: 1
      },
      {
        id: 'extension_of_time',
        label: dataTr?.['project_monitoring.extension_of_time.headers.extension_of_time'],
        value: 1
      },
      {
        id: 'payments_overview',
        label: dataTr?.['payments.payments.headers.payments'],
        value: 1
      },
      {
        id: 'actual_project_payments',
        label: dataTr?.['payments.actual_project_payments.headers.actual_project_payments'],
        value: 1
      },
      {
        id: 'actual_planned_payments',
        label: dataTr?.['payments.actual_versus_planned_project_payments.headers.actual_versus_planned_project_payments'],
        value: 1
      },
      {
        id: 'subcontractor_payments',
        label: dataTr?.['payments.subcontractor_payments.headers.subcontractor_payments'],
        value: 1
      },
      {
        id: 'disputes',
        label: dataTr?.['disputes.disputes.headers.disputes'],
        value: 1
      },
      {
        id: 'goods_equipment',
        label: dataTr?.['goods_equipment.goods_equipment_packages_status.headers.goods_equipment_packages_status'],
        value: 1
      },
      {
        id: 'engineering',
        label: dataTr?.['engineering_design_documents.engineering.headers.engineering'],
        value: 1
  
      },
      {
        id: 'gender_tracking',
        label: dataTr?.['environment_social_health_safety_and_security_obligations.gender_tracking.headers.gender_tracking'],
        value: 1
  
      },
      {
        id: 'ssemp_activity',
        label: dataTr?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.headers.ssemp_activity_monitoring'],
        value: 1
  
      },
      {
        id: 'social_safeguard',
        label: dataTr?.['environment_social_health_safety_and_security_obligations.social_safeguard_monitoring.headers.social_safeguard_monitoring'],
        value: 1
  
      },
      {
        id: 'larp',
        label: dataTr?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.headers.larp_land_acquisition_resettlement_plan'],
        value: 1
  
      },
      {
        id: 'health_and_safety_monitoring',
        label: dataTr?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring.headers.health_and_safety_monitoring'],
        value: 1
  
      },
      {
        id: 'health_and_safety_statistic',
        label: dataTr?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.headers.health_and_safety_statistics'],
        value:1
      },
      {
        id: 'complaints',
        label: dataTr?.['environment_social_health_safety_and_security_obligations.complaints_grievances.headers.complaints_grievances'],
        value:1
      },
      {
        id: 'security_monitoring',
        label: dataTr?.['environment_social_health_safety_and_security_obligations.security_monitoring.headers.security_monitoring'],
        value:1
      },
      {
        id: 'site_photographs',
        label: dataTr?.['site_photographs.site_photographs.headers.site_photographs'],
        value:1
      },
      {
        id: 'contract_closure',
        label: dataTr?.['completion_of_work_and_contract_closure_activities_table.contract_closure.headers.contract_closure'],
        value:1
      },
      {
        id: 'contact_details',
        label: dataTr?.['main.edit_projects_details.headers.contract_details'],
        value:1
      },
    ]
    setSections(allSections);
  }
}, [dataTr])

  useEffect(() => {
    getAllSections()
  }, [])

  const params = {
    project: location?.pathname?.match(/\/\d+/g, "")[0].replace(/\//g, ""),
  };
  const allEnterDatas = JSON.parse(localStorage.getItem("all-edit-data"));

  const [
    editProject,
    {
      data: editData,
      isSuccess: editSuccess,
      error: editError,
      isError: editIsError,
      isLoading: editLoading,
    },
  ] = useUpdateProjectMutation();

  const [
    createProject,
    {
      data: createData,
      isSuccess: createSuccess,
      error: createError,
      isError: createIsError,
      isLoading: createLoading,
    },
  ] = useCreateProjectMutation();

  useEffect(() => {
    editLoading ? dispatch(setLoader(true)) : dispatch(setLoader(false));
  }, [editLoading]);

  useEffect(() => {
    if (editSuccess) {
      toast.success(editData.message);
      localStorage.removeItem("all-edit-data");
      localStorage.removeItem("edit-data");
      localStorage.removeItem("create-data");
      navigate(`/project/${params?.project}`);
    }
  }, [editSuccess, editData]);

  useEffect(() => {
    if (editIsError){
      errorHandle(editError)
    }
    if (createIsError){
      errorHandle(createError)
    }
  }, [editIsError, editError,createIsError])


  const submitHandler = (e) => {
    e.preventDefault();
    let s = {};
    for (let i = 0; i < sections?.length; i++) {
      s = {
        ...s,
        [sections[i]?.id]: sections[i]
          ?.value
          ? sections[i].value
          : 0,
      };
    }
    if (sections.length !== 0) {
      editProject({
        body: {
          ...JSON.parse(localStorage.getItem("edit-data")),
          location_id: JSON.parse(localStorage.getItem("edit-data"))
            ?.location_id?.value,
          contract_type_id: JSON.parse(localStorage.getItem("edit-data"))
            ?.contract_type_id?.value,
          sector_id: JSON.parse(localStorage.getItem("edit-data"))?.sector_id
            ?.value,
          ...s,
        },
        id: params?.project,
      })
    }
  }

  return (
    <form className="create__form">
      <div className="create__form-content">
        <h6 className="create__form-subtitle">
          {translations?.['main.edit_projects_details.titles.select_required_sections_for_this_project']}
        </h6>
        <div className="s-page__details">
          <div className="contact__modal-projectBox">
            <div className="contact__modal-checkbox">
              <div className="a-checkbox">
                <input
                  id="all"
                  key={"all"}
                  type="checkbox"
                  checked={
                    sections?.filter((el) => el?.value)?.length ===
                    sections?.length
                  }
                  onChange={(e) => {
                    if (e?.target?.checked) {
                      const s = sections?.map((item) => {
                        item.value = 1;
                        return item;
                      });
                      setSections(s);
                    } else {
                      const s = sections?.map((item) => {
                        item.value = 0;
                        return item;
                      });
                      setSections(s);
                    }
                  }}
                />
                <label htmlFor={"all"}>{translations?.['main.edit_projects_details.titles.select_all']}</label>
              </div>
            </div>
          </div>
          {sections?.map((el, idx) => (
            <div key={el?.id} className="contact__modal-projectBox">
              <div className="contact__modal-checkbox">
                <div className="a-checkbox">
                  <input
                    key={el?.id}
                    id={el?.id}
                    type="checkbox"
                    checked={el?.value}
                    onChange={(e) => {
                      const s = sections?.map((item) => {
                        if (item?.id === el?.id) {
                          if (e.target.checked) {
                            item.value = 1;
                          } else {
                            item.value = 0;
                          }
                        }
                        return item;
                      });
                      setSections(s);
                    }}
                  />
                  <label htmlFor={el?.id}>{el?.label}</label>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="create__form-br"></div>
      <div className="create__form-btns">
        <div
          className="create__form-back"
          onClick={() => {
            setSteps(3);
          }}
        >
          {translations?.['main.edit_projects_details.actions.back']}
        </div>
        <button onClick={submitHandler} className="create__form-next">
          <span>{translations?.['main.edit_projects_details.actions.next']}</span>
          <span>
            <img src="/assets/icons/next-icon.svg" alt="icon" />
          </span>
        </button>
      </div>
    </form>
  );
};
