import React from 'react'
import TabComponent from './TabComponent'

export default function TierModalComponent({data, fullData, closeSection, translations}) {
    return (
        <div className="larp-db__section">
            <div className="larp-db__section-content">
                <div className="larp-db__section-header">
                    <div></div>
                    <div className="larp-db__section-close" onClick={closeSection}>
                        <img src="/assets/icons/close.svg" alt="close" />
                    </div>
                </div>
                <div className="larp-db__section-hr"></div>
                <div className="larp-db__section-hero">
                    {/* <div className="larp-db__section-title">{data?.tier}</div> */}
                    <div className="larp-db__section-title">{
                        data?.tier === 'Foundation' ? translations?.['titles.foundation'] :
                            data?.tier === 'Line Stringing' ? translations?.['titles.line_stringing'] :
                                data?.tier === 'Tower Erection' ? translations?.['titles.tower_erection'] : ''}
                    </div>
                    <div className="larp-db__section-subtitle">{data?.section}</div>
                </div>
                <div className="larp-db__section-description">
                    <h4>{translations?.['titles.description']}</h4>
                    <div className="larp-db__section-subtitle">{fullData?.filter(d => d.section === data?.section)[0]?.description}</div>
                </div>
                <div className="larp-db__section-date">
                    <div>{translations?.['titles.report_date']}</div>
                    <div>{data?.report_date}</div>
                </div>
                <div className="larp-db__section-tabs">
                    <TabComponent type="paid" count={data?.total_paid} hasDollar={false} title={translations?.['titles.paid']}/>
                    <TabComponent type="escrowed" count={data?.total_escrow} hasDollar={false} title={translations?.['titles.escrowed']}/>
                    <TabComponent type="unpaid" count={data?.total_unpaid} hasDollar={false} title={translations?.['titles.unpaid']}/>
                </div>

                <div className="larp-db__section-heading">{translations?.['headers.affected_households']}</div>

                <div className="larp-db__section-table">
                    <div className="larp-db__section-row">
                        <div className="larp-db__section-cell th">{translations?.['titles.affected_household_name']}</div>
                        <div className="larp-db__section-cell th">{translations?.['titles.amount']}</div>
                        <div className="larp-db__section-cell th">{translations?.['titles.payment_status']}</div>
                        <div className="larp-db__section-cell th">{translations?.['titles.current_status_residual_issues']}</div>
                    </div>
                    {data?.affected_persons?.map((item, index) => {
                        return (
                            <div className="larp-db__section-row" key={index}>
                                <div className="larp-db__section-cell td" data-title="Affected Household/Entity Name">
                                    {item?.name}
                                </div>
                                <div className="larp-db__section-cell td"
                                     data-title="Amount">{item?.amount === '' ? '$0' : Number(item?.amount?.replace('$', ''))?.toLocaleString('en-US', {
                                         style: 'currency', currency: 'USD'})}
                                </div>
                                <div className={`larp-db__section-cell td status
                                 ${item?.payment_status.toLowerCase() === translations?.['titles.paid'].toLowerCase() ? 'paid': ''}
                                 ${item?.payment_status.toLowerCase() === translations?.['titles.escrow'].toLowerCase() ? 'escrow': ''}
                                 ${item?.payment_status.toLowerCase() === translations?.['titles.unpaid'].toLowerCase() ? 'unpaid': ''}`}
                                     data-title="Payment status">{item?.payment_status}
                                </div>
                                <div className="larp-db__section-cell td" data-title="Comment / Status / Residual Issues">
                                    {item?.comment}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="larp-db__section-b" onClick={closeSection}></div>
        </div>
    )
}
