import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import TabComponent from './TabComponent';
import ColorInfoComponent from './ColorInfoComponent';
import LarpTableComponent from './LarpTableComponent';
import { useDispatch } from 'react-redux';
import {
    useGetDashboardQuery,
    useLazyGetLarpDashboardTranslationsQuery,
    useLazyGetDashboardIndexTranslationsQuery }
    from '../../store/service/adbService';
import {setLoader} from "../../store/slice/dashboard";
import TierModalComponent from './TierModalComponent';
import SectionModalComponent from './SectionModalComponent';
import { useErrorHandler } from '../../global/ErrorsHandler';
import './index.scss'
import MoreInfoModal from "../../components/MoreInfoModal";


export default function LarpDashboard() {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const itemId = location?.pathname.match(/\d+$/)[0];

    const [larpData, setLarpData] = useState(null)
    const [totalData, setTotalData] = useState(null)
    const [currentTier, setCurrentTier] = useState(null)
    const [currentSection, setCurrentSection] = useState(null)
    const [translations, setTranslations] = useState(null)
    const [indexTranslations, setIndexTranslations] = useState(null)
    const [isOpenMoreInfo, setOpenMoreInfo] = useState(false);

    const {
        data: dashboardData,
        error: dashboardError,
        isLoading: dashboardIsLoading,
    } = useGetDashboardQuery(itemId);

    const [
        getDashboardIndexTranslations, {
            data: dashboardIndexTranslations,
            isLoading: dashboardIndexTranslationsIsLoading
        }
    ] = useLazyGetDashboardIndexTranslationsQuery()

    const [getLarpDashboardTranslations, {
        data: larpDashboardTranslationsData,
        isLoading: larpDashboardTranslationsIsLoading,
    }] = useLazyGetLarpDashboardTranslationsQuery()

    const openTier = (item) => {
        document.querySelector('html').style.overflow = 'hidden'
        setCurrentTier(item)
    }

    const [errorHandler, setErrorHandler] = useErrorHandler([dashboardError])

    useEffect(() => {
        getDashboardIndexTranslations();
        getLarpDashboardTranslations();
    }, []);

    useEffect(() => {
        dispatch(setLoader(dashboardIsLoading || larpDashboardTranslationsIsLoading || dashboardIndexTranslationsIsLoading));
    }, [dashboardIsLoading, larpDashboardTranslationsIsLoading, dashboardIndexTranslationsIsLoading, dispatch]);

    useEffect(() => {
        if (dashboardData) {
            if (!dashboardData?.data?.project_settings?.larp) {
                navigate('/project-list')
            } else {
                if (dashboardData?.data?.larp_dashboard) {
                    const arr = Object.values(dashboardData?.data?.larp_dashboard);
                    const totals = arr?.pop()
                    setTotalData(totals)
                    setLarpData(arr)
                }
            }
        }
    }, [dashboardData])

    useEffect(() => {
        if (larpDashboardTranslationsData) {
            setTranslations(larpDashboardTranslationsData.data?.translations);
        }
    }, [larpDashboardTranslationsData]);

    useEffect(() => {
        if(dashboardIndexTranslations){
            setIndexTranslations(dashboardIndexTranslations.data?.translations)
        }
    }, [dashboardIndexTranslations])

    const closeTier = () => {
        document.querySelector('html').style.overflow = 'visible'
        setCurrentTier(null)
    }

    const openSection = (item) => {
        document.querySelector('html').style.overflow = 'hidden'
        setCurrentSection(item)
    }

    const closeSection = () => {
        document.querySelector('html').style.overflow = 'visible'
        setCurrentSection(null)
    }

    return (
        <div className="larp-db">
            {isOpenMoreInfo &&
                <MoreInfoModal
                    title={indexTranslations?.['tabs.larp_dashboard']}
                    moreInfo={translations?.['more_info.more_info']}
                    close={()=>setOpenMoreInfo(false)}
                />
            }
            {currentTier && (
                <TierModalComponent
                    data={currentTier}
                    fullData={larpData}
                    closeSection={closeTier}
                    translations={translations}
                />
            )}
            {currentSection && (
                <SectionModalComponent data={currentSection} fullData={larpData} closeSection={closeSection} translations={translations}/>
            )}
            <div className="larp-db__header">
                <div className="larp-db__nav">
                    <Link to="/project-list">{indexTranslations?.['titles.projects']}</Link>
                    <p>/ {dashboardData?.data?.contract_title}</p>
                </div>
                <div className="larp-db__update">
                    <p>{indexTranslations?.['titles.last_update']} {dashboardData?.data?.last_updated_format}
                    </p>
                    <div onClick={()=>setOpenMoreInfo(!isOpenMoreInfo)}>
                        <img src="/assets/icons/info.svg" alt="info" />
                        <span>{indexTranslations?.['actions.more_info']}</span>
                    </div>
                </div>
            </div>
            <div className="larp-db__top">
                <div className="larp-db__head">
                    <div className="larp-db__title">
                        <h1>{dashboardData?.data?.contract_title}</h1>
                    </div>
                    <div className="larp-db__date">
                        <div className="project__dashboard-date">
                            {dashboardData?.data?.latest_report.date_format}
                            <img src="/assets/icons/calendar.svg" alt="" />
                        </div>
                    </div>
                </div>
                <div className="larp-db__sidebar">
                    {location?.state?.dash_visible ? <NavLink to={`/dashboard/${itemId}`} state={{dash_visible: location?.state?.dash_visible, larp_visible: location?.state?.larp_visible}}>{indexTranslations?.['tabs.project_dashboard']}</NavLink> : null}
                    {location?.state?.larp_visible ? <NavLink to={`/larp-dashboard/${itemId}`} state={{dash_visible: location?.state?.dash_visible, larp_visible: location?.state?.larp_visible}}>{indexTranslations?.['tabs.larp_dashboard']}</NavLink> : null }
                </div>
            </div>

            <div className="larp-db__container">
                <div className="larp-db__tabs">
                    <TabComponent
                        count={totalData?.total_paid_amount}
                        percent={totalData?.total_paid_percent}
                        type="paid"
                        title={translations?.['titles.paid']}/>
                    <TabComponent
                        count={totalData?.total_escrow_amount}
                        percent={totalData?.total_escrow_percent}
                        type="escrowed"
                        title={translations?.['titles.escrowed']}/>
                    <TabComponent
                        count={totalData?.total_unpaid_amount}
                        percent={totalData?.total_unpaid_percent}
                        type="unpaid"
                        title={translations?.['titles.unpaid']}/>
                    <TabComponent
                        count={totalData?.tptal_ammount}
                        percent={totalData?.tptal_persons}
                        type="total"
                        title={translations?.['titles.total_affected_households_entities']}/>
                </div>
                <ColorInfoComponent
                    id={itemId}
                    report_id={dashboardData?.data?.latest_report?.id}
                    colorTitles={[
                        translations?.['titles.paid'],
                        translations?.['titles.escrowed'],
                        translations?.['titles.unpaid']]}
                    actionTitles={[
                        translations?.['actions.download_to_csv'],
                        translations?.['titles.view_oline_report'],
                    ]}/>
                <LarpTableComponent
                    datas={larpData}
                    openTier={openTier}
                    openSection={openSection}
                    translations={translations}
                />
            </div>
        </div>
    )

}
