import axios from "axios";
import isEmpty from "lodash/isEmpty";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import {Cell, Pie, PieChart} from "recharts";
import { toast } from "react-toastify";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ProgressChart } from "../ProgressChart/ProgressChart";
import { ChartPie } from "../ChartPie";
import DownloadCSV from "../DownloadCSV";
import { VersusChart } from "../VersusChart";
import CustomDatePicker from "../CustomDatePicker";
import { setLoader } from "../../store/slice/dashboard";
import ContractDetails from "./components/ContractDetails";
import {useLazyGetReportsDetailsTranslationsQuery} from '../../store/service/adbService'
import "./style.scss";

export const OnlineView = ({ route }) => {
    const localeLan = localStorage.getItem("local_lan" );
    moment.locale(localeLan);
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [selectedDate, setSelectedDate] = useState("");
    const [reportId, setReportId] = useState(location?.pathname?.split("/")[2]);
    const [selectedDetails, setSelectedDetails] = useState(
        location?.state?.selectedDetails
    );
    const [compName, setCompName] = useState('')
    const [dateIsChanged, setDateIsChanged] = useState(false);
    const [reportDates, setReportDates] = useState([]);
    const [offsetTop, setOffsetTop] = useState(null);
    const [programProgressTableData, setProgramProgressTableData] = useState([]);
    const [sidebarActiveLink, setSidebarActiveLink] = useState(null);
    const refContractDetails = useRef(null);
    const refKeyData = useRef(null);
    const refPermits = useRef(null);
    const refExecutiveSummary = useRef(null);
    const refActivities = useRef(null);
    const refCover = useRef(null);
    const refMobilizationActivities = useRef(null);
    const refProgramProgress = useRef(null);
    const refSitePhotographs = useRef(null);
    const refFinancialManagement = useRef(null);
    const refDisputes = useRef(null);
    const refRiskManagement = useRef(null);
    const refGoodsEquipment = useRef(null);
    const refEngineering = useRef(null);
    const refEnvironment = useRef(null);
    const refCommunications = useRef(null);
    const refCompletion = useRef(null);
    const refDate = useRef(null);
    const [riskData, setRiskData] = useState([]);
    const [translations, setTranslations] = useState([]);

    const downloadCSVUrl=`/larp/section/export/csv/${reportId}`;

    const [
        getReportsDetailsTranslations,
        {
            data: reportDetailsTranslationsData,
            isError: reportDetailsTranslationsError,
            isLoading: reportDetailsTranslationsLoading
        }] = useLazyGetReportsDetailsTranslationsQuery()

    useEffect(() => {
        getReportsDetailsTranslations()
    }, [])

    useEffect(() => {
        setTranslations(reportDetailsTranslationsData?.data?.translations)
    }, [reportDetailsTranslationsData])

    // useEffect(() => {
    //     if(companyName && translations){
    //         companyName === 'EXECUTING AGENCY' ? translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.dropdown.executing_agency']) :
    //             companyName === 'IMPLEMENTING AGENCY' ? translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.dropdown.implementing_agency']) :
    //                 companyName === 'CONTRACTOR / SUPPLIER' ? translations?.['communications_and_reporting.contractor_supplier.headers.contractor_supplier']) :
    //                     companyName === 'ENGINEER / PROJECT MANAGER' ? translations?.['communications_and_reporting.engineer_project_manager.headers.engineer_project_manager']) :
    //                         companyName === 'ADB' ? translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.dropdown.adb']) :
    //                             companyName === 'DISPUTE BOARD MEMBERS' ? translations?.['communications_and_reporting.dispute_board_members.headers.dispute_board_members']) :
    //                                 companyName === 'OTHER' ? translations?.['communications_and_reporting.other.headers.other']) : '?')
    //     }
    // }, [companyName, translations])

    useEffect(() => {
        if (location?.state?.larp) {
            window.scrollTo(0, refActivities?.current?.scrollHeight)
        }
    }, [location, refActivities])

    useEffect(() => {
        const headers = {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ` + localStorage?.getItem("user_token"),
        };
        dispatch(setLoader(true));
        axios
            .get(`${process.env.REACT_APP_API_URL}/report/access-show/${reportId}`, {
                headers: headers,
            })
            .then((res) => {
                dispatch(setLoader(false));
                if (res?.data.data?.access) {
                } else {
                    setTimeout(() => {
                        toast.error("Access denied", {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    }, 0);
                    navigate("/project-list");
                }
            });
    }, []);

    useEffect(() => {
        if (location?.state?.prevLocation !== "/reporting") {
            getProjectNotByLocation();
        }
    }, [dateIsChanged]);
    useEffect(() => {
        if (data?.data?.project_settings?.program_progress) {
            const array = data?.data?.program_progress?.data;
            const chunkSize = 10;

            const result = [];
            const remainder = array.length % chunkSize;
            const lastChunk = array.slice(-remainder || undefined);

            for (let i = 0; i < array.length - remainder; i += chunkSize) {
                const chunk = array.slice(i, i + chunkSize);
                result.push(chunk);
            }

            if (remainder) {
                const paddedLastChunk = [
                    ...lastChunk,
                    ...Array(chunkSize - remainder).fill(null),
                ];
                result.push(paddedLastChunk);
            }
            setProgramProgressTableData(result);
        }
        if (!data?.data?.risk_management_chart?.low && !data?.data?.risk_management_chart?.moderate && !data?.data?.risk_management_chart?.substantial && !data?.data?.risk_management_chart?.high) {
            setRiskData([
                {name: "Low", value: 1},
                {name: "Moderate", value: 1},
                {name: "Substantial", value: 1},
                {name: "High", value: 1},
            ])
            setRiskColors(["#9a9e9a", "#9a9e9a", "#9a9e9a", "#9a9e9a"])
        } else {
            setRiskData([
                {name: "Low", value: +data?.data?.risk_management_chart?.low},
                {name: "Moderate", value: +data?.data?.risk_management_chart?.moderate},
                {name: "Substantial", value: +data?.data?.risk_management_chart?.substantial},
                {name: "High", value: +data?.data?.risk_management_chart?.high},
            ]);
            setRiskColors(["#0CAF60", "#ECC949", "#E7984F", "#E68484"]);
        }
    }, [data?.data]);
    useEffect(() => {
        dispatch(setLoader(true));
        getProjectByReport();
    }, [location?.state, selectedDetails]);
    const getProjectNotByLocation = () => {
        dispatch(setLoader(true));
        const headers = {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ` + localStorage?.getItem("user_token"),
        };
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/projects/settings-by-report/${reportId}`,
                { headers: headers }
            )
            .then((res) => {
                dispatch(setLoader(false));
                let s = [];
                for (let key in res?.data?.data) {
                    s = [
                        ...s,
                        {
                            label:
                                key == "larp"
                                    ? "LARP (Land Acquisition & Resettlement Plan)"
                                    : key == "larp_processing"
                                        ? "LARP Processing Table"
                                        : key?.split("_")?.join(" "),
                            value: Math.random(),
                            show: res?.data?.data[key],
                        },
                    ];
                }
                setSelectedDetails(s);
                if (location?.state?.larp) {
                    window.scrollTo(0, refActivities?.current?.scrollHeight)
                }
            })
            .catch((e) => {
                dispatch(setLoader(false));
                toast.error(e.response?.data?.message ?? "Report not found");
                navigate("/project-list");
                console.clear();
            });
    };
    const getProjectByReport = () => {
        const headers = {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ` + localStorage?.getItem("user_token"),
        };
        let s = {};
        for (let i = 0; i < selectedDetails?.length; i++) {
            s = {
                ...s,
                [selectedDetails[i]?.label?.split(" ").join("_").toLowerCase() ==
                "larp_(land_acquisition_&_resettlement_plan)"
                    ? "larp"
                    : selectedDetails[i]?.label?.split(" ").join("_").toLowerCase() ==
                    "larp_processing_table"
                        ? "larp_approval"
                        : selectedDetails[i]?.label?.split("/").join("_").toLowerCase() ===
                        "goods_equipment"
                            ? "goods_equipment"
                            : selectedDetails[i]?.label?.split(" ").join("_").toLowerCase()]:
                    selectedDetails[i]?.show ? selectedDetails[i].show : 0,
            };
        }

        const queryString = Object.keys(s)
            .map((key) => key + "=" + encodeURIComponent(s[key]))
            .join("&");
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/projects/by-report/${reportId}?${queryString}`,
                {
                    headers: headers,
                }
            )
            .then((res) => {
                setData(res?.data);
                setLoader(dispatch(false));
                if (location?.state?.larp) {
                    window.scrollTo(0, refActivities?.current?.scrollHeight)
                }
            })
            .catch((e) => {});
    };

    useEffect(() => {
        if (data?.data) {
            const headers = {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ` + localStorage?.getItem("user_token"),
            };
            setSelectedDate(moment(data?.data?.latest_report?.date)?.toDate());
            axios
                .get(
                    `${process.env.REACT_APP_API_URL}/report/submitted/${data?.data?.id}`,
                    { headers: headers }
                )
                .then((res) => {
                    dispatch(setLoader(false));
                    let response = [
                        ...res?.data?.data,
                    ];
                    setReportDates(
                        response?.map((el) => ({
                            value: el?.id,
                            label: moment(el?.date).toDate(),
                        }))
                    );
                    if (location?.state?.larp) {
                        window.scrollTo(0, refActivities?.current?.scrollHeight + 500)
                    }
                })
                .catch(() => {
                    dispatch(setLoader(false));
                });
        }
    }, [data?.data]);

    useEffect(() => {
        document.addEventListener("scroll", () => {
            setOffsetTop(window.scrollY);
        });
    }, []);

    const generateReport = () => {
        const lanCode = localStorage.getItem("local_lan" );
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ` + localStorage.getItem("user_token"),
            "AppLocale": `${lanCode ? lanCode : 'en'}`
        };
        dispatch(setLoader(true));
        let s = {
            project_id: data?.data?.id,
            report_id: reportId,
        };
        for (let i = 0; i < selectedDetails?.length; i++) {
            s = {
                ...s,
                [selectedDetails[i]?.value?.split(" ").join("_").toLowerCase() ==
                "larp_(land_acquisition_&_resettlement_plan)"
                    ? "larp"
                    : selectedDetails[i]?.value?.split(" ").join("_").toLowerCase() ==
                    "larp_processing_table"
                        ? "larp_approval"
                        : selectedDetails[i]?.value?.split("/").join("_").toLowerCase() ===
                        "goods_equipment"
                            ? "goods_equipment"
                            : selectedDetails[i]?.value?.split(" ").join("_").toLowerCase()]:
                    selectedDetails[i]?.show ? selectedDetails[i].show : 0,
            };
        }
        axios
            .post(`${process.env.REACT_APP_API_URL}/report/generate`, s, {
                headers: headers,
                responseType: "blob",
            })
            .then(({ data: blob }) => {
                const link = document.createElement("a");
                const url = URL.createObjectURL(blob);
                link.href = url;
                link.download = `report_${moment(
                    data?.data?.latest_report?.date
                )?.format("DD-MM-YYYY")}.docx`;
                link.click();
                dispatch(setLoader(false));
            })
            .catch((e) => {
                toast.error(
                    e?.data?.message ? e?.data?.message : "Something went wrong.",
                    {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    }
                );
                dispatch(setLoader(false));
            });
    };

    useEffect(() => {
        switch (true) {
            case (offsetTop >= 0 &&
                offsetTop <= refCover?.current?.offsetTop - 35 &&
                offsetTop <= refCover.scrollHeight) ||
            offsetTop === null:
                setSidebarActiveLink("cover");
                break;
            case offsetTop >= refKeyData?.current?.offsetTop - 35 &&
            offsetTop <=
            refKeyData?.current?.offsetTop -
            35 +
            refKeyData?.current?.scrollHeight:
                setSidebarActiveLink("key_data");
                break;
            case offsetTop >= refExecutiveSummary?.current?.offsetTop - 35 &&
            offsetTop <=
            refExecutiveSummary?.current?.offsetTop -
            35 +
            refExecutiveSummary?.current?.scrollHeight:
                setSidebarActiveLink("executive_summary");
                break;
            case offsetTop >= refPermits?.current?.offsetTop - 35 &&
            offsetTop <=
            refPermits?.current?.offsetTop -
            35 +
            refPermits?.current?.scrollHeight:
                setSidebarActiveLink("permits");
                break;

            case offsetTop >= refMobilizationActivities?.current?.offsetTop - 35 &&
            offsetTop <=
            refMobilizationActivities?.current?.offsetTop +
            refMobilizationActivities?.current?.scrollHeight:
                setSidebarActiveLink("mobilization_activities");
                break;
            case offsetTop >= refActivities?.current?.offsetTop - 35 &&
            offsetTop <=
            refActivities?.current?.offsetTop -
            35 +
            refActivities?.current?.scrollHeight:
                setSidebarActiveLink("contract");
                break;
            case offsetTop >= refRiskManagement?.current?.offsetTop - 35 &&
            offsetTop <=
            refRiskManagement?.current?.offsetTop -
            35 +
            refRiskManagement?.current?.scrollHeight:
                setSidebarActiveLink("risk_management");
                break;
            case offsetTop >= refProgramProgress?.current?.offsetTop - 35 &&
            offsetTop <=
            refProgramProgress?.current?.offsetTop -
            35 +
            refProgramProgress?.current?.scrollHeight:
                setSidebarActiveLink("program_progress");
                break;
            case offsetTop >= refFinancialManagement?.current?.offsetTop - 35 &&
            offsetTop <=
            refFinancialManagement?.current?.offsetTop -
            35 +
            refFinancialManagement?.current?.scrollHeight:
                setSidebarActiveLink("financial_management");
                break;
            case offsetTop >= refDisputes?.current?.offsetTop - 35 &&
            offsetTop <=
            refDisputes?.current?.offsetTop -
            35 +
            refDisputes?.current?.scrollHeight:
                setSidebarActiveLink("disputes");
                break;
            case offsetTop >= refGoodsEquipment?.current?.offsetTop &&
            offsetTop <=
            refGoodsEquipment?.current?.offsetTop +
            refGoodsEquipment?.current?.scrollHeight:
                setSidebarActiveLink("goods_equipment");
                break;
            case offsetTop >= refEngineering?.current?.offsetTop - 35 &&
            offsetTop <=
            refEngineering?.current?.offsetTop -
            35 +
            refEngineering?.current?.scrollHeight:
                setSidebarActiveLink("engineering");
                break;
            case offsetTop >= refEnvironment?.current?.offsetTop - 35 &&
            offsetTop <=
            refEnvironment?.current?.offsetTop -
            35 +
            refEnvironment?.current?.scrollHeight:
                setSidebarActiveLink("environment");
                break;
            case offsetTop >= refSitePhotographs?.current?.offsetTop - 35 &&
            offsetTop <=
            refSitePhotographs?.current?.offsetTop -
            35 +
            refSitePhotographs?.current?.scrollHeight:
                setSidebarActiveLink("site_photographs");
                break;
            case offsetTop >= refCompletion?.current?.offsetTop - 35 &&
            offsetTop <=
            refCompletion?.current?.offsetTop -
            35 +
            refCompletion?.current?.scrollHeight:
                setSidebarActiveLink("completion");
                break;
            case offsetTop >= refContractDetails?.current?.offsetTop - 35 &&
            offsetTop <=
            refContractDetails?.current?.offsetTop -
            35 +
            refContractDetails?.current?.scrollHeight:
                setSidebarActiveLink("contract_details");
                break;
            case offsetTop >= refCommunications?.current?.offsetTop - 35 &&
            offsetTop <=
            refCommunications?.current?.offsetTop -
            35 +
            refCommunications?.current?.scrollHeight:
                setSidebarActiveLink("communications_reporting");
                break;
            default:
                break;
        }
    }, [offsetTop]);

    const [allDataLarp, setAllDataLarp] = useState([])
    const [fixedData, setFixedData] = useState([])

    useEffect(() => {
        if (data?.data?.larp_dashboard) {
            const arr = Object.values(data?.data?.larp_dashboard);
            const totals = arr?.pop()
            setFixedData(arr)
        }
    }, [data])

    useEffect(() => {
        if (fixedData?.length > 0) {
            const newData = fixedData?.map(item => {
                const foundation = item?.tiers?.filter(elem => elem?.tier === translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.titles.foundation'])[0];
                const towerErection = item?.tiers?.filter(elem => elem?.tier === translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.titles.tower_erection'])[0];
                const lineStringing = item?.tiers?.filter(elem => elem?.tier === translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.titles.line_stringing'])[0];
                return { ...item, tiers: [foundation, towerErection, lineStringing] };
            });

            let convertedData = convertHeight(newData)
            setAllDataLarp(convertedData)
        }
    }, [fixedData]);

    const filterAffectedPersons = (elem, item, type) => {
        const s = elem?.affected_persons?.filter((person) => person?.payment_status === type)
    }

    const borderRadius = (type, elem) => {
        const {total_paid, total_unpaid, total_escrow} = elem
        if (type === 'paid') {
            if (total_escrow == 0 && total_unpaid == 0) {
                return {borderTopLeftRadius: 4, borderTopRightRadius: 4, borderBottomLeftRadius: 4, borderBottomRightRadius: 4}
            }
            if ((total_escrow == 0 && total_paid != 0 && total_unpaid != 0) || (total_escrow != 0 && total_paid != 0 && total_unpaid != 0) || (total_escrow != 0 && total_paid != 0 && total_unpaid == 0)) {
                return {borderBottomLeftRadius: 4, borderBottomRightRadius: 4}
            }
        }
        if (type === 'escrow') {
            if (total_paid == 0 && total_unpaid == 0) {
                return {borderTopLeftRadius: 4, borderTopRightRadius: 4, borderBottomLeftRadius: 4, borderBottomRightRadius: 4}
            }
            if (total_escrow !== 0 && total_paid != 0 && total_unpaid == 0) {
                return {borderTopLeftRadius: 4, borderTopRightRadius: 4}
            }
            if (total_escrow !== 0 && total_paid == 0 && total_unpaid != 0) {
                return {borderBottomLeftRadius: 4, borderBottomRightRadius: 4}
            }
        }

        if (type === 'unpaid') {
            if (total_paid == 0 && total_escrow == 0) {
                return {borderTopLeftRadius: 4, borderTopRightRadius: 4, borderBottomLeftRadius: 4, borderBottomRightRadius: 4}
            }
            if ((total_paid != 0 && total_escrow == 0) || (total_paid != 0 && total_escrow != 0) || (total_paid == 0 && total_escrow != 0)) {
                return {borderTopLeftRadius: 4, borderTopRightRadius: 4}
            }
        }
    }

    const convertHeight = (s) => {
        const a = s.map(item => {
            const b = { ...item };
            b.tiers = b.tiers.map(elem => {
                const unpaid = percentToInt(elem?.unpaid);
                const escrow = percentToInt(elem?.escrow);
                const paid = percentToInt(elem?.paid);
                const smallest = findSmallestNonZeroItem([{value: unpaid, label: 'unpaid'}, {value: escrow, label: 'escrow'}, {value: paid, label: 'paid'}], 'value')
                const maxNumber = findMaxNonZeroItem([{value: unpaid, label: 'unpaid'}, {value: escrow, label: 'escrow'}, {value: paid, label: 'paid'}], 'value');
                let height_unpaid = `${unpaid}%` ;
                let height_escrow = `${escrow}%` ;
                let height_paid = `${paid}%`;

                if (+maxNumber?.value > 80) {
                    if (maxNumber.label === 'unpaid') {
                        height_unpaid = escrow == 0 && paid == 0 ? '100%' : (escrow == 0 || paid == 0) ? '90%' : `80%`
                        height_escrow = escrow == 0 ? '0%' : `10%`
                        height_paid = paid == 0 ? '0%' : `10%`
                        return { ...elem, height_unpaid, height_escrow, height_paid};
                    } else if (maxNumber.label === 'escrow') {
                        height_unpaid = unpaid == 0 ? '0%' : `10%`
                        height_escrow = unpaid == 0 && paid == 0 ? '100%' : (unpaid == 0 || paid == 0) ? '90%' : `80%`
                        height_paid = paid == 0 ? '0%' : `10%`
                        return { ...elem, height_unpaid, height_escrow, height_paid};
                    } else {
                        height_unpaid = unpaid == 0 ? '0%' : `10%`
                        height_escrow = escrow == 0 ? '0%' : `10%`
                        height_paid = unpaid == 0 && escrow == 0 ? '100%' : (unpaid == 0 || escrow == 0) ? '90%' : `80%`;
                        return { ...elem, height_unpaid, height_escrow, height_paid};
                    }
                }

                let count = 0;

                if (smallest?.value < 10 && smallest?.value !== null) {
                    count = (smallest?.value - 10) * -1;
                    if (smallest.label === 'unpaid') {
                        height_unpaid = `10%`
                    } else if (smallest.label === 'escrow') {
                        height_escrow = `10%`
                    } else {
                        height_paid = `10%`
                    }

                    if (maxNumber.label === 'unpaid') {
                        height_unpaid = `${maxNumber.value - count}%`
                    } else if (maxNumber.label === 'escrow') {
                        height_escrow = `${maxNumber.value - count}%`
                    } else {
                        height_paid = `${maxNumber.value - count}%`
                    }
                }
                return { ...elem, height_unpaid, height_escrow, height_paid};
            });
            return b;
        });

        return a
    }

    function findMaxNonZeroItem(arr, key) {
        if (!arr.length) {
            return null;
        }

        const nonZeroItems = arr.filter(item => item[key] !== 0);

        if (!nonZeroItems.length) {
            return null;
        }

        let MaxtNonZeroItem = nonZeroItems[0];

        for (let i = 1; i < nonZeroItems.length; i++) {
            if (nonZeroItems[i][key] > MaxtNonZeroItem[key]) {
                MaxtNonZeroItem = nonZeroItems[i];
            }
        }

        return MaxtNonZeroItem;
    }

    const percentToInt = (percent) => {
        if (percent != 0 || percent != '0%') {
            return +String(percent).replace('%', '')
        }
        return 0
    }

    function findSmallestNonZeroItem(arr, key) {
        if (!arr.length) {
            return null;
        }

        const nonZeroItems = arr.filter(item => item[key] !== 0);

        if (!nonZeroItems.length) {
            return null;
        }

        let smallestNonZeroItem = nonZeroItems[0];

        for (let i = 1; i < nonZeroItems.length; i++) {
            if (nonZeroItems[i][key] < smallestNonZeroItem[key]) {
                smallestNonZeroItem = nonZeroItems[i];
            }
        }

        return smallestNonZeroItem;
    }

    const filterNonArchivedItems = (data) => {
        return data?.filter(item => item.archived === 0);
    };
    const filterArchivedItems = (data) => {
        return data?.filter(item => item.archived === 1);
    };
    const heightPaidWithEscrow = (elem1, elem2) => {
        let a = +elem1?.replace('%', '');
        let b = +elem2?.replace('%', '');
        return ((((a + b) * 170) / 100) / 2) - 18 - 10 + 'px'
    }

    const centerHeightPaidWithEscrow = (elem1, elem2) => {
        let a = +elem1?.replace('%', '');
        let b = +elem2?.replace('%', '');
        return (((a + b) * 170) / 100) + 'px'
    }
    const COLORS = ["#0CAF60", "#ECC949", "#3375D6"];
    const [RISK_COLORS, setRiskColors] = useState(["#0CAF60", "#ECC949", "#E7984F", "#E68484"]);

    return (
        <div className="online">
            <div className="project__sidebar online__sidebar">
                <div
                    onClick={() => {
                        refCover.current.scrollIntoView();
                    }}
                    className={`project__sidebar-item ${
                        (offsetTop >= 0 &&
                            offsetTop <= refCover?.current?.offsetTop - 35) ||
                        offsetTop === null
                            ? "active"
                            : ""
                    }`}
                    id={"cover"}
                >
                    {translations?.['main.main.titles.report_cover']}
                </div>

                {data?.data?.project_settings?.contract_key_data == true ||
                data?.data?.project_settings?.contract_key_data == 1 ? (
                    <div
                        onClick={() => {
                            refKeyData.current.scrollIntoView();
                        }}
                        className={`project__sidebar-item ${
                            offsetTop >= refKeyData?.current?.offsetTop - 35 &&
                            offsetTop <= refExecutiveSummary?.current?.offsetTop - 35
                                ? "active"
                                : ""
                        }`}
                        id={"key_data"}
                    >
                        {translations?.['contract_key_data.contract_key_data.headers.contract_key_data']}
                    </div>
                ) : null}
                {data?.data?.project_settings?.executive_summary == true ||
                data?.data?.project_settings?.executive_summary == 1 ? (
                    <div
                        onClick={() => {
                            refExecutiveSummary.current.scrollIntoView();
                        }}
                        className={`project__sidebar-item ${
                            offsetTop >= refExecutiveSummary?.current?.offsetTop - 35 &&
                            offsetTop <= refPermits?.current?.offsetTop - 35
                                ? "active"
                                : ""
                        }`}
                        id={"executive_summary"}
                    >
                        {translations?.['executive_summary.executive_summary.headers.executive_summary']}
                    </div>
                ) : null}
                {data?.data?.project_settings?.permits_licenses_approvals == true ||
                data?.data?.project_settings?.permits_licenses_approvals == 1 ? (
                    <div
                        onClick={() => {
                            refPermits.current.scrollIntoView();
                        }}
                        className={`project__sidebar-item ${
                            offsetTop >= refPermits?.current?.offsetTop - 35 &&
                            offsetTop <= refActivities?.current?.offsetTop - 35
                                ? "active"
                                : ""
                        }`}
                        id={"permits"}
                    >
                        {translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.headers.permits_licenses_and_approvals']}
                    </div>
                ) : null}
                {data?.data?.project_settings?.contract_activities == true ? (
                    <div
                        onClick={() => {
                            refActivities.current.scrollIntoView();
                        }}
                        className={`project__sidebar-item ${
                            offsetTop >= refActivities?.current?.offsetTop - 35 &&
                            offsetTop <= refMobilizationActivities?.current?.offsetTop - 35
                                ? "active"
                                : ""
                        }`}
                        id={"contract"}
                    >
                        {translations?.['contract_start_up_activities_and_submittals.contract_start_up_activities_and_submittals.headers.contract_start_up_activities_and_submittals']}
                    </div>
                ) : null}
                {data?.data?.project_settings?.mobilization_activities == true ? (
                    <div
                        onClick={() => {
                            refMobilizationActivities.current.scrollIntoView();
                        }}
                        className={`project__sidebar-item ${
                            offsetTop >= refMobilizationActivities?.current?.offsetTop - 35 &&
                            offsetTop <= refRiskManagement?.current?.offsetTop - 35
                                ? "active"
                                : ""
                        }`}
                        id={"mobilization_activities"}
                    >
                        {translations?.['general_start_up_activities.mobilization_activities.headers.mobilization_activities']}
                    </div>
                ) : null}
                {data?.data?.project_settings?.risk_management == true ? (
                    <div
                        onClick={() => {
                            refRiskManagement.current.scrollIntoView();
                        }}
                        className={`project__sidebar-item ${
                            offsetTop >= refRiskManagement?.current?.offsetTop - 35 &&
                            offsetTop <= refProgramProgress?.current?.offsetTop - 35
                                ? "active"
                                : ""
                        }`}
                        id={"risk_management"}
                    >
                        {translations?.['risk_management.risk_management.headers.risk_management']}
                    </div>
                ) : null}
                {data?.data?.project_settings?.project_status == true ? (
                    <div
                        onClick={() => {
                            refProgramProgress.current.scrollIntoView();
                        }}
                        className={`project__sidebar-item ${
                            offsetTop >= refProgramProgress?.current?.offsetTop - 35 &&
                            offsetTop <= refFinancialManagement?.current?.offsetTop - 35
                                ? "active"
                                : ""
                        }`}
                        id={"program_progress"}
                    >
                        {translations?.['project_monitoring.project_monitoring.headers.project_monitoring']}
                    </div>
                ) : null}
                {data?.data?.project_settings?.payments_overview == true ? (
                    <div
                        onClick={() => {
                            refFinancialManagement.current.scrollIntoView();
                        }}
                        className={`project__sidebar-item ${
                            offsetTop >= refFinancialManagement?.current?.offsetTop - 35 &&
                            offsetTop <= refDisputes?.current?.offsetTop - 35
                                ? "active"
                                : ""
                        }`}
                        id={"financial_management"}
                    >
                        {translations?.['payments.payments.headers.payments']}
                    </div>
                ) : null}
                {data?.data?.project_settings?.disputes == true ? (
                    <div
                        onClick={() => {
                            refDisputes.current.scrollIntoView();
                        }}
                        className={`project__sidebar-item ${
                            offsetTop >= refDisputes?.current?.offsetTop - 35 &&
                            offsetTop <= refGoodsEquipment?.current?.offsetTop - 35
                                ? "active"
                                : ""
                        }`}
                        id={"disputes"}
                    >
                        {translations?.['disputes.disputes.headers.disputes']}
                    </div>
                ) : null}
                {data?.data?.project_settings?.goods_equipment == true ? (
                    <div
                        onClick={() => {
                            refGoodsEquipment.current.scrollIntoView();
                        }}
                        className={`project__sidebar-item ${
                            offsetTop >= refGoodsEquipment?.current?.offsetTop - 35 &&
                            offsetTop <= refEngineering?.current?.offsetTop - 35
                                ? "active"
                                : ""
                        }`}
                        id={"goods_equipment"}
                    >
                        {translations?.['goods_equipment.goods_equipment.headers.goods_equipment']}
                    </div>
                ) : null}
                {data?.data?.project_settings?.engineering == true ? (
                    <div
                        onClick={() => {
                            refEngineering.current.scrollIntoView();
                        }}
                        className={`project__sidebar-item ${
                            offsetTop >= refEngineering?.current?.offsetTop - 35 &&
                            offsetTop <= refEnvironment?.current?.offsetTop - 35
                                ? "active"
                                : ""
                        }`}
                        id={"engineering"}
                    >
                        {translations?.['engineering_design_documents.engineering_design_documents.headers.engineering_design_documents']}
                    </div>
                ) : null}
                {data?.data?.project_settings?.social_safeguard == true ||
                data?.data?.project_settings?.gender_tracking == true ||
                data?.data?.project_settings?.social_safeguard == true ||
                data?.data?.project_settings?.ssemp_activity == true ||
                data?.data?.project_settings?.health_and_safety_monitoring == true ||
                data?.data?.project_settings?.health_and_safety_statistic == true ||
                data?.data?.project_settings?.complaints == true ||
                data?.data?.project_settings?.security_monitoring == true ? (
                    <div
                        onClick={() => {
                            refEnvironment.current.scrollIntoView();
                        }}
                        className={`project__sidebar-item ${
                            offsetTop >= refEnvironment?.current?.offsetTop - 35 &&
                            offsetTop <= refSitePhotographs?.current?.offsetTop - 35
                                ? "active"
                                : ""
                        }`}
                        id={"environment"}
                    >
                        {translations?.['environment_social_health_safety_and_security_obligations.environment_social_health_safety_and_security_obligations.headers.environment_social_health_safety_and_security_obligations']}
                    </div>
                ) : null}
                {data?.data?.project_settings?.site_photographs == true ? (
                    <div
                        onClick={() => {
                            refSitePhotographs.current.scrollIntoView();
                        }}
                        className={`project__sidebar-item ${
                            offsetTop >= refSitePhotographs?.current?.offsetTop - 35 &&
                            offsetTop <= refCompletion?.current?.offsetTop - 35
                                ? "active"
                                : ""
                        }`}
                        id={"site_photographs"}
                    >
                        {translations?.['site_photographs.site_photographs.headers.site_photographs']}
                    </div>
                ) : null}
                {data?.data?.project_settings?.contract_closure == true ? (
                    <div
                        onClick={() => {
                            refCompletion.current.scrollIntoView();
                        }}
                        className={`project__sidebar-item ${
                            offsetTop >= refCompletion?.current?.offsetTop - 35 &&
                            offsetTop <= refContractDetails?.current?.offsetTop - 35
                                ? "active"
                                : ""
                        }`}
                        id={"completion"}
                    >
                        {translations?.['completion_of_work_and_contract_closure_activities_table.completion_of_work_and_contract_closure_activities_table.headers.completion_of_work_and_contract_closure_activities_table']}
                    </div>
                ) : null}
                <div
                    onClick={() => {
                        refContractDetails.current.scrollIntoView();
                    }}
                    className={`project__sidebar-item ${
                        offsetTop >= refContractDetails?.current?.offsetTop - 35 &&
                        offsetTop <= refCommunications?.current?.offsetTop - 35
                            ? "active"
                            : ""
                    }`}
                    id={"contract_details"}
                >
                    {translations?.['main.edit_projects_details.headers.contract_details']}
                </div>
                {data?.data?.project_settings?.contact_details == true ? (
                    <div
                        onClick={() => {
                            refCommunications.current.scrollIntoView();
                        }}
                        className={`project__sidebar-item ${
                            offsetTop >= refCommunications?.current?.offsetTop - 35
                                ? "active"
                                : ""
                        }`}
                        id={"communications_reporting"}
                    >
                        {translations?.['communications_and_reporting.communications_and_reporting.headers.communications_and_reporting']}
                    </div>
                ) : null}
            </div>
            {data?.data ? (
                <div className="online__scroll">
                    <div className="online__download">
                        <button
                            onClick={() => {
                                navigate(
                                    location?.state?.prevLocation ?? "/project-list",
                                    { state: { tab: sidebarActiveLink } } ?? "/project-list"
                                );
                            }}
                            className="online__close"
                        >
                            <img src="/assets/icons/close.svg" alt="" />
                            {translations?.['main.main.actions.close']}
                        </button>
                        <button className="online__downloadBtn" onClick={generateReport}>
                            <svg
                                style={{ marginRight: 10 }}
                                width="20"
                                height="20"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25"
                                    stroke="white"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M5.25 7.5L9 11.25L12.75 7.5"
                                    stroke="white"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M9 11.25V2.25"
                                    stroke="white"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            {translations?.['main.main.actions.download_report']}
                        </button>
                    </div>
                    <div className="online__top">
                        <h1 className="online__title">
                            {translations?.['main.main.titles.report']} - {data?.data?.contract_title}
                        </h1>
                        <div className="online__calendar reporting-select">
                            <img
                                className="online__calendar-svg"
                                src="/assets/icons/calendar.svg"
                                alt="calendar"
                            />
                            <CustomDatePicker
                                id="online_view_calendar"
                                ref={refDate}
                                selected={selectedDate && selectedDate}
                                placeholderText="Click to select a date"
                                onChange={(date) => {
                                    setSelectedDate(date);
                                    setDateIsChanged(!dateIsChanged);
                                    getProjectNotByLocation();
                                    setReportId(
                                        reportDates?.filter(
                                            (el) =>
                                                moment(el?.label).format("MM-YYYY") ===
                                                moment(date)?.format("MM-YYYY")
                                        )[0]?.value
                                    );
                                }}
                                dateFormat="MM/yyyy"
                                includeDates={reportDates?.map((el) =>
                                    moment(el?.label).toDate()
                                )}
                                showMonthYearPicker
                            />
                            <label htmlFor="online_view_calendar">
                                <img
                                    onClick={() => refDate?.current?.setFocus()}
                                    className="online__calendar-arrow"
                                    src="/assets/icons/arrow-down-calendar.svg"
                                    alt="arrow"
                                />
                            </label>

                        </div>
                    </div>
                    <div className="online__content">
                        <div ref={refCover} className="online__content-container">
                            <h2 className="online__content-title">
                                {data?.data?.contract_title}
                                <br />
                                {translations?.['main.main.titles.monthly_progress_report']}
                                <br />
                                {moment(data?.data?.latest_report?.date)?.format("MMMM YYYY")}
                            </h2>
                            <div className="online__content-photos">
                                <div className="online__content-photo">
                                   <div>
                                       <img src={data?.data?.latest_report?.image_1} alt="" />
                                   </div>
                                    <p className="online__content-desc">
                                        {data?.data?.latest_report?.description_1}
                                    </p>
                                </div>
                                <div className="online__content-photo">
                                   <div>
                                       <img src={data?.data?.latest_report?.image_2} alt="" />
                                   </div>
                                    <p className="online__content-desc">
                                        {data?.data?.latest_report?.description_2}
                                    </p>
                                </div>
                                <div className="online__content-photo">
                                  <div>
                                      <img src={data?.data?.latest_report?.image_3} alt="" />
                                  </div>
                                    <p className="online__content-desc">
                                        {data?.data?.latest_report?.description_3}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {data?.data?.project_settings?.contract_key_data == true ? (
                            <div ref={refKeyData} className="online__content-projectRow">
                                <p className="online__content-boldTitle">1. {translations?.['contract_key_data.contract_key_data.headers.contract_key_data']}</p>
                                <p className="online__content-projectText">
                                    {translations?.['contract_key_data.contract_key_data.titles.formal_project_name'].toUpperCase()}
                                    <span>{data?.data?.contract_title}</span>
                                </p>
                                <p className="online__content-projectText">
                                    {translations?.['main.main.titles.project_summary'].toUpperCase()}
                                    <span>{data?.data?.contract_project_summary}</span>
                                </p>
                                <p className="online__content-projectText">
                                    {translations?.['contract_key_data.contract_key_data.titles.contract_scope'].toUpperCase()}
                                    <span>{data?.data?.contract_details?.contract_scope}</span>
                                </p>
                                <p className="online__content-projectText">
                                    {translations?.['contract_key_data.contract_key_data.titles.contract_type'].toUpperCase()}
                                    <span>
                    {data?.data?.contract_details?.contract_type?.name}
                  </span>
                                </p>
                                <p className="online__content-projectText">
                                    {translations?.['contract_key_data.contract_key_data.titles.executing_agency'].toUpperCase()}
                                    <span>
                    {data?.data?.contract_details?.executing_agency?.long_name}
                  </span>
                                </p>
                                <p className="online__content-projectText">
                                    {translations?.['contract_key_data.contract_key_data.titles.contractor'].toUpperCase()}
                                    <span>
                    {data?.data?.contract_details?.contractor?.long_name}
                  </span>
                                </p>
                                <p className="online__content-projectText">
                                    {translations?.['contract_key_data.contract_key_data.titles.consultant_other'].toUpperCase()}
                                    <span>
                    {data?.data?.contract_details?.design_consultant?.long_name}
                  </span>
                                </p>
                                <p className="online__content-projectText">
                                    {translations?.['contract_key_data.contract_key_data.titles.project_manager'].toUpperCase()}
                                    <span>
                    {data?.data?.contract_details?.project_manager?.long_name}
                  </span>
                                </p>
                                <p className="online__content-projectText">
                                    {translations?.['contract_key_data.contract_key_data.titles.original_contract_amount'].toUpperCase()}
                                    <span>
                    USD $
                                        {data?.data?.contract_details?.original_contract_amount
                                            .toString()
                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </span>
                                </p>
                                <p className="online__content-projectText">
                                    {translations?.['contract_key_data.contract_key_data.titles.new_contract_amount'].toUpperCase()}
                                    <span>
                    USD $
                                        {data?.data?.approved_contract_changes?.current_approved_contract_amount
                                            .toString()
                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </span>
                                </p>
                                <p className="online__content-projectText">
                                    {translations?.['contract_key_data.contract_key_data.titles.original_contract_completion_date'].toUpperCase()}
                                    <span>
                    {moment(
                        data?.data?.contract_details
                            ?.original_contract_completion_date
                    )?.format("MMMM DD, yyyy")}
                  </span>
                                </p>
                                <p className="online__content-projectText">
                                    {translations?.['approved_contract_changes.approved_contract_changes.titles.current_contract_completion_date'].toUpperCase()}
                                    <span>
                    {data?.data?.approved_contract_changes
                        ?.current_contract_completion_date ? moment(
                        data?.data?.approved_contract_changes
                            ?.current_contract_completion_date
                    )?.format("MMMM DD, yyyy") : ''}
                  </span>
                                </p>
                                <p className="online__content-projectText">
                                    {translations?.['contract_key_data.contract_key_data.titles.contract_signing_date'].toUpperCase()}
                                    <span>
                    {moment(
                        data?.data?.contract_details?.contract_signed_date
                    )?.format("MMMM DD, yyyy")}
                  </span>
                                </p>
                                <p className="online__content-projectText">
                                    {translations?.['contract_key_data.contract_key_data.titles.contract_commencement_effective_date'].toUpperCase()}
                                    <span>
                    {moment(
                        data?.data?.contract_details?.contract_commencement_effective_date
                    )?.format("MMMM DD, yyyy")}
                  </span>
                                </p>
                            </div>
                        ) : null}
                        {data?.data?.project_settings?.executive_summary == true ? (
                            <div
                                ref={refExecutiveSummary}
                                className="online__content-executive"
                            >
                                <p className="online__content-boldTitle">
                                    2. {translations?.['executive_summary.executive_summary.headers.executive_summary']}
                                </p>
                                <p className="online__content-text">
                                    {data?.data?.latest_report?.executive_summary}
                                </p>
                            </div>
                        ) : null}
                        {data?.data?.project_settings?.permits_licenses_approvals ==
                        true ? (
                            <div ref={refPermits} className="online__content-table">
                                <p className="online__content-boldTitle">
                                    3. {translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.headers.permits_licenses_and_approvals']}
                                </p>
                                <table>
                                    <thead className="dashboard__thead">
                                    <tr className="dashboard__tr">
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.table_headers.application_type']}
                                        </th>
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.table_headers.relevant_law_or_regulation']}
                                        </th>
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.table_headers.responsible_party']}
                                        </th>
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.table_headers.approving_authority']}
                                        </th>
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.table_headers.planned_date']}
                                        </th>
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.table_headers.actual_date']}
                                        </th>
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.table_headers.expiry_date']}
                                        </th>
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.table_headers.current_status_or_comments']}
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody className="dashboard__tbody">
                                    {
                                        filterNonArchivedItems(data?.data?.permits_licenses_and_approvals)?.map(
                                            (el, idx) => {
                                                return (
                                                    <tr className="dashboard__tr" key={idx}>
                                                        <td className="py-4 px-6">
                                                            {el?.application_type}
                                                        </td>
                                                        <td className="py-4 px-6">{el?.regulation}</td>
                                                        <td className="py-4 px-6">
                                                            {el?.responsible_party}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.approving_authority}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.planned_date
                                                                ? moment(el?.planned_date)?.format("DD/MM/YYYY")
                                                                : ""}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.actual_date
                                                                ? moment(el?.actual_date)?.format("DD/MM/YYYY")
                                                                : ""}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.expiry_date
                                                                ? moment(el?.expiry_date)?.format("DD/MM/YYYY")
                                                                : ""}
                                                        </td>
                                                        <td className="py-4 px-6">{el?.comment}</td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        ) : null}

                        {data?.data?.project_settings?.residual_tender_stage_actions == true ? (
                            <div ref={refActivities} className="online__content-table">
                                <p className="online__content-boldTitle">
                                    4. {translations?.['contract_start_up_activities_and_submittals.contract_start_up_activities_and_submittals.headers.contract_start_up_activities_and_submittals']}
                                </p>
                            </div>
                        ) : null}
                        {data?.data?.project_settings?.larp_approval == true ? (
                            <>
                                <p className="online__content-commonTitle">
                                    &nbsp; 4.1. {translations?.['contract_start_up_activities_and_submittals.larp_approval.headers.larp_approval']}
                                </p>
                                <table>
                                    <thead className="dashboard__thead">
                                    <tr className="dashboard__tr">
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['contract_start_up_activities_and_submittals.larp_approval.tooltips.contract_clause']}
                                        </th>
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['contract_start_up_activities_and_submittals.larp_approval.tooltips.implementation_status']}
                                        </th>
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['contract_start_up_activities_and_submittals.larp_approval.tooltips.compliance_report_planned_date']}
                                        </th>
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['contract_start_up_activities_and_submittals.larp_approval.tooltips.compliance_report_actual_date']}
                                        </th>
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['contract_start_up_activities_and_submittals.larp_approval.tooltips.current_status_or_comments']}
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody className="dashboard__tbody">
                                    {filterNonArchivedItems(data?.data?.larp_approval)?.map((el, idx) => {
                                        return (
                                            <tr className="dashboard__tr" key={idx}>
                                                <td className="py-4 px-6">
                                                    {el?.section_definition}
                                                </td>
                                                <td className="py-4 px-6">
                                                    {el?.implementation_status}
                                                </td>
                                                <td className="py-4 px-6">
                                                    {el?.planned_date
                                                        ? moment(el?.planned_date)?.format("DD/MM/YYYY")
                                                        : ""}
                                                </td>
                                                <td className="py-4 px-6">
                                                    {el?.actual_date
                                                        ? moment(el?.actual_date)?.format("DD/MM/YYYY")
                                                        : ""}
                                                </td>
                                                <td className="py-4 px-6">{el?.comment}</td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </>
                        ) : null}
                        {data?.data?.project_settings?.residual_tender_stage_actions == true ? (
                            <>
                                <p className="online__content-commonTitle">
                                    &nbsp; 4.2. {translations?.['general_start_up_activities.residual_tender_stage_actions.headers.residual_tender_stage_actions']}
                                </p>
                                <table>
                                    <thead className="dashboard__thead">
                                    <tr className="dashboard__tr">
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['general_start_up_activities.residual_tender_stage_actions.table_headers.contract_clause']}
                                        </th>
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['general_start_up_activities.residual_tender_stage_actions.table_headers.action_description']}
                                        </th>
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['general_start_up_activities.residual_tender_stage_actions.table_headers.responsible_party']}
                                        </th>
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['general_start_up_activities.residual_tender_stage_actions.table_headers.planned_date']}
                                        </th>
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['general_start_up_activities.residual_tender_stage_actions.table_headers.actual_date']}
                                        </th>
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['general_start_up_activities.residual_tender_stage_actions.table_headers.current_status_or_comments']}
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody className="dashboard__tbody">
                                    {data?.data?.residual_tender_stage_action?.map(
                                        (el, idx) => {
                                            return (
                                                <tr className="dashboard__tr" key={idx}>
                                                    <td className="py-4 px-6">
                                                        {el?.contract_clause}
                                                    </td>
                                                    <td className="py-4 px-6">
                                                        {el?.action_description}
                                                    </td>
                                                    <td className="py-4 px-6">
                                                        {el?.responsible_party}
                                                    </td>
                                                    <td className="py-4 px-6">
                                                        {el?.planned_date
                                                            ? moment(el?.planned_date)?.format(
                                                                "DD/MM/YYYY"
                                                            )
                                                            : ""}
                                                    </td>
                                                    <td className="py-4 px-6">
                                                        {el?.actual_date
                                                            ? moment(el?.actual_date)?.format(
                                                                "DD/MM/YYYY"
                                                            )
                                                            : ""}
                                                    </td>
                                                    <td className="py-4 px-6">{el?.comment}</td>
                                                </tr>
                                            );
                                        }
                                    )}
                                    </tbody>
                                </table>
                                <p className="online__content-commonTitle">
                                    &nbsp; 4.3. {translations?.['general_start_up_activities.contract_requirements.headers.contract_requirements']}
                                </p>
                                <table>
                                    <thead className="dashboard__thead">
                                    <tr className="dashboard__tr">
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['general_start_up_activities.contract_requirements.table_headers.contract_clause']}
                                        </th>
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['general_start_up_activities.contract_requirements.table_headers.action_description']}
                                        </th>
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['general_start_up_activities.contract_requirements.table_headers.responsible_party']}
                                        </th>
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['general_start_up_activities.contract_requirements.table_headers.planned_date']}
                                        </th>
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['general_start_up_activities.contract_requirements.table_headers.actual_date']}
                                        </th>
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['general_start_up_activities.contract_requirements.table_headers.expiry_date']}
                                        </th>
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['general_start_up_activities.contract_requirements.table_headers.current_status_or_comments']}
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody className="dashboard__tbody">
                                    {filterNonArchivedItems(data?.data?.contract_requirements)?.map((el, idx) => {
                                        return (
                                            <tr className="dashboard__tr" key={idx}>
                                                <td className="py-4 px-6">{el?.contract_clause}</td>
                                                <td className="py-4 px-6">
                                                    {el?.action_description}
                                                </td>
                                                <td className="py-4 px-6">
                                                    {el?.responsible_party}
                                                </td>
                                                <td className="py-4 px-6">
                                                    {el?.planned_date
                                                        ? moment(el?.planned_date)?.format("DD/MM/YYYY")
                                                        : ""}
                                                </td>
                                                <td className="py-4 px-6">
                                                    {el?.actual_date
                                                        ? moment(el?.actual_date)?.format("DD/MM/YYYY")
                                                        : ""}
                                                </td>
                                                <td className="py-4 px-6">
                                                    {!el?.not_applicable
                                                        ? el?.expiry_date ? moment(el?.expiry_date)?.format("DD/MM/YYYY") : ""
                                                        : " "}
                                                </td>
                                                <td className="py-4 px-6">{el?.comment}</td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                                <p className="online__content-commonTitle">
                                    &nbsp; 4.4. {translations?.['general_start_up_activities.initial_contract_activities.headers.initial_contract_activities']}
                                </p>
                                <table>
                                    <thead className="dashboard__thead">
                                    <tr className="dashboard__tr">
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['general_start_up_activities.initial_contract_activities.table_headers.contract_clause']}
                                        </th>
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['general_start_up_activities.initial_contract_activities.table_headers.action_description']}
                                        </th>
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['general_start_up_activities.initial_contract_activities.table_headers.responsible_party']}
                                        </th>
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['general_start_up_activities.initial_contract_activities.table_headers.planned_date']}
                                        </th>
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['general_start_up_activities.initial_contract_activities.table_headers.actual_date']}
                                        </th>
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['general_start_up_activities.initial_contract_activities.table_headers.expiry_date']}
                                        </th>
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['general_start_up_activities.initial_contract_activities.table_headers.current_status_or_comments']}
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody className="dashboard__tbody">
                                    {data?.data?.initial_contract_activities?.map(
                                        (el, idx) => {
                                            return (
                                                <tr className="dashboard__tr" key={idx}>
                                                    <td className="py-4 px-6">
                                                        {el?.contract_clause}
                                                    </td>
                                                    <td className="py-4 px-6">
                                                        {el?.action_description}
                                                    </td>
                                                    <td className="py-4 px-6">
                                                        {el?.responsible_party}
                                                    </td>
                                                    <td className="py-4 px-6">
                                                        {el?.planned_date
                                                            ? moment(el?.planned_date)?.format(
                                                                "DD/MM/YYYY"
                                                            )
                                                            : ""}
                                                    </td>
                                                    <td className="py-4 px-6">
                                                        {el?.actual_date
                                                            ? moment(el?.actual_date)?.format(
                                                                "DD/MM/YYYY"
                                                            )
                                                            : ""}
                                                    </td>
                                                    <td className="py-4 px-6">
                                                        {!el?.not_applicable
                                                            ? el?.expiry_date ? moment(el?.expiry_date)?.format("DD/MM/YYYY") : ""
                                                            : ""}
                                                    </td>
                                                    <td className="py-4 px-6">{el?.comment}</td>
                                                </tr>
                                            );
                                        }
                                    )}
                                    </tbody>
                                </table>
                            </>
                        ) : null}
                        {data?.data?.project_settings?.mobilization_activities == true ? (
                            <div ref={refMobilizationActivities}>
                                <p className="online__content-commonTitle">
                                    &nbsp; 4.5. {translations?.['general_start_up_activities.mobilization_activities.headers.mobilization_activities']}
                                </p>
                                <table>
                                    <thead className="dashboard__thead">
                                    <tr className="dashboard__tr">
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['general_start_up_activities.mobilization_activities.table_headers.contract_clause']}
                                        </th>
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['general_start_up_activities.mobilization_activities.table_headers.action_description']}
                                        </th>
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['general_start_up_activities.mobilization_activities.table_headers.reference']}
                                        </th>
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['general_start_up_activities.mobilization_activities.table_headers.planned_date']}
                                        </th>
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['general_start_up_activities.mobilization_activities.table_headers.actual_date']}
                                        </th>
                                        <th scope="col" className="py-3 px-6 dashboard__th">
                                            {translations?.['general_start_up_activities.mobilization_activities.table_headers.variance_days']}
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody className="dashboard__tbody">
                                    {data?.data?.mobilization_activities?.map((el, idx) => {
                                        return (
                                            <tr className="dashboard__tr" key={idx}>
                                                <td className="py-4 px-6">{el?.contract_clause}</td>
                                                <td className="py-4 px-6">
                                                    {el?.action_description}
                                                </td>
                                                <td className="py-4 px-6">{idx + 1}</td>
                                                <td className="py-4 px-6">
                                                    {el?.planned_date
                                                        ? moment(el?.planned_date)?.format("DD/MM/YYYY")
                                                        : ""}
                                                </td>
                                                <td className="py-4 px-6">
                                                    {el?.actual_date
                                                        ? moment(el?.actual_date)?.format("DD/MM/YYYY")
                                                        : ""}
                                                </td>
                                                <td className="py-4 px-6">{el?.variance}</td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        ) : null}

                        <div className="online__content-table">
                            {data?.data?.project_settings?.risk_management == true ? (
                                <div ref={refRiskManagement}>
                                    <p className="online__content-boldTitle">
                                        5. {translations?.['risk_management.risk_management.headers.risk_management']}
                                    </p>
                                    <table>
                                        <thead className="dashboard__thead">
                                        <tr className="dashboard__tr">
                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                {translations?.['risk_management.risk_management.table_headers.ref']}
                                            </th>
                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                {translations?.['risk_management.risk_management.table_headers.risk_description']}
                                            </th>
                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                {translations?.['risk_management.risk_management.table_headers.impact_rating']}
                                            </th>
                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                {translations?.['risk_management.risk_management.table_headers.likelihood_rating']}
                                            </th>
                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                {translations?.['risk_management.risk_management.table_headers.risk_level']}
                                            </th>
                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                {translations?.['risk_management.risk_management.table_headers.mitigation_measure']}
                                            </th>
                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                {translations?.['risk_management.risk_management.table_headers.responsible_party']}
                                            </th>
                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                {translations?.['risk_management.risk_management.table_headers.current_status_or_comments']}
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody className="dashboard__tbody">
                                        {filterNonArchivedItems(data?.data?.risk_management)?.map((el, idx) => {
                                            return (
                                                <tr className="dashboard__tr" key={idx}>
                                                    <td className="py-4 px-6">
                                                        {el?.risk_reference}
                                                    </td>
                                                    <td className="py-4 px-6">
                                                        {el?.risk_description}
                                                    </td>
                                                    <td className="py-4 px-6">
                                                        {el?.impact_rating_text}
                                                    </td>
                                                    <td className="py-4 px-6">
                                                        {el?.likelihood_rating_text}
                                                    </td>
                                                    <td className="py-4 px-6">
                                                        {el?.risk_level_text}
                                                    </td>
                                                    <td className="py-4 px-6">
                                                        {el?.mitigation_measure}
                                                    </td>
                                                    <td className="py-4 px-6">
                                                        {el?.responsible_party}
                                                    </td>
                                                    <td className="py-4 px-6">
                                                        {el?.current_status}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                        </tbody>
                                    </table>
                                    <div className='plannedProject__charts-wrapp'>
                                        <div className="plannedProject__chart">
                                            <div className='plannedProject__charts' >
                                                <p className="plannedProject__chart-title">{translations?.['project_monitoring.program_progress_bar_chart.headers.program_progress_bar_chart']}</p>
                                                <div className="project__dashboard-pieChart" style={{ display: 'flex', alignItems:'center', justifyContent: 'space-around' }}>
                                                    <div className="project__dashboard-pieBlock">
                                                        <PieChart height={152} width={152}>
                                                            <Pie
                                                                data={[
                                                                    {name: "Overall progress", value: +data?.data?.program_pie_chart?.overall_progress},
                                                                    {name: "Time elapsed", value: +data?.data?.program_pie_chart?.time_elapsed},
                                                                    {name: "Time remaining", value: +data?.data?.program_pie_chart?.time_remaining},
                                                                ]}
                                                                dataKey="value"
                                                                nameKey="name"
                                                                color="#000000"
                                                                fill="#8884d8"
                                                                cx="50%"
                                                                cy="50%"
                                                                innerRadius={35}
                                                            >
                                                                {
                                                                    [
                                                                        {name: "Overall progress", value: +data?.data?.program_pie_chart?.overall_progress},
                                                                        {name: "Time elapsed", value: +data?.data?.program_pie_chart?.time_elapsed},
                                                                        {name: "Time remaining", value: +data?.data?.program_pie_chart?.time_remaining},
                                                                    ]?.map((el,idx) => (
                                                                        <Cell  key={`cell-${idx}`}
                                                                               fill={COLORS[idx % COLORS.length]}
                                                                        />
                                                                    ))
                                                                }
                                                            </Pie>
                                                        </PieChart>
                                                    </div>
                                                    <div className="project__dashboard-stats">
                                                        <div className="project__dashboard-stat">
                                                            <div className="project__dashboard-statBox">
                                                                <div className="project__program-color green"></div>
                                                                <p>{translations?.['project_monitoring.program_overview.titles.overall_progress']}</p>
                                                            </div>
                                                            <span>{data?.data?.program_pie_chart?.overall_progress ? data?.data?.program_pie_chart?.overall_progress : '0'}%</span>
                                                        </div>
                                                        <div className="project__dashboard-stat">
                                                            <div className="project__dashboard-statBox">
                                                                <div className="project__program-color yellow"></div>
                                                                <p>{translations?.['project_monitoring.program_overview.titles.time_elapsed']}</p>
                                                            </div>
                                                            <span>{data?.data?.program_pie_chart?.time_elapsed ? data?.data?.program_pie_chart?.time_elapsed : '0'}%</span>
                                                        </div>
                                                        <div className="project__dashboard-stat">
                                                            <div className="project__dashboard-statBox">
                                                                <div className="project__program-color blue"></div>
                                                                <p>{translations?.['project_monitoring.program_overview.titles.time_remaining']}</p>
                                                            </div>
                                                            <span>{data?.data?.program_pie_chart?.time_remaining ? data?.data?.program_pie_chart?.time_remaining : '0'}%</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="plannedProject__chart" style={{ marginLeft: '20px'}}>
                                            <div className='plannedProject__charts' >
                                                <p className="plannedProject__chart-title">{translations?.['risk_management.risk_management.table_headers.risk_level']}</p>
                                                <div className="project__dashboard-pieChart" style={{ display: 'flex', alignItems:'center', justifyContent: 'space-around' }}>
                                                    <div className="project__dashboard-pieBlock">
                                                        <PieChart height={152} width={152}>
                                                            {

                                                            }
                                                            <Pie
                                                                data={riskData}
                                                                dataKey="value"
                                                                nameKey="name"
                                                                color="#000000"
                                                                fill="#8884d8"
                                                                cx="50%"
                                                                cy="50%"
                                                                innerRadius={35}
                                                            >
                                                                {
                                                                    riskData?.map((el,idx) => (
                                                                        <Cell  key={`cell-${idx}`}
                                                                               fill={RISK_COLORS[idx % RISK_COLORS.length]}
                                                                        />
                                                                    ))
                                                                }
                                                            </Pie>
                                                        </PieChart>
                                                    </div>
                                                    <div className="project__dashboard-stats">
                                                        <div className="project__dashboard-stat">
                                                            <div className="project__dashboard-statBox">
                                                                <div className="project__program-color green"></div>
                                                                <p>{translations?.['risk_management.risk_management.dropdown.low']}</p>
                                                            </div>
                                                            <span>{data?.data?.risk_management_chart?.low || '0'}%</span>
                                                        </div>
                                                        <div className="project__dashboard-stat">
                                                            <div className="project__dashboard-statBox">
                                                                <div className="project__program-color yellow"></div>
                                                                <p>{translations?.['risk_management.risk_management.dropdown.moderate']}</p>
                                                            </div>
                                                            <span>{data?.data?.risk_management_chart?.moderate || '0'}%</span>
                                                        </div>
                                                        <div className="project__dashboard-stat">
                                                            <div className="project__dashboard-statBox">
                                                                <div className="project__program-color orange"></div>
                                                                <p>{translations?.['risk_management.risk_management.dropdown.substantial']}</p>
                                                            </div>
                                                            <span>{data?.data?.risk_management_chart?.substantial || '0'}%</span>
                                                        </div>
                                                        <div className="project__dashboard-stat">
                                                            <div className="project__dashboard-statBox">
                                                                <div className="project__program-color red"></div>
                                                                <p>{translations?.['risk_management.risk_management.dropdown.high']}</p>
                                                            </div>
                                                            <span>{data?.data?.risk_management_chart?.high || '0'}%</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className="online__content-table">
                            <div ref={refProgramProgress}>
                                {data?.data?.project_settings?.project_status == true ? (
                                    <>
                                        <p className="online__content-boldTitle">
                                            6. {translations?.['project_monitoring.project_monitoring.headers.project_monitoring']}
                                        </p>
                                        <p className="online__content-commonTitle">
                                            &nbsp;6.1. {translations?.['project_monitoring.project_monitoring.titles.project_status_as_of_date']}
                                        </p>
                                        <div className="online__content-notFullTable">
                                            <div className="online__content-projectStatusRow">
                                                {translations?.['project_monitoring.project_monitoring.titles.project_status_as_of_date']}
                                                <span>
                          {data?.data?.project_monitoring?.status_as_of_date
                              ? moment(
                                  data?.data?.project_monitoring
                                      ?.status_as_of_date
                              )?.format("DD/MM/YYYY")
                              : ""}
                        </span>
                                            </div>
                                            <div className="online__content-projectStatusRow">
                                                {translations?.['project_monitoring.project_monitoring.titles.current_contract_completion_date']}
                                                <span>
                          {data?.data?.project_monitoring?.current_contract_completion_date
                              ? moment(
                                  data?.data?.project_monitoring
                                      ?.current_contract_completion_date
                              )?.format("DD/MM/YYYY")
                              : ""}
                        </span>
                                            </div>
                                            <div className="online__content-projectStatusRow">
                                                {translations?.['project_monitoring.project_monitoring.titles.original_contract_completion_date']}
                                                <span>
                          {data?.data?.project_monitoring
                              ?.original_completion_date
                              ? moment(
                                  data?.data?.project_monitoring
                                      ?.original_completion_date
                              )?.format("DD/MM/YYYY")
                              : ""}
                        </span>
                                            </div>
                                            <div className="online__content-projectStatusRow">
                                                {translations?.['project_monitoring.project_monitoring.titles.project_status_time_elapsed']}
                                                <span>
                          {data?.data?.project_monitoring?.status_time_elapsed
                              ? moment(
                                  data?.data?.project_monitoring
                                      ?.status_time_elapsed
                              )?.format("DD/MM/YYYY")
                              : ""}
                        </span>
                                            </div>
                                            <div className="online__content-projectStatusRow">
                                                {translations?.['project_monitoring.project_monitoring.titles.planned_progress']}
                                                <span>
                          {`${data?.data?.project_monitoring?.planned_progress}%`}
                        </span>
                                            </div>
                                            <div className="online__content-projectStatusRow">
                                                {translations?.['project_monitoring.project_monitoring.titles.actual_progress']}
                                                <span>
                          {`${data?.data?.project_monitoring?.actual_progress}%`}
                        </span>
                                            </div>
                                            <div className="online__content-projectStatusRow">
                                                {translations?.['project_monitoring.project_monitoring.titles.expected_completion_date']}
                                                <span>
                          {data?.data?.project_monitoring
                              ?.expected_completion_date
                              ? moment(
                                  data?.data?.project_monitoring
                                      ?.expected_completion_date
                              )?.format("DD/MM/YYYY")
                              : ""}
                        </span>
                                            </div>
                                            <div className="online__content-projectStatusRow">
                                                {translations?.['project_monitoring.project_monitoring.titles.variance_to_completion']}
                                                <span>
                          {
                              data?.data?.project_monitoring
                                  ?.variance_to_completion
                          }
                        </span>
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                                {data?.data?.project_settings?.major_project_milestones ==
                                true ? (
                                    <>
                                        <p className="online__content-commonTitle">
                                            &nbsp; 6.2. {translations?.['project_monitoring.major_project_milestones.headers.major_project_milestones']}
                                        </p>
                                        <table>
                                            <thead className="dashboard__thead">
                                            <tr className="dashboard__tr">
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.major_project_milestones.table_headers.ref']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.major_project_milestones.table_headers.description']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.major_project_milestones.table_headers.original_program_milestone_completion_date']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.major_project_milestones.table_headers.updated_completion_date']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.major_project_milestones.table_headers.variance_days']}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="dashboard__tbody">
                                            {filterNonArchivedItems(data?.data?.level_2_program)?.map((el, idx) => {
                                                return (
                                                    <tr className="dashboard__tr" key={idx}>
                                                        <td className="py-4 px-6">
                                                            {el?.program_reference}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.program_description}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.original_completion_date
                                                                ? moment(
                                                                    el?.original_completion_date
                                                                )?.format("DD/MM/YYYY")
                                                                : ""}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.updated_completion_date
                                                                ? moment(el?.updated_completion_date)?.format(
                                                                    "DD/MM/YYYY"
                                                                )
                                                                : ""}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.program_variance}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    </>
                                ) : null}
                                {data?.data?.project_settings?.program_progress == true ? (
                                    <>
                                        <p className="online__content-commonTitle">
                                            &nbsp; 6.3. {translations?.['project_monitoring.program_progress.headers.program_progress']}
                                        </p>
                                        {data?.data?.latest_report?.id ? (
                                            <ProgressChart
                                                translations={translations}
                                                data={data?.data}
                                                id={data?.data?.latest_report?.id}
                                            />
                                        ) : null}
                                        <div style={{ marginBottom: 20 }}></div>
                                        {programProgressTableData?.map((item, idx) => (
                                            <>
                                                <div className="s-program-progress-table" key={idx}>
                                                    <div className="s-program-progress-table-header">
                                                        {translations?.['project_monitoring.program_progress.titles.months']}
                                                    </div>
                                                    {item?.map((elem, idx) => (
                                                        <div
                                                            key={idx}
                                                            className={`s-program-progress-table-header ${
                                                                elem === null ? "none" : ""
                                                            }`}
                                                        >
                                                            {elem !== null
                                                                ? elem?.date
                                                                    ? moment(elem?.date).format("MMM YYYY")
                                                                    : ""
                                                                : null}
                                                        </div>
                                                    ))}
                                                    <div className="s-program-progress-table-planed border">
                                                        <span className="red"></span> {translations?.['project_monitoring.program_progress.titles.planned']}
                                                    </div>
                                                    {item?.map((elem, idx) => (
                                                        <div
                                                            key={idx}
                                                            className={`s-program-progress-table-planed ${
                                                                elem === null ? "none" : ""
                                                            }`}
                                                        >
                                                            {elem !== null ? (
                                                                <div>{elem?.planned_amount} %</div>
                                                            ) : null}
                                                        </div>
                                                    ))}
                                                    <div className="s-program-progress-table-planed border">
                                                        <span className="lightblue"></span> {translations?.['project_monitoring.program_progress.titles.actual']}
                                                    </div>
                                                    {item?.map((elem, idx) => (
                                                        <div
                                                            key={idx}
                                                            className={`s-program-progress-table-planed ${
                                                                elem === null ? "none" : ""
                                                            } ${!elem?.editable ? "editable" : ""}`}
                                                        >
                                                            {elem !== null ? (
                                                                <div>
                                                                    {elem?.actual_amount !== null
                                                                        ? `${elem?.actual_amount} %`
                                                                        : "-"}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    ))}
                                                    <div className="s-program-progress-table-planed border">
                                                        <span className="blue"></span> {translations?.['project_monitoring.program_progress.titles.cumulative_planned']}
                                                    </div>
                                                    {item?.map((elem, idx) => (
                                                        <div
                                                            key={idx}
                                                            className={`s-program-progress-table-planed cumulative ${
                                                                elem === null ? "none" : ""
                                                            }`}
                                                        >
                                                            {elem !== null ? (
                                                                <div>
                                                                    {elem?.cumulative_planned_amount !== null
                                                                        ? `${elem?.cumulative_planned_amount} %`
                                                                        : "-"}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    ))}
                                                    <div className="s-program-progress-table-planed border">
                                                        <span className="yellow"></span> {translations?.['project_monitoring.program_progress.titles.cumulative_actual']}
                                                    </div>
                                                    {item?.map((elem, idx) => (
                                                        <div
                                                            key={idx}
                                                            className={`s-program-progress-table-planed cumulative ${
                                                                elem === null ? "none" : ""
                                                            }`}
                                                        >
                                                            {elem !== null ? (
                                                                <div>
                                                                    {elem?.cumulative_actual_amount !== null
                                                                        ? `${elem?.cumulative_actual_amount} %`
                                                                        : "-"}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    ))}
                                                </div>

                                            </>
                                        ))}
                                        <div className="plannedProject__total-block">
                                            <p className="plannedProject__total-text">{translations?.['project_monitoring.program_progress.titles.cumulative_planned']}</p>
                                            <p className="plannedProject__total-text">{data?.data?.program_progress?.total_planned}%</p>
                                        </div>
                                        <div className="plannedProject__total-block">
                                            <p className="plannedProject__total-text">{translations?.['project_monitoring.program_progress.titles.cumulative_actual']}</p>
                                            <p className="plannedProject__total-text">{data?.data?.program_progress?.total_actual}%</p>
                                        </div>
                                    </>
                                ) : null}
                                {data?.data?.project_settings?.program_overview == true ? (
                                    <>
                                        <p className="online__content-commonTitle">
                                            &nbsp; 6.4. {translations?.['project_monitoring.program_overview.headers.program_overview']}
                                        </p>
                                        {data?.data?.latest_report?.id ? (
                                            <ChartPie
                                                translations={translations}
                                                reportId={data?.data?.latest_report?.id}
                                                id={data?.data?.id}
                                                isPage={true}
                                            />
                                        ) : null}
                                    </>
                                ) : null}
                                {data?.data?.project_settings?.program_overview == true ? (
                                    <>
                                        <p
                                            style={{ marginTop: 20 }}
                                            className="online__content-text"
                                        >
                                            {data?.data?.program_overview}
                                        </p>
                                    </>
                                ) : null}
                                {data?.data?.project_settings?.critical_path == true ? (
                                    <>
                                        <p className="online__content-boldTitle">
                                            {translations?.['project_monitoring.critical_path.headers.critical_path']}
                                        </p>
                                        <p className="online__content-text">
                                            {data?.data?.critical_path}
                                        </p>
                                    </>
                                ) : null}
                                {data?.data?.project_settings?.one_month_look_ahead == true ? (
                                    <>
                                        <p className="online__content-commonTitle">
                                            &nbsp; 6.5. {translations?.['project_monitoring.one_month_look_ahead.headers.one_month_look_ahead']}
                                        </p>
                                        <table>
                                            <thead className="dashboard__thead">
                                            <tr className="dashboard__tr">
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.one_month_look_ahead.table_headers.target_description']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.one_month_look_ahead.table_headers.planned_date']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.one_month_look_ahead.table_headers.completion_date']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.one_month_look_ahead.table_headers.completed']}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="dashboard__tbody">
                                            {data?.data?.one_month_look_ahead?.map((el, idx) => {
                                                return (
                                                    <tr className="dashboard__tr" key={idx}>
                                                        <td className="py-4 px-6">{el?.description}</td>
                                                        <td className="py-4 px-6">
                                                            {el?.target_date
                                                                ? moment(el?.target_date)?.format(
                                                                    "DD/MM/YYYY"
                                                                )
                                                                : ""}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.completed_date
                                                                ? moment(el?.completed_date)?.format(
                                                                    "DD/MM/YYYY"
                                                                )
                                                                : ""}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.completed ? "Yes" : "No"}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    </>
                                ) : null}
                                {data?.data?.project_settings?.variations_and_claims == true ? (
                                    <>
                                        <p className="online__content-boldTitle">
                                            7. {translations?.['project_monitoring.variations_and_claims.headers.variations_and_claims']}
                                        </p>
                                        <p className="online__content-commonTitle">
                                            &nbsp; 7.1. {translations?.['project_monitoring.variations_and_claims.headers.total_changes']}
                                        </p>
                                        <div className="online__content-notFullTable">
                                            <div className="online__content-projectStatusRow">
                                                {translations?.['project_monitoring.variations_and_claims.titles.total_approved_variations']}
                                                <span>
                          ${" "}
                                                    {
                                                        data?.data?.variations_and_claims
                                                            ?.total_approved_variations_cost
                                                    }
                        </span>
                                            </div>
                                            <div className="online__content-projectStatusRow">
                                                {translations?.['project_monitoring.variations_and_claims.titles.total_variations_pending']}
                                                <span>
                          ${" "}
                                                    {
                                                        data?.data?.variations_and_claims
                                                            ?.total_variations_pending_approval_cost
                                                    }
                        </span>
                                            </div>
                                            <div className="online__content-projectStatusRow">
                                                {translations?.['project_monitoring.variations_and_claims.titles.total_certified_claims']}
                                                <span>
                          ${" "}
                                                    {
                                                        data?.data?.variations_and_claims
                                                            ?.total_claims_certified_amount
                                                    }
                        </span>
                                            </div>
                                            <div className="online__content-projectStatusRow">
                                                {translations?.['project_monitoring.variations_and_claims.titles.total_claims_pending']}
                                                <span>
                          ${" "}
                                                    {
                                                        data?.data?.variations_and_claims
                                                            ?.total_claims_pending_certification_amount
                                                    }
                        </span>
                                            </div>
                                            <div className="online__content-projectStatusRow">
                                                {translations?.['project_monitoring.variations_and_claims.titles.total_approved_contract_changes']}
                                                <span>
                          ${" "}
                                                    {
                                                        data?.data?.variations_and_claims
                                                            ?.total_approved_contract_cost_changes
                                                    }
                        </span>
                                            </div>
                                            <div className="online__content-projectStatusRow">
                                                {translations?.['project_monitoring.variations_and_claims.titles.total_pending_amounts']}
                                                <span>
                          ${" "}
                                                    {
                                                        data?.data?.variations_and_claims
                                                            ?.total_pending_contract_cost_changes
                                                    }
                        </span>
                                            </div>
                                            <div className="online__content-projectStatusRow">
                                                {translations?.['project_monitoring.variations_and_claims.titles.prior_contract_amount']}
                                                <span>
                          ${" "}
                                                    {
                                                        data?.data?.variations_and_claims
                                                            ?.prior_contract_price
                                                    }
                        </span>
                                            </div>
                                            <div className="online__content-projectStatusRow">
                                                {translations?.['project_monitoring.variations_and_claims.titles.revised_contract_amount']}
                                                <span>
                          ${" "}
                                                    {
                                                        data?.data?.variations_and_claims
                                                            ?.approved_revised_contract_cost
                                                    }
                        </span>
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                                {data?.data?.project_settings?.variation_register == true ? (
                                    <>
                                        <p className="online__content-commonTitle">
                                            &nbsp; 7.2. {translations?.['project_monitoring.variation_register.headers.variation_register']}
                                        </p>
                                        <table>
                                            <thead className="dashboard__thead">
                                            <tr className="dashboard__tr">
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.variation_register.table_headers.variation_ref']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.variation_register.table_headers.variation_description']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.variation_register.table_headers.date_instruction_issued']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.variation_register.table_headers.estimated_variation_cost']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.variation_register.table_headers.approved_variation_cost']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.variation_register.table_headers.current_status']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.variation_register.table_headers.date_variation_approved']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.variation_register.table_headers.current_status']}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="dashboard__tbody">
                                            {filterNonArchivedItems(data?.data?.variation_register)?.map((el, idx) => {
                                                return (
                                                    <tr className="dashboard__tr" key={idx}>
                                                        <td className="py-4 px-6">
                                                            {el?.variation_reference}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.variation_description}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.date_issued
                                                                ? moment(el?.date_issued)?.format(
                                                                    "DD/MM/YYYY"
                                                                )
                                                                : ""}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.estimated_variance_cost
                                                                ? "$" +
                                                                String(el?.estimated_variance_cost).replace(
                                                                    /(\d)(?=(\d{3})+([^\d]|$))/g,
                                                                    "$1,"
                                                                )
                                                                : ""}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.approved_variance_cost
                                                                ? "$" +
                                                                String(el?.approved_variance_cost).replace(
                                                                    /(\d)(?=(\d{3})+([^\d]|$))/g,
                                                                    "$1,"
                                                                )
                                                                : ""}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.variation_approved ? 'Yes' : 'No'}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.variation_approved ? el?.date_variation_approved
                                                                ? moment(el?.date_variation_approved)?.format(
                                                                    "DD/MM/YYYY"
                                                                )
                                                                : "" : ''}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.current_status}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    </>
                                ) : null}
                                {data?.data?.project_settings?.claim_register == true ? (
                                    <>
                                        <p className="online__content-commonTitle">
                                            &nbsp; 7.3. {translations?.['project_monitoring.claim_register.headers.claim_register']}
                                        </p>
                                        <table>
                                            <thead className="dashboard__thead">
                                            <tr className="dashboard__tr">
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.claim_register.table_headers.claim_ref']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.claim_register.table_headers.claim_description']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.claim_register.table_headers.submission_of_notice_of_claim']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.claim_register.table_headers.submission_of_fully_detailed_claim']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.claim_register.table_headers.claimed_amount']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.claim_register.table_headers.claim_certified']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.claim_register.table_headers.date_claim_certified']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.claim_register.table_headers.amount_certified']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.claim_register.table_headers.current_status']}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="dashboard__tbody">
                                            {filterNonArchivedItems(data?.data?.claim_register)?.map((el, idx) => {
                                                return (
                                                    <tr className="dashboard__tr" key={idx}>
                                                        <td className="py-4 px-6">
                                                            {el?.claim_reference}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.claim_description}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.notice_date
                                                                ? moment(el?.notice_date)?.format(
                                                                    "DD/MM/YYYY"
                                                                )
                                                                : ""}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.detailed_date
                                                                ? moment(el?.detailed_date)?.format(
                                                                    "DD/MM/YYYY"
                                                                )
                                                                : ""}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.submitted_amount
                                                                ? "$" +
                                                                String(el?.submitted_amount).replace(
                                                                    /(\d)(?=(\d{3})+([^\d]|$))/g,
                                                                    "$1,"
                                                                )
                                                                : ""}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.certified ? 'Yes' : 'No'}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.certified ? el?.date_claim_certified
                                                                ? moment(el?.date_claim_certified)?.format(
                                                                    "DD/MM/YYYY"
                                                                )
                                                                : "" : ''}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.certified ? el?.certified_amount
                                                                ? "$" +
                                                                String(el?.certified_amount).replace(
                                                                    /(\d)(?=(\d{3})+([^\d]|$))/g,
                                                                    "$1,"
                                                                )
                                                                : "" : ''}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.current_status}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    </>
                                ) : null}
                                {data?.data?.project_settings?.extension_of_time == true ? (
                                    <>
                                        <p className="online__content-commonTitle">
                                            &nbsp; 7.4. {translations?.['project_monitoring.extension_of_time.headers.extension_of_time']}
                                        </p>
                                        <table>
                                            <thead className="dashboard__thead">
                                            <tr className="dashboard__tr">
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.extension_of_time.table_headers.ref']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.extension_of_time.table_headers.eot_description']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.extension_of_time.table_headers.submission_of_notice_of_eot']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.extension_of_time.table_headers.number_of_days_claimed']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.extension_of_time.table_headers.eot_approval_date']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.extension_of_time.table_headers.number_of_approved_days']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.extension_of_time.table_headers.approved']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.extension_of_time.table_headers.current_status_or_comments']}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="dashboard__tbody">
                                            {filterNonArchivedItems(data?.data?.extension_of_time?.data)?.map((el, idx) => {
                                                return (
                                                    <tr className="dashboard__tr" key={idx}>
                                                        <td className="py-4 px-6">
                                                            {el?.ref}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.description}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.submission_of_notice
                                                                ? moment(el?.submission_of_notice)?.format(
                                                                    "DD/MM/YYYY"
                                                                )
                                                                : ""}
                                                        </td>
                                                        <td className="py-4 px-6" style={{paddingLeft: Number(el?.number_of_days_claimed) < 0 ? '1rem' : '1.5rem'}}>
                                                            {el?.number_of_days_claimed}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.approved ? el?.approval_date
                                                                ? moment(el?.approval_date)?.format(
                                                                    "DD/MM/YYYY"
                                                                )
                                                                : "" : ''}
                                                        </td>
                                                        <td className="py-4 px-6" style={{paddingLeft: Number(el?.number_of_approved_days) < 0 ? '1rem' : '1.5rem'}}>
                                                            {el?.number_of_approved_days}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.approved ? 'Approved' : 'Not Approved'}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.comments}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    </>
                                ) : null}
                            </div>
                            <div ref={refFinancialManagement}>
                                {data?.data?.project_settings?.payments_overview == true ? (
                                    <>
                                        <p className="online__content-boldTitle">
                                            8. {translations?.['payments.payments.headers.payments']}
                                        </p>
                                        <p className="online__content-text">
                                            {data?.data?.financial_management_overview}
                                        </p>
                                        <p className="online__content-commonTitle">
                                            &nbsp; 8.1. {translations?.['payments.actual_project_payments.headers.actual_project_payments']}
                                        </p>
                                        <table>
                                            <thead className="dashboard__thead">
                                            <tr className="dashboard__tr">
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['payments.actual_project_payments.table_headers.payment_description']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['payments.actual_project_payments.table_headers.invoice_date']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['payments.actual_project_payments.table_headers.invoice_amount']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['payments.actual_project_payments.table_headers.payment_date']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['payments.actual_project_payments.table_headers.payment_amount']}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="dashboard__tbody">
                                            {data?.data?.actual_project_payments?.payments?.map(
                                                (el, idx) => {
                                                    return (
                                                        <tr className="dashboard__tr" key={idx}>
                                                            <td className="py-4 px-6">{el?.description}</td>
                                                            <td className="py-4 px-6">
                                                                {el?.invoice_date
                                                                    ? moment(el?.invoice_date)?.format("DD/MM/YYYY")
                                                                    : ""}
                                                            </td>
                                                            <td className="py-4 px-6">
                                                                $
                                                                {el?.invoice_amount
                                                                    ?.toString()
                                                                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                            </td>
                                                            <td className="py-4 px-6">
                                                                {el?.payment_date
                                                                    ? moment(el?.payment_date)?.format("DD/MM/YYYY")
                                                                    : ""}
                                                            </td>
                                                            <td className="py-4 px-6">
                                                                $
                                                                {el?.payment_amount
                                                                    ?.toString()
                                                                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                            </tbody>
                                        </table>
                                        <p className="online__content-commonTitle">
                                            &nbsp; 8.2. {translations?.['payments.planned_project_payments.headers.planned_project_payments']}
                                        </p>
                                        {data?.data?.latest_report?.id ? (
                                            <VersusChart
                                                translations={translations}
                                                reportId={data?.data?.latest_report?.id}
                                                id={data?.data?.id}
                                                isPage={true}
                                            />
                                        ) : null}
                                    </>
                                ) : null}
                                {data?.data?.project_settings?.subcontractor_payments ==
                                true ? (
                                    <>
                                        <p className="online__content-commonTitle">
                                            &nbsp; 8.3. {translations?.['payments.subcontractor_payments.headers.subcontractor_payments']}
                                        </p>
                                        <table>
                                            <thead className="dashboard__thead">
                                            <tr className="dashboard__tr">
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['payments.subcontractor_payments.table_headers.subcontractor_name']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['payments.subcontractor_payments.table_headers.role']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['payments.subcontractor_payments.table_headers.percent_of_work_completed']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['payments.subcontractor_payments.table_headers.percent_of_contract_invoiced']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['payments.subcontractor_payments.table_headers.percent_of_contract_paid']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['payments.subcontractor_payments.table_headers.issues_or_disputes']}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="dashboard__tbody">
                                            {filterNonArchivedItems(data?.data?.subcontractor_payments)?.map((el, idx) => {
                                                return (
                                                    <tr className="dashboard__tr" key={idx}>
                                                        <td className="py-4 px-6">
                                                            {el?.subcontractor_name}
                                                        </td>
                                                        <td className="py-4 px-6">{el?.role}</td>
                                                        <td className="py-4 px-6">{el?.percent_work}%</td>
                                                        <td className="py-4 px-6">{el?.invoiced}%</td>
                                                        <td className="py-4 px-6">{el?.paid}%</td>
                                                        <td className="py-4 px-6">{el?.issues}</td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    </>
                                ) : null}
                            </div>
                            {data?.data?.project_settings?.disputes == true ? (
                                <div ref={refDisputes}>
                                    <p className="online__content-boldTitle">9. {translations?.['disputes.disputes.headers.disputes']}</p>
                                    <p className="online__content-text">
                                        {data?.data?.disputes_overview}
                                    </p>
                                    <table>
                                        <thead className="dashboard__thead">
                                        <tr className="dashboard__tr">
                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                {translations?.['disputes.disputes.table_headers.dispute_description']}
                                            </th>
                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                {translations?.['disputes.disputes.table_headers.current_status']}
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody className="dashboard__tbody">
                                        {data?.data?.disputes?.map((el, idx) => {
                                            return (
                                                <tr className="dashboard__tr" key={idx}>
                                                    <td className="py-4 px-6">{el?.description}</td>
                                                    <td className="py-4 px-6">{el?.status}</td>
                                                </tr>
                                            );
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            ) : null}
                            {data?.data?.project_settings?.goods_equipment == true ? (
                                <div ref={refGoodsEquipment}>
                                    <p className="online__content-boldTitle">
                                        10. {translations?.['goods_equipment.goods_equipment.headers.goods_equipment']}
                                    </p>
                                    <p className="online__content-commonTitle">
                                        &nbsp; 10.1. {translations?.['goods_equipment.goods_equipment_packages_status.headers.goods_equipment_packages_status']}
                                    </p>
                                    <table>
                                        <thead className="dashboard__thead"></thead>
                                        <tbody className="dashboard__tbody">
                                        {filterNonArchivedItems(data?.data?.goods_equipment)?.map((el, idx) => {
                                            return (
                                                <div style={{ marginBottom: 30 }} key={idx}>
                                                    <tr className="dashboard__tr">
                                                        <th
                                                            scope="col"
                                                            className="py-3 px-6 dashboard__th x-left-y-center"
                                                        >
                                                            {translations?.['goods_equipment.goods_equipment_packages_status.table_headers.goods_equipment_description']}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="py-3 px-6 dashboard__th x-left-y-center"
                                                        >
                                                            {translations?.['goods_equipment.goods_equipment_packages_status.table_headers.fat_status']}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="py-3 px-6 dashboard__th x-left-y-center"
                                                        >
                                                            {translations?.['goods_equipment.goods_equipment_packages_status.table_headers.destinations']}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="py-3 px-6 dashboard__th x-left-y-center"
                                                        >
                                                            {translations?.['goods_equipment.goods_equipment_packages_status.table_headers.customs_clearance_status']}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="py-3 px-6 dashboard__th x-left-y-center"
                                                        >
                                                            {translations?.['goods_equipment.goods_equipment_packages_status.table_headers.percent_invoiced']}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="py-3 px-6 dashboard__th x-left-y-center"
                                                        >
                                                            {translations?.['goods_equipment.goods_equipment_packages_status.table_headers.percent_paid']}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="py-3 px-6 dashboard__th x-left-y-center"
                                                        >
                                                            {translations?.['goods_equipment.goods_equipment_packages_status.table_headers.delivery_complete']}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="py-3 px-6 dashboard__th x-left-y-center"
                                                        >
                                                            {translations?.['goods_equipment.goods_equipment_packages_status.table_headers.current_status_or_comments']}
                                                        </th>
                                                    </tr>
                                                    <tr className="dashboard__tr">
                                                        <td className="py-4 px-6 s-center">
                                                            {el?.description}
                                                        </td>
                                                        <td className="py-4 px-6 s-center">
                                                            {el?.fat_status}
                                                        </td>
                                                        <td className="py-4 px-6 s-center">
                                                            {el?.destination}
                                                        </td>
                                                        <td className="py-4 px-6 s-center">
                                                            {el?.clearance_status}
                                                        </td>
                                                        <td className="py-4 px-6 s-center">
                                                            {el?.invoiced}%
                                                        </td>
                                                        <td className="py-4 px-6 s-center">
                                                            {el?.paid}%
                                                        </td>
                                                        <td className="py-4 px-6 s-center">
                                                            {el?.delivery_complete ? "Yes" : "No"}
                                                        </td>
                                                        <td className="py-4 px-6 s-center">
                                                            {el?.comments}
                                                        </td>
                                                    </tr>
                                                    <tr className="dashboard__tr">
                                                        <th
                                                            scope="col"
                                                            className="py-3 px-6 dashboard__th"
                                                        >
                                                            {translations?.['goods_equipment.goods_equipment_packages_status.table_headers.planned_shipment_date']}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="py-3 px-6 dashboard__th"
                                                        >
                                                            {translations?.['goods_equipment.goods_equipment_packages_status.table_headers.actual_shipment_date']}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="py-3 px-6 dashboard__th"
                                                        >
                                                            {translations?.['goods_equipment.goods_equipment_packages_status.table_headers.planned_arrival_date']}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="py-3 px-6 dashboard__th"
                                                        >
                                                            {translations?.['goods_equipment.goods_equipment_packages_status.table_headers.actual_arrival_date']}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="py-3 px-6 dashboard__th"
                                                        >
                                                            {translations?.['goods_equipment.goods_equipment_packages_status.table_headers.warehouse_planned_arrival_date']}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="py-3 px-6 dashboard__th"
                                                        >
                                                            {translations?.['goods_equipment.goods_equipment_packages_status.table_headers.warehouse_actual_arrival_date']}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="py-3 px-6 dashboard__th"
                                                        >
                                                            {translations?.['goods_equipment.goods_equipment_packages_status.table_headers.site_planned_delivery_date']}
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="py-3 px-6 dashboard__th"
                                                        >
                                                            {translations?.['goods_equipment.goods_equipment.titles.site_actual_delivery_date']}
                                                        </th>
                                                    </tr>
                                                    <tr className="dashboard__tr">
                                                        <td className="py-4 px-6 s-center">
                                                            {el?.planned_shipped_date
                                                                ? moment(el?.planned_shipped_date).format(
                                                                    "DD/MM/YYYY"
                                                                )
                                                                : ""}
                                                        </td>
                                                        <td className="py-4 px-6 s-center">
                                                            {el?.actual_shipped_date
                                                                ? moment(el?.actual_shipped_date).format(
                                                                    "DD/MM/YYYY"
                                                                )
                                                                : ""}
                                                        </td>
                                                        <td className="py-4 px-6 s-center">
                                                            {el?.planned_arrival_date
                                                                ? moment(el?.planned_arrival_date).format(
                                                                    "DD/MM/YYYY"
                                                                )
                                                                : ""}
                                                        </td>
                                                        <td className="py-4 px-6 s-center">
                                                            {el?.actual_arrival_date
                                                                ? moment(el?.actual_arrival_date).format(
                                                                    "DD/MM/YYYY"
                                                                )
                                                                : ""}
                                                        </td>
                                                        <td className="py-4 px-6 s-center">
                                                            {el?.warehouse_planned_date
                                                                ? moment(el?.warehouse_planned_date).format(
                                                                    "DD/MM/YYYY"
                                                                )
                                                                : ""}
                                                        </td>
                                                        <td className="py-4 px-6 s-center">
                                                            {el?.warehouse_actual_date
                                                                ? moment(el?.warehouse_actual_date).format(
                                                                    "DD/MM/YYYY"
                                                                )
                                                                : ""}
                                                        </td>
                                                        <td className="py-4 px-6 s-center">
                                                            {el?.planned_delivery_date
                                                                ? moment(el?.planned_delivery_date).format(
                                                                    "DD/MM/YYYY"
                                                                )
                                                                : ""}
                                                        </td>
                                                        <td className="py-4 px-6 s-center">
                                                            {el?.actual_delivery_date
                                                                ? moment(el?.actual_delivery_date).format(
                                                                    "DD/MM/YYYY"
                                                                )
                                                                : ""}
                                                        </td>
                                                    </tr>
                                                </div>
                                            );
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            ) : null}
                            {data?.data?.project_settings?.engineering ==
                            true ? (
                                <div ref={refEngineering}>
                                    <p className="online__content-boldTitle">
                                        11. {translations?.['engineering_design_documents.engineering_design_documents.headers.engineering_design_documents']}
                                    </p>
                                    <p className="online__content-commonTitle">
                                        &nbsp; 11.1. {translations?.['engineering_design_documents.engineering.headers.engineering']}
                                    </p>
                                    <table>
                                        <thead className="dashboard__thead">
                                        <tr className="dashboard__tr">
                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                {translations?.['engineering_design_documents.engineering.table_headers.design_deliverable']}
                                            </th>
                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                {translations?.['engineering_design_documents.engineering.table_headers.planned_approval_date']}
                                            </th>
                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                {translations?.['engineering_design_documents.engineering.table_headers.actual_approval_date']}
                                            </th>
                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                {translations?.['engineering_design_documents.engineering.table_headers.approval_entity']}
                                            </th>
                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                {translations?.['engineering_design_documents.engineering.table_headers.approval_stage']}
                                            </th>
                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                {translations?.['engineering_design_documents.engineering.table_headers.current_status_or_comments']}
                                            </th>
                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                {translations?.['engineering_design_documents.engineering.table_headers.completed']}
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody className="dashboard__tbody">
                                        {data?.data?.engineering?.map(
                                            (el, idx) => {
                                                return (
                                                    <tr className="dashboard__tr" key={idx}>
                                                        <td className="py-4 px-6">{el?.design_deliverable}</td>
                                                        <td className="py-4 px-6">
                                                            {el?.planned_approval_date}
                                                        </td>
                                                        <td className="py-4 px-6">{el?.planned_approval_date}</td>
                                                        <td className="py-4 px-6">
                                                            {el?.approval_entity}
                                                        </td>
                                                        <td className="py-4 px-6">{el?.approval_stage}</td>
                                                        <td className="py-4 px-6">{el?.comment}</td>
                                                        <td className="py-4 px-6">{el?.completed === 1 ? "Completed" : "Not completed"}</td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            ) : null}
                            <div ref={refEnvironment}>
                                {data?.data?.project_settings?.gender_tracking == true ? (
                                    <>
                                        <p className="online__content-boldTitle">
                                            12. {translations?.['environment_social_health_safety_and_security_obligations.environment_social_health_safety_and_security_obligations.headers.environment_social_health_safety_and_security_obligations']}
                                        </p>
                                        <p className="online__content-commonTitle">
                                            &nbsp; 12.1. {translations?.['environment_social_health_safety_and_security_obligations.gender_tracking.headers.gender_tracking']}
                                        </p>
                                        <div className="online__content-notFullTable">
                                            <div className="online__content-projectStatusRow">
                                                {translations?.['environment_social_health_safety_and_security_obligations.gender_tracking.titles.number_of_females_employed_on_the_project']}
                                                <span>
                          {
                              data?.data?.environment?.gender_tracking_monitoring
                                  ?.number_females
                          }
                        </span>
                                            </div>
                                            <div className="online__content-projectStatusRow">
                                                {translations?.['environment_social_health_safety_and_security_obligations.gender_tracking.titles.total_number_of_staff_employed_on_the_project']}
                                                <span>
                          {
                              data?.data?.environment?.gender_tracking_monitoring
                                  ?.total_number
                          }
                        </span>
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                                {data?.data?.project_settings?.ssemp_activity == true ? (
                                    <>
                                        <p className="online__content-commonTitle">
                                            &nbsp; 12.2. {translations?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.headers.ssemp_activity_monitoring']}
                                        </p>
                                        <table>
                                            <thead className="dashboard__thead">
                                            <tr className="dashboard__tr">
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.table_headers.description_of_ssemp_activities']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.table_headers.corresponding_submittal']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.table_headers.planned_date']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.table_headers.actual_date']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.table_headers.current_status_or_comments']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.table_headers.completed']}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="dashboard__tbody">
                                            {data?.data?.environment?.ssemp_activity_monitoring?.map(
                                                (el, idx) => {
                                                    return (
                                                        <tr className="dashboard__tr" key={idx}>
                                                            <td className="py-4 px-6">{el?.description}</td>
                                                            <td className="py-4 px-6">
                                                                {el?.corresponding_submittal}
                                                            </td>
                                                            <td className="py-4 px-6">
                                                                {el?.planned_date
                                                                    ? moment(el?.planned_date)?.format(
                                                                        "DD/MM/YYYY"
                                                                    )
                                                                    : ""}
                                                            </td>
                                                            <td className="py-4 px-6">
                                                                {el?.actual_date
                                                                    ? moment(el?.actual_date)?.format(
                                                                        "DD/MM/YYYY"
                                                                    )
                                                                    : ""}
                                                            </td>
                                                            <td className="py-4 px-6">{el?.comment}</td>
                                                            <td className="py-4 px-6">
                                                                {el?.completed ? translations?.['environment_social_health_safety_and_security_obligations.ssemp_activity_monitoring.titles.completed'] : ''}
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                            </tbody>
                                        </table>
                                    </>
                                ) : null}
                                {data?.data?.project_settings?.social_safeguard == true ? (
                                    <>
                                        <p className="online__content-commonTitle">
                                            &nbsp; 12.3. {translations?.['environment_social_health_safety_and_security_obligations.social_safeguard_monitoring.headers.social_safeguard_monitoring']}
                                        </p>
                                        <table>
                                            <thead className="dashboard__thead">
                                            <tr className="dashboard__tr">
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.social_safeguard_monitoring.table_headers.social_safeguard_monitoring_description']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.social_safeguard_monitoring.table_headers.corresponding_submittal']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.social_safeguard_monitoring.table_headers.planned_date']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.social_safeguard_monitoring.table_headers.actual_date']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.social_safeguard_monitoring.table_headers.current_status_or_comments']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.social_safeguard_monitoring.table_headers.completed']}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="dashboard__tbody">
                                            {data?.data?.environment?.social_safeguard_monitoring?.map(
                                                (el, idx) => {
                                                    return (
                                                        <tr className="dashboard__tr" key={idx}>
                                                            <td className="py-4 px-6">{el?.description}</td>
                                                            <td className="py-4 px-6">
                                                                {el?.corresponding_submittal}
                                                            </td>
                                                            <td className="py-4 px-6">
                                                                {el?.planned_date
                                                                    ? moment(el?.planned_date)?.format(
                                                                        "DD/MM/YYYY"
                                                                    )
                                                                    : ""}
                                                            </td>
                                                            <td className="py-4 px-6">
                                                                {el?.actual_date
                                                                    ? moment(el?.actual_date)?.format(
                                                                        "DD/MM/YYYY"
                                                                    )
                                                                    : ""}
                                                            </td>
                                                            <td className="py-4 px-6">{el?.comment}</td>
                                                            <td className="py-4 px-6">
                                                                {el?.completed ? translations?.['translations?.[\'environment_social_health_safety_and_security_obligations.social_safeguard_monitoring.titles.completed'] : ''}
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                            </tbody>
                                        </table>
                                    </>
                                ) : null}
                                {data?.data?.project_settings?.larp == true ? (
                                    <>
                                        <div className="dpFlex">
                                            <p className="online__content-commonTitle">
                                                &nbsp; 12.4. {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.headers.larp_land_acquisition_resettlement_plan']}
                                            </p>
                                            {data?.data?.larp?.top_level?.detailed ? <DownloadCSV
                                                btnTitle={translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.actions.download_to_csv']}
                                                url={downloadCSVUrl}
                                                fileName="affected_persons"
                                                tooltipTitle={translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.tooltips.download_affected_households_data']}
                                            /> : ''}
                                        </div>
                                        <table>
                                            <thead className="dashboard__thead">
                                            <tr className="dashboard__tr">
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.final_route_and_sections_submitted_to_employer']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.date_submitted']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.final_route_and_sections_approved']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.date_approved']}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="dashboard__tbody">
                                            <tr className="dashboard__tr">
                                                <td className="py-4 px-6">
                                                    {data?.data?.larp?.top_level?.sections_submitted
                                                        ? "Yes"
                                                        : "No"}
                                                </td>
                                                <td className="py-4 px-6">
                                                    {data?.data?.larp?.top_level?.date_submitted
                                                        ? moment(
                                                            data?.data?.larp?.top_level?.date_submitted
                                                        )?.format("DD/MM/YYYY")
                                                        : ""}
                                                </td>
                                                <td className="py-4 px-6">
                                                    {data?.data?.larp?.top_level?.sections_approved
                                                        ? "Yes"
                                                        : "No"}
                                                </td>
                                                <td className="py-4 px-6">
                                                    {data?.data?.larp?.top_level?.date_approved
                                                        ? moment(
                                                            data?.data?.larp?.top_level?.date_approved
                                                        )?.format("DD/MM/YYYY")
                                                        : ""}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        {data?.data?.larp?.sections?.map((section, idx) => {
                                            return (
                                                <div className="online__larp" key={idx}>
                                                    <table>
                                                        <thead className="dashboard__thead">
                                                        <tr className="dashboard__tr">
                                                            <th
                                                                scope="col"
                                                                className="py-3 px-6 th__align dashboard__th"
                                                            >
                                                                {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.section_ref']}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-3 px-6 th__align dashboard__th"
                                                            >
                                                                {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.section_description']}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-3 px-6 th__align dashboard__th"
                                                            >
                                                                {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.number_of_household_affected']}
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody className="dashboard__tbody">
                                                        <tr className="dashboard__tr">
                                                            <td className="py-4 px-6">
                                                                {section?.section_ref}
                                                            </td>
                                                            <td className="py-4 px-6">
                                                                {section?.section_description}
                                                            </td>
                                                            <td className="py-4 px-6">
                                                                {section?.number_of_households_affected}
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    {section?.tiers?.map((tier, idx) => {
                                                        return (
                                                            <div key={idx}>
                                                                <table>
                                                                    <thead className="dashboard__thead">
                                                                    <tr className="dashboard__tr">
                                                                        <th
                                                                            scope="col"
                                                                            className="py-3 px-6 th__align dashboard__th"
                                                                        >
                                                                            {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.tier_ref']}
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className="py-3 px-6 th__align dashboard__th"
                                                                        >
                                                                            {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.notice_to_proceed']}
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className="py-3 px-6 th__align dashboard__th"
                                                                        >
                                                                            {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.date_of_notice']}
                                                                        </th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody className="dashboard__tbody">
                                                                    <tr className="dashboard__tr">
                                                                        <td className="py-4 px-6">
                                                                            {tier?.stage_ref}
                                                                        </td>
                                                                        <td className="py-4 px-6">
                                                                            {tier?.notice_to_proceed ? "Yes" : "No"}
                                                                        </td>
                                                                        <td className="py-4 px-6">
                                                                            {tier?.date_of_notice
                                                                                ? moment(tier?.date_of_notice)?.format(
                                                                                    "DD/MM/YYYY"
                                                                                )
                                                                                : ""}
                                                                        </td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                                <div className="s-online__content-grid-table">
                                                                    <div className="online__bold">
                                                                        {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.updated_larp_submitted_to_adb']}
                                                                    </div>
                                                                    <div className="online__bold">
                                                                        {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.larp_date_submitted']}
                                                                    </div>
                                                                    <div className="online__bold">
                                                                        {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.larp_approved']}
                                                                    </div>
                                                                    <div className="online__bold">
                                                                        {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.larp_date_approved']}
                                                                    </div>
                                                                    <div className="online__bold">
                                                                        {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.verification_reports_submitted_to_adb']}
                                                                    </div>
                                                                    <div className="online__bold">
                                                                        {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.verification_report_date_submitted']}
                                                                    </div>
                                                                    <span>
                                  {tier?.details?.larp_submitted ? "Yes" : "No"}
                                </span>
                                                                    <span>
                                  {tier?.details?.larp_date_submitted
                                      ? moment(
                                          tier?.details?.larp_date_submitted
                                      )?.format("DD/MM/YYYY")
                                      : ""}
                                </span>
                                                                    <span>
                                  {tier?.details?.larp_approved ? "Yes" : "No"}
                                </span>
                                                                    <span>
                                  {tier?.details?.larp_date_approved
                                      ? moment(
                                          tier?.details?.larp_date_approved
                                      )?.format("DD/MM/YYYY")
                                      : ""}
                                </span>
                                                                    <span>
                                  {tier?.details?.report_submitted
                                      ? "Yes"
                                      : "No"}
                                </span>
                                                                    <span>
                                  {tier?.details?.report_date_submitted
                                      ? moment(
                                          tier?.details?.report_date_submitted
                                      )?.format("DD/MM/YYYY")
                                      : ""}
                                </span>
                                                                </div>
                                                                <div className="s-online__content-grid-table">
                                <span className="online__bold">
                                  {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.list_of_affected_households_and_compensation_vouchers_received']}
                                </span>
                                                                    <span className="online__bold">
                                  {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.total_amount']}
                                </span>
                                                                    <span className="online__bold">
                                  {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.payments_completed']}
                                </span>
                                                                    <span className="online__bold">
                                  {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.payments_completed_date']}
                                </span>
                                                                    <span className="online__bold">
                                  {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.verification_report_approved']}
                                </span>

                                                                    <span className="online__bold">
                                  {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.verification_report_date_approved']}
                                </span>
                                                                    <span>
                                  {tier?.details?.vouchers_received
                                      ? "Yes"
                                      : "No"}
                                </span>
                                                                    <span>
                                  {tier?.details?.total_amount
                                      ? "$" +
                                      String(
                                          tier?.details?.total_amount
                                      ).replace(
                                          /(\d)(?=(\d{3})+([^\d]|$))/g,
                                          "$1,"
                                      )
                                      : ""}
                                </span>
                                                                    <span>
                                  {tier?.details?.payments_completed
                                      ? "Yes"
                                      : "No"}
                                </span>
                                                                    <span>
                                  {tier?.details?.payments_date_completed
                                      ? moment(
                                          tier?.details?.payments_date_completed
                                      )?.format("DD/MM/YYYY")
                                      : ""}
                                </span>
                                                                    <span>
                                  {tier?.details?.report_approved
                                      ? "Yes"
                                      : "No"}
                                </span>
                                                                    <span>
                                  {tier?.details?.report_date_approved
                                      ? moment(
                                          tier?.details?.report_date_approved
                                      )?.format("DD/MM/YYYY")
                                      : ""}
                                </span>
                                                                </div>
                                                                {tier?.affected_persons?.map((person, idx) => {
                                                                    let table = <>
                                                                        <table key={idx}>
                                                                            <thead className="dashboard__thead">
                                                                            <tr className="dashboard__tr">
                                                                                <th
                                                                                    scope="col"
                                                                                    className="py-3 px-6 dashboard__th"
                                                                                >
                                                                                    {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.affected_household_name']}
                                                                                </th>
                                                                                <th
                                                                                    scope="col"
                                                                                    className="py-3 px-6 dashboard__th"
                                                                                >
                                                                                    {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.amount']}
                                                                                </th>
                                                                                <th
                                                                                    scope="col"
                                                                                    className="py-3 px-6 dashboard__th"
                                                                                >
                                                                                    {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.payment_status']}
                                                                                </th>
                                                                                <th
                                                                                    scope="col"
                                                                                    className="py-3 px-6 dashboard__th"
                                                                                >
                                                                                    {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.paid_date']}
                                                                                </th>
                                                                                <th
                                                                                    scope="col"
                                                                                    className="py-3 px-6 dashboard__th"
                                                                                >
                                                                                    {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.external_consultant_verified']}
                                                                                </th>
                                                                                <th
                                                                                    scope="col"
                                                                                    className="py-3 px-6 dashboard__th"
                                                                                >
                                                                                    {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.data_verified']}
                                                                                </th>
                                                                                <th
                                                                                    scope="col"
                                                                                    className="py-3 px-6 dashboard__th"
                                                                                >
                                                                                    {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.completed']}
                                                                                </th>
                                                                                <th
                                                                                    scope="col"
                                                                                    className="py-3 px-6 dashboard__th"
                                                                                >
                                                                                    {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.table_headers.current_status_residual_issues']}
                                                                                </th>
                                                                            </tr>
                                                                            </thead>
                                                                            <tbody className="dashboard__tbody">
                                                                            <tr className="dashboard__tr">
                                                                                <td className="py-4 px-6">
                                                                                    {person?.name}
                                                                                </td>
                                                                                <td className="py-4 px-6">
                                                                                    {person?.amount
                                                                                        ? "$" +
                                                                                        String(person?.amount).replace(
                                                                                            /(\d)(?=(\d{3})+([^\d]|$))/g,
                                                                                            "$1,"
                                                                                        )
                                                                                        : ""}
                                                                                </td>
                                                                                <td className="py-4 px-6">
                                                                                    {person?.paid.charAt(0).toUpperCase() + person?.paid.slice(1)}
                                                                                </td>
                                                                                <td className="py-4 px-6">
                                                                                    {person?.paid_date
                                                                                        ? moment(person?.paid_date)?.format(
                                                                                            "DD/MM/YYYY"
                                                                                        )
                                                                                        : ""}
                                                                                </td>
                                                                                <td className="py-4 px-6">
                                                                                    {person?.external_consultant_verified
                                                                                        ? "Yes"
                                                                                        : "No"}
                                                                                </td>
                                                                                <td className="py-4 px-6">
                                                                                    {person?.date_verified
                                                                                        ? moment(
                                                                                            person?.date_verified
                                                                                        )?.format("DD/MM/YYYY")
                                                                                        : ""}
                                                                                </td>
                                                                                <td className="py-4 px-6">
                                                                                    {person?.completed ? "Yes" : "No"}
                                                                                </td>
                                                                                <td className="py-4 px-6">
                                                                                    {person?.status}
                                                                                </td>
                                                                            </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </>;
                                                                    return table;
                                                                })}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            );
                                        })}
                                    </>
                                ) : null}
                                {data?.data?.project_settings?.larp == true ?
                                    <>
                                        <div className="larp-db__df larp-db__aj">
                                            <div className="larp-db__colors">
                                                <div className="larp-db__color paid">
                                                    <div></div>
                                                    <p>{translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.titles.paid']}</p>
                                                </div>
                                                <div className="larp-db__color escrowed">
                                                    <div></div>
                                                    <p>{translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.titles.escrowed']}</p>
                                                </div>
                                                <div className="larp-db__color unpaid">
                                                    <div></div>
                                                    <p>{translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.titles.unpaid']}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="larp-db__table">
                                            <div className="larp-db__row">
                                                <div className="larp-db__th" style={{height: 187}}>
                                                    {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.titles.foundation']}
                                                </div>
                                                <div className="larp-db__th"  style={{height: 187}}>
                                                    {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.titles.tower_erection']}
                                                </div>
                                                <div className="larp-db__th" style={{height: 187}}>
                                                    {translations?.['environment_social_health_safety_and_security_obligations.larp_land_acquisition_resettlement_plan.titles.line_stringing']}
                                                </div>
                                                <div className="larp-db__th end"></div>
                                            </div>
                                            <div className="larp-db__table-scroll">
                                                {allDataLarp?.map((item, index) => (
                                                    <div className="larp-db__row" key={index} style={{width: 120}}>
                                                        {item?.tiers?.map((elem, i) => (
                                                            <div className="larp-db__td" key={i} style={{padding: 0, paddingRight: 40, width: '120px', height: 187}}>
                                                                <div className="larp-db__td-radius" style={{height: 170}}>
                                                                    {elem?.unpaid !== '0%' && (
                                                                        <div className={`larp-db__chart full unpaid ${elem?.unpaid == '100%' ? 'gray' : ''}`} onClick={() => {filterAffectedPersons(elem, item, 'Unpaid')}} style={{height: elem?.height_unpaid, cursor: 'auto', ...borderRadius('unpaid', elem)}}>{elem?.unpaid}</div>
                                                                    )}
                                                                    {elem?.escrow !== '0%' && (
                                                                        <div className="larp-db__chart full escrowed" onClick={() => {filterAffectedPersons(elem, item, 'Escrow')}} style={{height: elem?.height_escrow, ...borderRadius('escrow', elem), cursor: 'auto'}}>
                                                                            {elem?.escrow}
                                                                            {elem?.escrow !== '100%' && elem?.total_paid !== 0 && (
                                                                                <div className="larp-db__top-arrow">
                                                                                    <img src="/assets/icons/larp-end-arrow.svg" alt="arrow" className='end' />
                                                                                    <span style={{height: heightPaidWithEscrow(elem?.height_paid, elem?.height_escrow)}}></span>
                                                                                    <img src="/assets/icons/larp-start-arrow.svg" alt="arrow" className='start' />
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                    {elem?.paid !== '0%' && (
                                                                        <div className="larp-db__chart full paid" onClick={() => {filterAffectedPersons(elem, item, 'Paid')}} style={{height: elem?.height_paid, ...borderRadius('paid', elem), cursor: 'auto'}}>
                                                                            {elem?.paid}
                                                                            {elem?.paid !== '100%' && elem?.total_escrow != 0 && (
                                                                                <div className="larp-db__top-arrow">
                                                                                    <img src="/assets/icons/larp-start-arrow.svg" alt="arrow" className='start' />
                                                                                    {/* <span style={{height: `calc(((${elem?.height_paid} + ${elem?.height_escrow}) / 2) - 22px - 7px)`}}></span> */}
                                                                                    <span style={{height: heightPaidWithEscrow(elem?.height_paid, elem?.height_escrow)}}></span>
                                                                                    <img src="/assets/icons/larp-end-arrow.svg" alt="arrow" className='end' />
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    )}


                                                                    {elem?.escrow === '0%' && elem?.paid === '0%' && elem?.unpaid === 0 && (
                                                                        <div className="larp-db__chart full gray" style={{height: '100%'}}>0%</div>
                                                                    )}
                                                                    {elem?.total_escrow != 0 && elem?.total_paid != 0 && (
                                                                        <div className="larp-db__chart-percent" style={{height: centerHeightPaidWithEscrow(elem?.height_paid, elem?.height_escrow)}}>
                                                                            <span>{elem?.total_percent}</span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <div className="larp-db__td end" style={{width: '100%', cursor: 'auto'}} onClick={() => {
                                                        }}>
                                                            <div className="larp-db__td-name">{ item?.section }</div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </>
                                    : null}
                                {data?.data?.project_settings?.health_and_safety_monitoring == true ? (
                                    <>
                                        <p className="online__content-commonTitle">
                                            &nbsp; 12.5. {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring.headers.health_and_safety_monitoring']}
                                        </p>
                                        <table>
                                            <thead className="dashboard__thead">
                                            <tr className="dashboard__tr">
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring.table_headers.health_and_safety_monitoring_description']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring.table_headers.corresponding_submittal']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring.table_headers.planned_date']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring.table_headers.actual_date']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring.table_headers.current_status_or_comments']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring.table_headers.completed']}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="dashboard__tbody">
                                            {filterNonArchivedItems(data?.data?.environment?.health_safety_monitoring)?.map(
                                                (el, idx) => {
                                                    return (
                                                        <tr className="dashboard__tr" key={idx}>
                                                            <td className="py-4 px-6">{el?.description}</td>
                                                            <td className="py-4 px-6">
                                                                {el?.corresponding_submittal}
                                                            </td>
                                                            <td className="py-4 px-6">
                                                                {el?.planned_date
                                                                    ? moment(el?.planned_date)?.format(
                                                                        "DD/MM/YYYY"
                                                                    )
                                                                    : ""}
                                                            </td>
                                                            <td className="py-4 px-6">
                                                                {el?.actual_date
                                                                    ? moment(el?.actual_date)?.format(
                                                                        "DD/MM/YYYY"
                                                                    )
                                                                    : ""}
                                                            </td>
                                                            <td className="py-4 px-6">{el?.comment}</td>
                                                            <td className="py-4 px-6">
                                                                {el?.completed ? translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring.titles.completed'] : ""}
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                            </tbody>
                                        </table>
                                    </>
                                ) : null}
                                {data?.data?.project_settings?.health_and_safety_statistic ==
                                true ? (
                                    <>
                                        <p className="online__content-commonTitle">
                                            &nbsp; 12.6. {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.headers.health_and_safety_statistics']}
                                        </p>
                                        <div className="online__content-notFullTable">
                                            <div className="online__content-projectStatusRow">
                                                {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.titles.average_daily_manpower']}
                                                <span>
                          {
                              data?.data?.environment?.health_safety_statistics
                                  ?.average_daily_manpower
                          }
                        </span>
                                            </div>
                                            <div className="online__content-projectStatusRow">
                                                {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.titles.lost_time_injury']}
                                                <span>
                          {
                              data?.data?.environment?.health_safety_statistics
                                  ?.lti
                          }
                        </span>
                                            </div>
                                            <div className="online__content-projectStatusRow">
                                                {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.titles.lost_time_injury_frequency_rate']}
                                                <span>
                          {
                              data?.data?.environment?.health_safety_statistics
                                  ?.ltifr
                          }
                        </span>
                                            </div>
                                            <div className="online__content-projectStatusRow">
                                                {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.titles.first_aid_injury']}
                                                <span>
                          {
                              data?.data?.environment?.health_safety_statistics
                                  ?.fai
                          }
                        </span>
                                            </div>
                                            <div className="online__content-projectStatusRow">
                                                {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.titles.medical_treatment_injury']}
                                                <span>
                          {
                              data?.data?.environment?.health_safety_statistics
                                  ?.mti
                          }
                        </span>
                                            </div>
                                            <div className="online__content-projectStatusRow">
                                                {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.titles.significant_near_misses']}
                                                <span>
                          {
                              data?.data?.environment?.health_safety_statistics
                                  ?.snm
                          }
                        </span>
                                            </div>
                                            <div className="online__content-projectStatusRow">
                                                {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_statistics.titles.total_recordable_injury_frequency_rate']}
                                                <span>
                          {
                              data?.data?.environment?.health_safety_statistics
                                  ?.trifr
                          }
                        </span>
                                            </div>
                                        </div>
                                        <span className="online__content-statisticsText">
                      {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring_statistics_definitions.titles.lti']}
                                            <br />
                      <br />
                                            {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring_statistics_definitions.titles.ltifr']}
                                            <br />
                      <br />
                                            {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring_statistics_definitions.titles.fai']}
                                            <br />
                      <br />
                                            {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring_statistics_definitions.titles.mti']}
                                            <br />
                      <br />
                                            {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring_statistics_definitions.titles.snm']}
                                            <br />
                      <br />
                                            {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring_statistics_definitions.titles.trifr']}
                    </span>
                                    </>
                                ) : null}
                                {data?.data?.project_settings?.complaints == true ? (
                                    <>
                                        <p className="online__content-commonTitle">
                                            &nbsp; 12.7. {translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.headers.complaints_grievances']}
                                        </p>
                                        <table>
                                            <thead className="dashboard__thead">
                                            <tr className="dashboard__tr">
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.table_headers.name_of_complainant']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.table_headers.description']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.table_headers.date_filed']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.table_headers.affected_persons']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.table_headers.responsible_party']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.table_headers.current_status_or_comments']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.table_headers.closed']}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="dashboard__tbody">
                                            {data?.data?.environment?.complaints?.map((el, idx) => {
                                                return (
                                                    <tr className="dashboard__tr" key={idx}>
                                                        <td className="py-4 px-6">{el?.name}</td>
                                                        <td className="py-4 px-6">{el?.description}</td>
                                                        <td className="py-4 px-6">
                                                            {el?.submitted_date
                                                                ? moment(el?.submitted_date)?.format(
                                                                    "DD/MM/YYYY"
                                                                )
                                                                : ""}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.affected_person}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.responsible_party}
                                                        </td>
                                                        <td className="py-4 px-6">{el?.comment}</td>
                                                        <td className="py-4 px-6">
                                                            {el?.closed === 0 ? "Open" : "Closed"}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    </>
                                ) : null}
                                {data?.data?.project_settings?.security_monitoring == true ? (
                                    <>
                                        <p className="online__content-commonTitle">
                                            &nbsp; 12.8. {translations?.['environment_social_health_safety_and_security_obligations.security_monitoring.headers.security_monitoring']}
                                        </p>
                                        <table>
                                            <thead className="dashboard__thead">
                                            <tr className="dashboard__tr">
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.security_monitoring.table_headers.security_situation_in_the_project_area_description']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.security_monitoring.table_headers.security_resources_as_planned_in_security_plan']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.security_monitoring.table_headers.security_resources_actual_on_site']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.security_monitoring.table_headers.security_incidents_reported_during_previous_month']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.security_monitoring.table_headers.security_comments']}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="dashboard__tbody">
                                            {data?.data?.environment?.security_monitoring?.map(
                                                (el, idx) => {
                                                    return (
                                                        <tr className="dashboard__tr" key={idx}>
                                                            <td className="py-4 px-6">
                                                                {el?.area_description}
                                                            </td>
                                                            <td className="py-4 px-6">
                                                                {el?.security_plan}
                                                            </td>
                                                            <td className="py-4 px-6">
                                                                {el?.actual_on_site}
                                                            </td>
                                                            <td className="py-4 px-6">
                                                                {el?.during_previous_month}
                                                            </td>
                                                            <td className="py-4 px-6">{el?.comments}</td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                            </tbody>
                                        </table>
                                    </>
                                ) : null}
                            </div>
                            {data?.data?.project_settings?.site_photographs == true ? (
                                <>
                                    <div
                                        ref={refSitePhotographs}
                                        className="online__content-sitePhotographs"
                                    >
                                        <p className="online__content-boldTitle">
                                            13. {translations?.['site_photographs.site_photographs.headers.site_photographs']}
                                        </p>
                                        {data?.data?.site_photographs?.map((el, idx) => {
                                            return (
                                                <div
                                                    className="online__content-sitePhotographs-box"
                                                    key={idx}
                                                >
                                                    <img src={el?.photo} alt="" />
                                                    <p>{el?.description}</p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </>
                            ) : null}
                            {data?.data?.project_settings?.contract_closure == true ? (
                                <div ref={refCompletion}>
                                    <p className="online__content-boldTitle">
                                        14. {translations?.['completion_of_work_and_contract_closure_activities_table.completion_of_work_and_contract_closure_activities_table.headers.completion_of_work_and_contract_closure_activities_table']}
                                    </p>
                                    <table>
                                        <thead className="dashboard__thead">
                                        <tr className="dashboard__tr">
                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                {translations?.['completion_of_work_and_contract_closure_activities_table.contract_closure.table_headers.completion_closure_activity_description']}
                                            </th>
                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                {translations?.['completion_of_work_and_contract_closure_activities_table.contract_closure.table_headers.planned_date']}
                                            </th>
                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                {translations?.['completion_of_work_and_contract_closure_activities_table.contract_closure.table_headers.actual_date']}
                                            </th>
                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                {translations?.['completion_of_work_and_contract_closure_activities_table.contract_closure.table_headers.current_status_or_comments']}
                                            </th>
                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                {translations?.['completion_of_work_and_contract_closure_activities_table.contract_closure.table_headers.completed']}
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody className="dashboard__tbody">
                                        {data?.data?.completion_of_work?.map((el, idx) => {
                                            return (
                                                <tr className="dashboard__tr" key={idx}>
                                                    <td className="py-4 px-6">{el?.description}</td>
                                                    <td className="py-4 px-6">
                                                        {el?.planned_date
                                                            ? moment(el?.planned_date)?.format("DD/MM/YYYY")
                                                            : ""}
                                                    </td>
                                                    <td className="py-4 px-6">
                                                        {el?.achieved_date
                                                            ? moment(el?.achieved_date)?.format(
                                                                "DD/MM/YYYY"
                                                            )
                                                            : ""}
                                                    </td>
                                                    <td className="py-4 px-6">{el?.comment}</td>
                                                    <td className="py-4 px-6">
                                                        {el?.closed ? "Yes" : "No"}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            ) : null}
                            <div ref={refContractDetails}>
                                <p className="online__content-boldTitle">
                                    15. {translations?.['main.edit_projects_details.headers.contract_details']}
                                </p>
                                <p className="online__content-commonTitle">
                                    &nbsp; 15.1. {translations?.['main.edit_projects_details.headers.contract_dates']}
                                </p>

                                <div className="online__content-notFullTable">
                                    <ContractDetails data={data?.data} translations={translations}/>
                                </div>
                            </div>
                            {data?.data?.project_settings?.contact_details == true ? (
                                <div ref={refCommunications}>
                                    <p className="online__content-boldTitle">
                                        16. {translations?.['communications_and_reporting.communications_and_reporting.headers.communications_and_reporting']}
                                    </p>
                                    <p className="online__content-commonTitle">
                                        16.1. {translations?.['main.edit_projects_details.headers.contract_details']}
                                    </p>
                                    <table>
                                        <thead className="dashboard__thead">
                                        <tr className="dashboard__tr">
                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                {translations?.['communications_and_reporting.executing_agency.table_headers.name']}
                                            </th>
                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                {translations?.['communications_and_reporting.executing_agency.table_headers.title']}
                                            </th>
                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                {translations?.['communications_and_reporting.executing_agency.table_headers.job_title']}
                                            </th>
                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                {translations?.['communications_and_reporting.executing_agency.table_headers.phone_number']}
                                            </th>
                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                {translations?.['communications_and_reporting.executing_agency.table_headers.email']}
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody className="dashboard__tbody">
                                        {
                                            Object.keys(data?.data?.contact_details).map((key,)=>(
                                                <>
                                                    <tr className={"dashboard__tr "}>
                                                        <td className="pl5">
                                                            {/*{data?.data?.contact_details[key]?.title.toUpperCase()}*/}
                                                            {
                                                            data?.data?.contact_details[key]?.title.toUpperCase() === 'EXECUTING AGENCY' ? translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.dropdown.executing_agency'] :
                                                                data?.data?.contact_details[key]?.title.toUpperCase() === 'IMPLEMENTING AGENCY' ? translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.dropdown.implementing_agency'] :
                                                                    data?.data?.contact_details[key]?.title.toUpperCase() === 'CONTRACTOR / SUPPLIER' ? translations?.['communications_and_reporting.contractor_supplier.headers.contractor_supplier'] :
                                                                        data?.data?.contact_details[key]?.title.toUpperCase() === 'ENGINEER / PROJECT MANAGER' ? translations?.['communications_and_reporting.engineer_project_manager.headers.engineer_project_manager'] :
                                                                            data?.data?.contact_details[key]?.title.toUpperCase() === 'ADB' ? translations?.['environment_social_health_safety_and_security_obligations.complaints_grievances.dropdown.adb'] :
                                                                                data?.data?.contact_details[key]?.title.toUpperCase() === 'DISPUTE BOARD MEMBERS' ? translations?.['communications_and_reporting.dispute_board_members.headers.dispute_board_members']:
                                                                                    data?.data?.contact_details[key]?.title.toUpperCase() === 'OTHER' ? translations?.['communications_and_reporting.other.headers.other'] : ''
                                                        }
                                                        </td>
                                                    </tr>
                                                    {
                                                        data?.data?.contact_details[key]?.data?.map((el, idx) => (
                                                            <tr className="dashboard__tr" key={idx}>
                                                                <td className="py-4 px-6">{el?.name}</td>
                                                                <td className="py-4 px-6">{el?.title}</td>
                                                                <td className="py-4 px-6">{el?.position}</td>
                                                                <td className="py-4 px-6">{el?.phone}</td>
                                                                <td className="py-4 px-6">{el?.email}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </>
                                            ))
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            ) : null}

                            {/*APPENDIX*/}

                            <p className="online__content-boldTitle">{translations?.['appendix.index.headers.appendix']}</p>

                            {(data?.data?.project_settings?.permits_licenses_approvals == true) ? (
                                !isEmpty(filterArchivedItems(data?.data?.permits_licenses_and_approvals))? (
                                    <div className="online__content-table">
                                        <p className="online__content-boldTitle">
                                            3. {translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.headers.permits_licenses_and_approvals']}
                                        </p>
                                        <table>
                                            <thead className="dashboard__thead">
                                            <tr className="dashboard__tr">
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.table_headers.application_type']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.table_headers.relevant_law_or_regulation']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.table_headers.responsible_party']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.table_headers.approving_authority']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.table_headers.planned_date']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.table_headers.actual_date']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.table_headers.expiry_date']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.table_headers.current_status_or_comments']}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="dashboard__tbody">
                                            {
                                                filterArchivedItems(data?.data?.permits_licenses_and_approvals)?.map(
                                                    (el, idx) => {
                                                        return (
                                                            <tr className="dashboard__tr" key={idx}>
                                                                <td className="py-4 px-6">
                                                                    {el?.application_type}
                                                                </td>
                                                                <td className="py-4 px-6">{el?.regulation}</td>
                                                                <td className="py-4 px-6">
                                                                    {el?.responsible_party}
                                                                </td>
                                                                <td className="py-4 px-6">
                                                                    {el?.approving_authority}
                                                                </td>
                                                                <td className="py-4 px-6">
                                                                    {el?.planned_date
                                                                        ? moment(el?.planned_date)?.format("DD/MM/YYYY")
                                                                        : ""}
                                                                </td>
                                                                <td className="py-4 px-6">
                                                                    {el?.actual_date
                                                                        ? moment(el?.actual_date)?.format("DD/MM/YYYY")
                                                                        : ""}
                                                                </td>
                                                                <td className="py-4 px-6">
                                                                    {el?.expiry_date
                                                                        ? moment(el?.expiry_date)?.format("DD/MM/YYYY")
                                                                        : ""}
                                                                </td>
                                                                <td className="py-4 px-6">{translations?.['permits_licenses_and_approvals.permits_licenses_and_approvals.table_headers.completed']}</td>
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : null
                            ) : null}

                            {(data?.data?.project_settings?.larp_approval == true || data?.data?.project_settings?.contract_requirements == true) ? (
                                (!isEmpty(filterArchivedItems(data?.data?.larp_processing)) || !isEmpty(filterArchivedItems(data?.data?.contract_requirements))) ? (
                                    <>
                                        <p className="online__content-boldTitle">
                                            4. {translations?.['contract_start_up_activities_and_submittals.contract_start_up_activities_and_submittals.headers.contract_start_up_activities_and_submittals']}
                                        </p>

                                        {data?.data?.project_settings?.larp_approval == true ? (
                                            !isEmpty(filterArchivedItems(data?.data?.larp_processing)) ? (
                                                <>
                                                    <p className="online__content-commonTitle">
                                                        &nbsp; 4.1. {translations?.['contract_start_up_activities_and_submittals.larp_approval.headers.larp_approval']}
                                                    </p>
                                                    <table>
                                                        <thead className="dashboard__thead">
                                                        <tr className="dashboard__tr">
                                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                                {translations?.['contract_start_up_activities_and_submittals.larp_approval.table_headers.contract_clause']}
                                                            </th>
                                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                                {translations?.['contract_start_up_activities_and_submittals.larp_approval.table_headers.implementation_status']}
                                                            </th>
                                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                                {translations?.['contract_start_up_activities_and_submittals.larp_approval.table_headers.compliance_report_planned_date']}
                                                            </th>
                                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                                {translations?.['contract_start_up_activities_and_submittals.larp_approval.table_headers.compliance_report_actual_date']}
                                                            </th>
                                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                                {translations?.['contract_start_up_activities_and_submittals.larp_approval.table_headers.current_status_or_comments']}
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody className="dashboard__tbody">
                                                        {filterArchivedItems(data?.data?.larp_processing)?.map((el, idx) => {
                                                            return (
                                                                <tr className="dashboard__tr" key={idx}>
                                                                    <td className="py-4 px-6">
                                                                        {el?.section_definition}
                                                                    </td>
                                                                    <td className="py-4 px-6">
                                                                        {el?.implementation_status}
                                                                    </td>
                                                                    <td className="py-4 px-6">
                                                                        {el?.planned_date
                                                                            ? moment(el?.planned_date)?.format("DD/MM/YYYY")
                                                                            : ""}
                                                                    </td>
                                                                    <td className="py-4 px-6">
                                                                        {el?.actual_date
                                                                            ? moment(el?.actual_date)?.format("DD/MM/YYYY")
                                                                            : ""}
                                                                    </td>
                                                                    <td className="py-4 px-6">{translations?.['contract_start_up_activities_and_submittals.larp_approval.table_headers.compliance_report_complete']}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                        </tbody>
                                                    </table>
                                                </>
                                            ) : null
                                        ) : null}

                                        {data?.data?.project_settings?.contract_requirements == true ? (
                                            !isEmpty(filterArchivedItems(data?.data?.contract_requirements)) ? (
                                                <>
                                                    <p className="online__content-commonTitle">
                                                        &nbsp; 4.3. {translations?.['general_start_up_activities.contract_requirements.headers.contract_requirements']}
                                                    </p>
                                                    <table>
                                                        <thead className="dashboard__thead">
                                                        <tr className="dashboard__tr">
                                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                                {translations?.['general_start_up_activities.contract_requirements.table_headers.contract_clause']}
                                                            </th>
                                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                                {translations?.['general_start_up_activities.contract_requirements.table_headers.action_description']}
                                                            </th>
                                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                                {translations?.['general_start_up_activities.contract_requirements.table_headers.responsible_party']}
                                                            </th>
                                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                                {translations?.['general_start_up_activities.contract_requirements.table_headers.planned_date']}
                                                            </th>
                                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                                {translations?.['general_start_up_activities.contract_requirements.table_headers.actual_date']}
                                                            </th>
                                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                                {translations?.['general_start_up_activities.contract_requirements.table_headers.expiry_date']}
                                                            </th>
                                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                                {translations?.['general_start_up_activities.contract_requirements.table_headers.current_status_or_comments']}
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody className="dashboard__tbody">
                                                        {filterArchivedItems(data?.data?.contract_requirements)?.map((el, idx) => {
                                                            return (
                                                                <tr className="dashboard__tr" key={idx}>
                                                                    <td className="py-4 px-6">{el?.contract_clause}</td>
                                                                    <td className="py-4 px-6">
                                                                        {el?.action_description}
                                                                    </td>
                                                                    <td className="py-4 px-6">
                                                                        {el?.responsible_party}
                                                                    </td>
                                                                    <td className="py-4 px-6">
                                                                        {el?.planned_date
                                                                            ? moment(el?.planned_date)?.format("DD/MM/YYYY")
                                                                            : ""}
                                                                    </td>
                                                                    <td className="py-4 px-6">
                                                                        {el?.actual_date
                                                                            ? moment(el?.actual_date)?.format("DD/MM/YYYY")
                                                                            : ""}
                                                                    </td>
                                                                    <td className="py-4 px-6">
                                                                        {!el?.not_applicable
                                                                            ? el?.expiry_date ? moment(el?.expiry_date)?.format("DD/MM/YYYY") : ""
                                                                            : ""}
                                                                    </td>
                                                                    <td className="py-4 px-6">{translations?.['general_start_up_activities.contract_requirements.table_headers.completed']}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                        </tbody>
                                                    </table>
                                                </>
                                            ) : null
                                        ) : null}
                                    </>
                                ) : null
                            ) : null}

                            {data?.data?.project_settings?.risk_management == true ? (
                                !isEmpty(filterArchivedItems(data?.data?.risk_management)) ? (
                                    <>
                                        <p className="online__content-boldTitle">
                                            5. {translations?.['risk_management.risk_management.headers.risk_management']}
                                        </p>
                                        <table>
                                            <thead className="dashboard__thead">
                                            <tr className="dashboard__tr">
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['risk_management.risk_management.table_headers.ref']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['risk_management.risk_management.table_headers.risk_description']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['risk_management.risk_management.table_headers.impact_rating']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['risk_management.risk_management.table_headers.likelihood_rating']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['risk_management.risk_management.table_headers.risk_level']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['risk_management.risk_management.table_headers.mitigation_measure']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['risk_management.risk_management.table_headers.responsible_party']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['risk_management.risk_management.table_headers.current_status_or_comments']}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="dashboard__tbody">
                                            {filterArchivedItems(data?.data?.risk_management)
                                                ?.map((el, idx) => {
                                                    return (
                                                        <tr className="dashboard__tr" key={idx}>
                                                            <td className="py-4 px-6">
                                                                {el?.risk_reference}
                                                            </td>
                                                            <td className="py-4 px-6">
                                                                {el?.risk_description}
                                                            </td>
                                                            <td className="py-4 px-6">
                                                                {el?.impact_rating_text}
                                                            </td>
                                                            <td className="py-4 px-6">
                                                                {el?.likelihood_rating_text}
                                                            </td>
                                                            <td className="py-4 px-6">
                                                                {el?.risk_level_text}
                                                            </td>
                                                            <td className="py-4 px-6">
                                                                {el?.mitigation_measure}
                                                            </td>
                                                            <td className="py-4 px-6">
                                                                {el?.responsible_party}
                                                            </td>
                                                            <td className="py-4 px-6">
                                                                {el?.comment}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </>
                                ) : null
                            ) : null}

                            {data?.data?.project_settings?.major_project_milestones == true ? (
                                !isEmpty(filterArchivedItems(data?.data?.level_2_program))  ? (
                                    <>
                                        <p className="online__content-boldTitle">
                                            6. {translations?.['project_monitoring.project_monitoring.headers.project_monitoring']}
                                        </p>
                                        <p className="online__content-commonTitle">
                                            &nbsp; 6.2. {translations?.['project_monitoring.major_project_milestones.headers.major_project_milestones']}
                                        </p>
                                        <table>
                                            <thead className="dashboard__thead">
                                            <tr className="dashboard__tr">
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.major_project_milestones.table_headers.ref']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.major_project_milestones.table_headers.description']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.major_project_milestones.table_headers.original_program_milestone_completion_date']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.major_project_milestones.table_headers.updated_completion_date']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.major_project_milestones.table_headers.variance_days']}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="dashboard__tbody">
                                            {filterArchivedItems(data?.data?.level_2_program)?.map((el, idx) => {
                                                return (
                                                    <tr className="dashboard__tr" key={idx}>
                                                        <td className="py-4 px-6">
                                                            {el?.program_reference}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.program_description}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.original_completion_date
                                                                ? moment(
                                                                    el?.original_completion_date
                                                                )?.format("DD/MM/YYYY")
                                                                : ""}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.updated_completion_date
                                                                ? moment(el?.updated_completion_date)?.format(
                                                                    "DD/MM/YYYY"
                                                                )
                                                                : ""}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.program_variance}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    </>
                                ) : null
                            ) : null}

                            {(data?.data?.project_settings?.variation_register == true || data?.data?.project_settings?.claim_register == true) ? (
                                !isEmpty(filterArchivedItems(data?.data?.variation_register)) || !isEmpty(filterArchivedItems(data?.data?.claim_register)) ? (
                                    <>
                                        <p className="online__content-boldTitle">
                                            7. Variations and Claims - Continued
                                        </p>

                                        {data?.data?.project_settings?.variation_register == true ? (
                                            !isEmpty(filterArchivedItems(data?.data?.variation_register)) ? (
                                                <>
                                                    <p className="online__content-commonTitle">
                                                        &nbsp; 7.2. {translations?.['project_monitoring.variation_register.headers.variation_register']}
                                                    </p>
                                                    <table>
                                                        <thead className="dashboard__thead">
                                                        <tr className="dashboard__tr">
                                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                                {translations?.['project_monitoring.variation_register.table_headers.variation_ref']}
                                                            </th>
                                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                                {translations?.['project_monitoring.variation_register.table_headers.variation_description']}
                                                            </th>
                                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                                {translations?.['project_monitoring.variation_register.table_headers.date_instruction_issued']}
                                                            </th>
                                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                                {translations?.['project_monitoring.variation_register.table_headers.estimated_variation_cost']}
                                                            </th>
                                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                                {translations?.['project_monitoring.variation_register.table_headers.approved_variation_cost']}
                                                            </th>
                                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                                {translations?.['project_monitoring.variation_register.table_headers.cost_approved']}
                                                            </th>
                                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                                {translations?.['project_monitoring.variation_register.table_headers.date_variation_approved']}
                                                            </th>
                                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                                {translations?.['project_monitoring.variation_register.table_headers.current_status']}
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody className="dashboard__tbody">
                                                        {filterArchivedItems(data?.data?.variation_register)?.map((el, idx) => {
                                                            return (
                                                                <tr className="dashboard__tr" key={idx}>
                                                                    <td className="py-4 px-6">
                                                                        {el?.variation_reference}
                                                                    </td>
                                                                    <td className="py-4 px-6">
                                                                        {el?.variation_description}
                                                                    </td>
                                                                    <td className="py-4 px-6">
                                                                        {el?.date_issued
                                                                            ? moment(el?.date_issued)?.format(
                                                                                "DD/MM/YYYY"
                                                                            )
                                                                            : ""}
                                                                    </td>
                                                                    <td className="py-4 px-6">
                                                                        {el?.estimated_variance_cost
                                                                            ? "$" +
                                                                            String(el?.estimated_variance_cost).replace(
                                                                                /(\d)(?=(\d{3})+([^\d]|$))/g,
                                                                                "$1,"
                                                                            )
                                                                            : ""}
                                                                    </td>
                                                                    <td className="py-4 px-6">
                                                                        {el?.approved_variance_cost
                                                                            ? "$" +
                                                                            String(el?.approved_variance_cost).replace(
                                                                                /(\d)(?=(\d{3})+([^\d]|$))/g,
                                                                                "$1,"
                                                                            )
                                                                            : ""}
                                                                    </td>
                                                                    <td className="py-4 px-6">
                                                                        {el?.variation_approved ? 'Yes' : 'No'}
                                                                    </td>
                                                                    <td className="py-4 px-6">
                                                                        {el?.variation_approved ? el?.date_variation_approved
                                                                            ? moment(el?.date_variation_approved)?.format(
                                                                                "DD/MM/YYYY"
                                                                            )
                                                                            : "" : ''}
                                                                    </td>
                                                                    <td className="py-4 px-6">
                                                                        {el?.current_status}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                        </tbody>
                                                    </table>
                                                </>
                                            ) : null
                                        ) : null}

                                        {data?.data?.project_settings?.claim_register == true ? (
                                            !isEmpty(filterArchivedItems(data?.data?.claim_register)) ? (
                                                <>
                                                    <p className="online__content-commonTitle">
                                                        &nbsp; 7.3. {translations?.['project_monitoring.claim_register.headers.claim_register']}
                                                    </p>
                                                    <table>
                                                        <thead className="dashboard__thead">
                                                        <tr className="dashboard__tr">
                                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                                {translations?.['project_monitoring.claim_register.table_headers.claim_ref']}
                                                            </th>
                                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                                {translations?.['project_monitoring.claim_register.table_headers.claim_description']}
                                                            </th>
                                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                                {translations?.['project_monitoring.claim_register.table_headers.submission_of_notice_of_claim']}
                                                            </th>
                                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                                {translations?.['project_monitoring.claim_register.table_headers.submission_of_fully_detailed_claim']}
                                                            </th>
                                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                                {translations?.['project_monitoring.claim_register.table_headers.claimed_amount']}
                                                            </th>
                                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                                {translations?.['project_monitoring.claim_register.table_headers.claim_certified']}
                                                            </th>
                                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                                {translations?.['project_monitoring.claim_register.table_headers.date_claim_certified']}
                                                            </th>
                                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                                {translations?.['project_monitoring.claim_register.table_headers.amount_certified']}
                                                            </th>
                                                            <th scope="col" className="py-3 px-6 dashboard__th">
                                                                {translations?.['project_monitoring.claim_register.table_headers.current_status']}
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody className="dashboard__tbody">
                                                        {filterArchivedItems(data?.data?.claim_register)?.map((el, idx) => {
                                                            return (
                                                                <tr className="dashboard__tr" key={idx}>
                                                                    <td className="py-4 px-6">
                                                                        {el?.claim_reference}
                                                                    </td>
                                                                    <td className="py-4 px-6">
                                                                        {el?.claim_description}
                                                                    </td>
                                                                    <td className="py-4 px-6">
                                                                        {el?.notice_date
                                                                            ? moment(el?.notice_date)?.format(
                                                                                "DD/MM/YYYY"
                                                                            )
                                                                            : ""}
                                                                    </td>
                                                                    <td className="py-4 px-6">
                                                                        {el?.detailed_date
                                                                            ? moment(el?.detailed_date)?.format(
                                                                                "DD/MM/YYYY"
                                                                            )
                                                                            : ""}
                                                                    </td>
                                                                    <td className="py-4 px-6">
                                                                        {el?.submitted_amount
                                                                            ? "$" +
                                                                            String(el?.submitted_amount).replace(
                                                                                /(\d)(?=(\d{3})+([^\d]|$))/g,
                                                                                "$1,"
                                                                            )
                                                                            : ""}
                                                                    </td>
                                                                    <td className="py-4 px-6">
                                                                        {el?.certified ? 'Yes' : 'No'}
                                                                    </td>
                                                                    <td className="py-4 px-6">
                                                                        {el?.certified ? el?.date_claim_certified
                                                                            ? moment(el?.date_claim_certified)?.format(
                                                                                "DD/MM/YYYY"
                                                                            )
                                                                            : "" : ''}
                                                                    </td>
                                                                    <td className="py-4 px-6">
                                                                        {el?.certified ? el?.certified_amount
                                                                            ? "$" +
                                                                            String(el?.certified_amount).replace(
                                                                                /(\d)(?=(\d{3})+([^\d]|$))/g,
                                                                                "$1,"
                                                                            )
                                                                            : "" : ''}
                                                                    </td>
                                                                    <td className="py-4 px-6">
                                                                        {el?.current_status}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                        </tbody>
                                                    </table>
                                                </>
                                            ) : null
                                        ) : null}
                                    </>
                                ):null
                            ):null}

                            {data?.data?.project_settings?.extension_of_time == true ? (
                                !isEmpty(filterArchivedItems(data?.data?.extension_of_time?.data)) ? (
                                    <>
                                        <p className="online__content-commonTitle">
                                            &nbsp; 7.4. {translations?.['project_monitoring.extension_of_time.headers.extension_of_time']}
                                        </p>
                                        <table>
                                            <thead className="dashboard__thead">
                                            <tr className="dashboard__tr">
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.extension_of_time.table_headers.ref']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.extension_of_time.table_headers.eot_description']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.extension_of_time.table_headers.submission_of_notice_of_eot']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.extension_of_time.table_headers.number_of_days_claimed']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.extension_of_time.table_headers.eot_approval_date']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.extension_of_time.table_headers.number_of_approved_days']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.extension_of_time.table_headers.approved']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['project_monitoring.extension_of_time.table_headers.current_status_or_comments']}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="dashboard__tbody">
                                            {filterArchivedItems(data?.data?.extension_of_time?.data)?.map((el, idx) => {
                                                return (
                                                    <tr className="dashboard__tr" key={idx}>
                                                        <td className="py-4 px-6">
                                                            {el?.ref}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.description}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.submission_of_notice
                                                                ? moment(el?.submission_of_notice)?.format(
                                                                    "DD/MM/YYYY"
                                                                )
                                                                : ""}
                                                        </td>
                                                        <td className="py-4 px-6" style={{paddingLeft: Number(el?.number_of_days_claimed) < 0 ? '1rem' : '1.5rem'}}>
                                                            {el?.number_of_days_claimed}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.approved ? el?.approval_date
                                                                ? moment(el?.approval_date)?.format(
                                                                    "DD/MM/YYYY"
                                                                )
                                                                : "" : ''}
                                                        </td>
                                                        <td className="py-4 px-6" style={{paddingLeft: Number(el?.number_of_approved_days) < 0 ? '1rem' : '1.5rem'}}>
                                                            {el?.number_of_approved_days}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.approved ? 'Approved' : 'Not Approved'}
                                                        </td>
                                                        <td className="py-4 px-6">
                                                            {el?.comments}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    </>
                                ) : null
                            ) : null}

                            {data?.data?.project_settings?.subcontractor_payments == true ? (
                                !isEmpty(filterArchivedItems(data?.data?.subcontractor_payments))? (
                                    <>
                                        <p className="online__content-boldTitle">
                                            8. {translations?.['payments.payments.headers.payments']}
                                        </p>
                                        <p className="online__content-commonTitle">
                                            &nbsp; 8.3. {translations?.['payments.subcontractor_payments.headers.subcontractor_payments']}
                                        </p>
                                        <table>
                                            <thead className="dashboard__thead">
                                            <tr className="dashboard__tr">
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['payments.subcontractor_payments.table_headers.subcontractor_name']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['payments.subcontractor_payments.table_headers.role']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['payments.subcontractor_payments.table_headers.percent_of_work_completed']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['payments.subcontractor_payments.table_headers.percent_of_contract_invoiced']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['payments.subcontractor_payments.table_headers.percent_of_contract_paid']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['payments.subcontractor_payments.table_headers.issues_or_disputes']}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="dashboard__tbody">
                                            {filterArchivedItems(data?.data?.subcontractor_payments)?.map((el, idx) => {
                                                return (
                                                    <tr className="dashboard__tr" key={idx}>
                                                        <td className="py-4 px-6">
                                                            {el?.subcontractor_name}
                                                        </td>
                                                        <td className="py-4 px-6">{el?.role}</td>
                                                        <td className="py-4 px-6">{el?.percent_work}%</td>
                                                        <td className="py-4 px-6">{el?.invoiced}%</td>
                                                        <td className="py-4 px-6">{el?.paid}%</td>
                                                        <td className="py-4 px-6">{el?.issues}</td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    </>
                                ) : null
                            ) : null}

                            {data?.data?.project_settings?.goods_equipment == true ? (
                                !isEmpty(filterArchivedItems(data?.data?.goods_equipment)) ? (
                                    <div >
                                        <p className="online__content-boldTitle">
                                            10. {translations?.['goods_equipment.goods_equipment.headers.goods_equipment']}
                                        </p>
                                        <p className="online__content-commonTitle">
                                            &nbsp; 10.1. {translations?.['goods_equipment.goods_equipment_packages_status.headers.goods_equipment_packages_status']}
                                        </p>
                                        <table>
                                            <thead className="dashboard__thead"></thead>
                                            <tbody className="dashboard__tbody">
                                            {filterArchivedItems(data?.data?.goods_equipment)?.map((el, idx) => {
                                                return (
                                                    <div style={{ marginBottom: 30 }} key={idx}>
                                                        <tr className="dashboard__tr">
                                                            <th
                                                                scope="col"
                                                                className="py-3 px-6 dashboard__th x-left-y-center"
                                                            >
                                                                {translations?.['goods_equipment.goods_equipment_packages_status.table_headers.goods_equipment_description']}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-3 px-6 dashboard__th x-left-y-center"
                                                            >
                                                                {translations?.['goods_equipment.goods_equipment_packages_status.table_headers.fat_status']}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-3 px-6 dashboard__th x-left-y-center"
                                                            >
                                                                {translations?.['goods_equipment.goods_equipment_packages_status.table_headers.destinations']}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-3 px-6 dashboard__th x-left-y-center"
                                                            >
                                                                {translations?.['goods_equipment.goods_equipment_packages_status.table_headers.customs_clearance_status']}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-3 px-6 dashboard__th x-left-y-center"
                                                            >
                                                                {translations?.['goods_equipment.goods_equipment_packages_status.table_headers.percent_invoiced']}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-3 px-6 dashboard__th x-left-y-center"
                                                            >
                                                                {translations?.['goods_equipment.goods_equipment_packages_status.table_headers.percent_paid']}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-3 px-6 dashboard__th x-left-y-center"
                                                            >
                                                                {translations?.['goods_equipment.goods_equipment_packages_status.table_headers.delivery_complete']}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-3 px-6 dashboard__th x-left-y-center"
                                                            >
                                                                {translations?.['goods_equipment.goods_equipment_packages_status.table_headers.current_status_or_comments']}
                                                            </th>
                                                        </tr>
                                                        <tr className="dashboard__tr">
                                                            <td className="py-4 px-6 s-center">
                                                                {el?.description}
                                                            </td>
                                                            <td className="py-4 px-6 s-center">
                                                                {el?.fat_status}
                                                            </td>
                                                            <td className="py-4 px-6 s-center">
                                                                {el?.destination}
                                                            </td>
                                                            <td className="py-4 px-6 s-center">
                                                                {el?.clearance_status}
                                                            </td>
                                                            <td className="py-4 px-6 s-center">
                                                                {el?.invoiced}%
                                                            </td>
                                                            <td className="py-4 px-6 s-center">
                                                                {el?.paid}%
                                                            </td>
                                                            <td className="py-4 px-6 s-center">
                                                                {el?.delivery_complete ? "Yes" : "No"}
                                                            </td>
                                                            <td className="py-4 px-6 s-center">
                                                                Completed
                                                            </td>
                                                        </tr>
                                                        <tr className="dashboard__tr">
                                                            <th
                                                                scope="col"
                                                                className="py-3 px-6 dashboard__th"
                                                            >
                                                                {translations?.['goods_equipment.goods_equipment_packages_status.tooltips.planned_shipment_date']}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-3 px-6 dashboard__th"
                                                            >
                                                                {translations?.['goods_equipment.goods_equipment_packages_status.tooltips.actual_shipment_date']}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-3 px-6 dashboard__th"
                                                            >
                                                                {translations?.['goods_equipment.goods_equipment_packages_status.tooltips.planned_arrival_date']}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-3 px-6 dashboard__th"
                                                            >
                                                                {translations?.['goods_equipment.goods_equipment_packages_status.tooltips.actual_arrival_date']}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-3 px-6 dashboard__th"
                                                            >
                                                                {translations?.['goods_equipment.goods_equipment_packages_status.tooltips.warehouse_planned_arrival_date']}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-3 px-6 dashboard__th"
                                                            >
                                                                {translations?.['goods_equipment.goods_equipment_packages_status.tooltips.warehouse_actual_arrival_date']}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-3 px-6 dashboard__th"
                                                            >
                                                                {translations?.['goods_equipment.goods_equipment_packages_status.tooltips.site_planned_delivery_date']}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="py-3 px-6 dashboard__th"
                                                            >
                                                                {translations?.['goods_equipment.goods_equipment_packages_status.tooltips.site_actual_delivery_date']}
                                                            </th>
                                                        </tr>
                                                        <tr className="dashboard__tr">
                                                            <td className="py-4 px-6 s-center">
                                                                {el?.planned_shipped_date
                                                                    ? moment(el?.planned_shipped_date).format(
                                                                        "DD/MM/YYYY"
                                                                    )
                                                                    : ""}
                                                            </td>
                                                            <td className="py-4 px-6 s-center">
                                                                {el?.actual_shipped_date
                                                                    ? moment(el?.actual_shipped_date).format(
                                                                        "DD/MM/YYYY"
                                                                    )
                                                                    : ""}
                                                            </td>
                                                            <td className="py-4 px-6 s-center">
                                                                {el?.planned_arrival_date
                                                                    ? moment(el?.planned_arrival_date).format(
                                                                        "DD/MM/YYYY"
                                                                    )
                                                                    : ""}
                                                            </td>
                                                            <td className="py-4 px-6 s-center">
                                                                {el?.actual_arrival_date
                                                                    ? moment(el?.actual_arrival_date).format(
                                                                        "DD/MM/YYYY"
                                                                    )
                                                                    : ""}
                                                            </td>
                                                            <td className="py-4 px-6 s-center">
                                                                {el?.warehouse_planned_date
                                                                    ? moment(el?.warehouse_planned_date).format(
                                                                        "DD/MM/YYYY"
                                                                    )
                                                                    : ""}
                                                            </td>
                                                            <td className="py-4 px-6 s-center">
                                                                {el?.warehouse_actual_date
                                                                    ? moment(el?.warehouse_actual_date).format(
                                                                        "DD/MM/YYYY"
                                                                    )
                                                                    : ""}
                                                            </td>
                                                            <td className="py-4 px-6 s-center">
                                                                {el?.planned_delivery_date
                                                                    ? moment(el?.planned_delivery_date).format(
                                                                        "DD/MM/YYYY"
                                                                    )
                                                                    : ""}
                                                            </td>
                                                            <td className="py-4 px-6 s-center">
                                                                {el?.actual_delivery_date
                                                                    ? moment(el?.actual_delivery_date).format(
                                                                        "DD/MM/YYYY"
                                                                    )
                                                                    : ""}
                                                            </td>
                                                        </tr>
                                                    </div>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : null
                            ) : null}

                            {data?.data?.project_settings?.health_safety_monitoring == true ? (
                                !isEmpty(filterArchivedItems(data?.data?.environment?.health_safety_monitoring)) ? (
                                    <>
                                        <p className="online__content-boldTitle">
                                            {translations?.['environment_social_health_safety_and_security_obligations.environment_social_health_safety_and_security_obligations.headers.environment_social_health_safety_and_security_obligations']}
                                        </p>
                                        <p className="online__content-commonTitle">
                                            &nbsp; {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring.headers.health_and_safety_monitoring']}
                                        </p>
                                        <table>
                                            <thead className="dashboard__thead">
                                            <tr className="dashboard__tr">
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring.table_headers.health_and_safety_monitoring_description']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring.table_headers.corresponding_submittal']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring.table_headers.planned_date']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring.table_headers.actual_date']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring.table_headers.current_status_or_comments']}
                                                </th>
                                                <th scope="col" className="py-3 px-6 dashboard__th">
                                                    {translations?.['environment_social_health_safety_and_security_obligations.health_and_safety_monitoring.table_headers.completed']}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="dashboard__tbody">
                                            {filterArchivedItems(data?.data?.environment?.health_safety_monitoring)?.map(
                                                (el, idx) => {
                                                    return (
                                                        <tr className="dashboard__tr" key={idx}>
                                                            <td className="py-4 px-6">{el?.description}</td>
                                                            <td className="py-4 px-6">
                                                                {el?.corresponding_submittal}
                                                            </td>
                                                            <td className="py-4 px-6">
                                                                {el?.planned_date
                                                                    ? moment(el?.planned_date)?.format(
                                                                        "DD/MM/YYYY"
                                                                    )
                                                                    : ""}
                                                            </td>
                                                            <td className="py-4 px-6">
                                                                {el?.actual_date
                                                                    ? moment(el?.actual_date)?.format(
                                                                        "DD/MM/YYYY"
                                                                    )
                                                                    : ""}
                                                            </td>
                                                            <td className="py-4 px-6">{el?.comment}</td>
                                                            <td className="py-4 px-6">
                                                                Completed
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                            </tbody>
                                        </table>
                                    </>
                                ) : null
                            ) : null}
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};
